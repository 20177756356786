import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { IconSoundHigh, IconSoundLow, IconSoundOff } from '@czechtv/icons';
import { PlayerNativeButton } from '../../../../components/PlayerNativeButton/PlayerNativeButton';
import { formatMessage } from '../../../../utils/formatMessage';
import { usePlayerContext } from '../../../PlayerContext';
import VolumeKnob from './VolumeKnob';
import { volumeControlClassnames } from './VolumeControl.css';

interface VolumeControlProps {
  className?: string;
  iconClassName?: string;
  onDragChange?: (isDragging: boolean) => void;
  onSoundOffButtonPressed: () => void;
  onSoundOnButtonPressed: () => void;
  onVolumeChange: (volume: number) => void;
  vertical?: boolean;
  volume: number;
}

const VOLUME_CONTROL_TIMEOUT = 1500;

const messages = {
  soundOff: {
    id: 'VolumeControl.buttonTitle.soundOff',
    defaultMessage: 'Vypnout zvuk',
    description: '',
  },
  soundOn: {
    id: 'VolumeControl.buttonTitle.soundOn',
    defaultMessage: 'Zapnout zvuk',
    description: '',
  },
};

export const VolumeControl = memo(
  ({
    volume: playerVolume,
    onVolumeChange,
    onSoundOnButtonPressed,
    onSoundOffButtonPressed,
    className,
    iconClassName,
    vertical,
    onDragChange: onDragChangeCallback = () => {},
  }: VolumeControlProps) => {
    const { isChromecastSession, setVolumeUpdateCallback } = usePlayerContext();
    const [chromecastVolume, setChromecastVolume] = useState(1);

    const volume = isChromecastSession ? chromecastVolume : playerVolume;

    const [visible, setVisible] = useState(false);
    const [isDraging, setDraging] = useState(false);
    const isHovered = useRef(false);
    const volumeRef = useRef(volume);

    // abych zabránil animačce při načtení
    const firstRender = useRef(true);

    // když chceme skrýt posuvník po tapnutí
    const tapVisibilityTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

    useEffect(() => {
      const onVolumeChange = (volume: number) => {
        setChromecastVolume(volume);
      };
      setVolumeUpdateCallback(onVolumeChange);
    }, [setVolumeUpdateCallback]);

    const onVolumeControlMouseEnter = useCallback(() => {
      setVisible(true);
      isHovered.current = true;
    }, []);

    const onVolumeControlMouseLeave = useCallback(() => {
      if (!isDraging) {
        setVisible(false);
      }
      isHovered.current = false;
    }, [isDraging]);

    const onSoundOnButtonTap = useCallback(
      (e: React.TouchEvent) => {
        isHovered.current = false;
        e.preventDefault();

        if (!visible) {
          tapVisibilityTimeout.current = setTimeout(() => {
            setVisible(false);
          }, VOLUME_CONTROL_TIMEOUT);
          setVisible(true);
        } else {
          onSoundOnButtonPressed();
        }
      },
      [onSoundOnButtonPressed, visible]
    );

    const onSoundOffButtonTap = useCallback(
      (e: React.TouchEvent) => {
        isHovered.current = false;
        e.preventDefault();

        if (!visible) {
          tapVisibilityTimeout.current = setTimeout(() => {
            setVisible(false);
          }, VOLUME_CONTROL_TIMEOUT);
          setVisible(true);
        } else {
          onSoundOffButtonPressed();
        }
      },
      [onSoundOffButtonPressed, visible]
    );

    const onDragChange = useCallback(
      (draging: boolean) => {
        onDragChangeCallback(draging);
        setDraging(draging);
      },
      [onDragChangeCallback]
    );

    const onVolumeScroll = (e: React.WheelEvent | WheelEvent) => {
      const delta = Math.max(0, Math.min(1, e.deltaY));
      let currentVolume = volumeRef.current;

      if (delta == 0 && currentVolume >= 0 && currentVolume <= 0.98) {
        onVolumeChange(Number((currentVolume += 0.02).toFixed(2)));
      }
      if (delta == 1 && currentVolume >= 0.02 && currentVolume <= 1) {
        onVolumeChange(Number((currentVolume -= 0.02).toFixed(2)));
      }
      e.preventDefault();
    };

    useEffect(() => {
      // do nothing on first render
      if (firstRender.current) {
        firstRender.current = false;
        return () => {};
      }
      // pokud se změní volume, zobrazíme tahátko
      setVisible(true);

      if (tapVisibilityTimeout.current !== null) {
        clearTimeout(tapVisibilityTimeout.current);
      }

      const volumeControlTimeout = setTimeout(() => {
        // nechceme skrývat pokud má uživatel na prvku myš nebo draguje
        if (!isHovered.current && !isDraging) {
          setVisible(false);
        }
      }, VOLUME_CONTROL_TIMEOUT);

      return () => clearTimeout(volumeControlTimeout);
    }, [volume, isDraging]);

    useEffect(() => {
      volumeRef.current = volume;
    }, [volume]);

    useEffect(() => {
      const soundControl = document.getElementById('PlayerControlsSound');
      soundControl?.addEventListener('wheel', onVolumeScroll, { passive: false });
      return () => soundControl?.removeEventListener('wheel', onVolumeScroll);
    });

    return (
      <div
        className={classNames(volumeControlClassnames.soundControls, className, { vertical })}
        data-testid="PlayerControlsSound"
        id="PlayerControlsSound"
        key="PlayerControlsSound"
        onMouseEnter={onVolumeControlMouseEnter}
        onMouseLeave={onVolumeControlMouseLeave}
        onTouchEnd={() => {
          // potřebujeme pro touch zachovat blur
          isHovered.current = false;
        }}
      >
        {volume <= 0.5 && volume > 0 && (
          <PlayerNativeButton
            className={classNames(volumeControlClassnames.icon, iconClassName, { vertical })}
            data-testid="playerSoundOnIcon"
            key="PlayerControlsSoundIcon"
            title={formatMessage(messages.soundOff)}
            onClick={onSoundOnButtonPressed}
            onTouchEnd={onSoundOnButtonTap}
          >
            <IconSoundLow tabIndex={-1} />
          </PlayerNativeButton>
        )}
        {volume > 0.5 && (
          <PlayerNativeButton
            className={classNames(volumeControlClassnames.icon, iconClassName, { vertical })}
            data-testid="playerSoundOnIcon"
            key="PlayerControlsSoundIcon"
            title={formatMessage(messages.soundOff)}
            onClick={onSoundOnButtonPressed}
            onTouchEnd={onSoundOnButtonTap}
          >
            <IconSoundHigh tabIndex={-1} />
          </PlayerNativeButton>
        )}
        {volume === 0 && (
          <PlayerNativeButton
            className={classNames(volumeControlClassnames.icon, iconClassName, { vertical })}
            data-testid="playerSoundOffIcon"
            key="PlayerControlsSoundIcon"
            title={formatMessage(messages.soundOn)}
            onClick={onSoundOffButtonPressed}
            onTouchEnd={onSoundOffButtonTap}
          >
            <IconSoundOff tabIndex={-1} />
          </PlayerNativeButton>
        )}
        <VolumeKnob
          vertical={vertical}
          visible={visible}
          volume={volume}
          onDragChange={onDragChange}
          onVolumeChange={onVolumeChange}
        />
      </div>
    );
  }
);
