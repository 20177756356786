import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import { useFocusVisibleClassName } from '@czechtv/components';
import { Link } from '../Link/Link';
import { PlaylistOptions } from '../../utils/playlists/constants';
import { log } from '../../utils/logger';
import { shouldPlayDash } from '../../utils/shouldPlayDash';
import { getSupportedDRMs } from '../../utils/drm';
import { FAQ_URL } from '../../constants';
import { faqLinkClassnames } from './FaqLink.css';

interface FaqLinkProps {
  basicLinkClassName?: string;
  children: React.ReactNode;
  onClick?: () => void;
  playlistOptions?: PlaylistOptions;
}

export const FaqLink = ({
  basicLinkClassName,
  playlistOptions,
  children,
  onClick,
}: FaqLinkProps) => {
  const focusVisibleClassName = useFocusVisibleClassName();
  const [isLoading, setIsLoading] = useState(false);
  const faqUrl = playlistOptions?.faqUrl || FAQ_URL;

  const onFaqRedirect = useCallback(() => {
    const faqReportApiUrl = playlistOptions?.faqReportApiUrl;
    if (!faqReportApiUrl) {
      return;
    }
    if (onClick) {
      onClick();
    }

    let redirectUrl = `${faqUrl}?id=unknown`;

    const newTab = window.open(redirectUrl, '_blank');

    const reportAndRedirect = async () => {
      setIsLoading(true);
      try {
        const supportedDrms = await getSupportedDRMs();
        const streamType = shouldPlayDash() ? 'dash' : 'hls';
        const { externalId, mediaId, indexId, versionId, bonusId, encoder, origin } =
          playlistOptions;
        const idEntries = [
          ['encoder', encoder],
          ['indexId', indexId],
          ['versionId', versionId],
          ['bonusId', bonusId],
          ['externalId', externalId],
          ['mediaId', mediaId],
        ];
        const foundEntry = idEntries.find(([, value]) => value);
        const idResult = foundEntry ? `${foundEntry[0]},${foundEntry[1]},${origin}` : '?';
        const payload = {
          userAgent: window.navigator.userAgent,
          features: {
            cookiesEnabled: window.navigator.cookieEnabled,
            localStorage: typeof localStorage !== 'undefined',
            sessionStorage: typeof sessionStorage !== 'undefined',
            indexedDB: typeof indexedDB !== 'undefined',
            serviceWorker: 'serviceWorker' in navigator,
          },
          playerDetails: {
            videoIdentificator: idResult,
            newPlaylist: !!playlistOptions.useNewPlaylist,
            supportedDrm: supportedDrms.join(','),
            streamType,
          },
        };

        const response = await fetch(faqReportApiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        });

        if (response.ok) {
          const data = (await response.json()) as { id: string };
          redirectUrl = `${faqUrl}?id=${data.id}`;
        } else {
          log.warn({ message: 'Error fetching the report ID from the API:' });
        }
      } catch (error) {
        log.warn({ message: 'Error fetching the report ID from the API:', error });
      } finally {
        setIsLoading(false);
        if (newTab) {
          console.log('newtab', newTab);
          newTab.location.href = redirectUrl;
        }
      }
    };

    void reportAndRedirect();
  }, [playlistOptions, onClick, faqUrl]);

  if (playlistOptions?.faqReportApiUrl) {
    return (
      <button
        className={classNames(
          faqLinkClassnames.linkButton,
          basicLinkClassName,
          focusVisibleClassName
        )}
        disabled={isLoading}
        type="button"
        onClick={onFaqRedirect}
      >
        {children}
      </button>
    );
  }
  return (
    <Link
      absolute
      className={basicLinkClassName}
      href={faqUrl}
      rel="noreferrer"
      target="_blank"
      onClick={onClick}
    >
      {children}
    </Link>
  );
};
