import React, { memo, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { IconErrorCircle } from '@czechtv/icons';
import { PlayerNativeButton } from '../../PlayerNativeButton/PlayerNativeButton';
import { BUTTON_SEPARATOR } from '../../../constants';
import { toastMessageClassnames } from './ToastMessage.css';

const TOAST_MESSAGE_TIMEOUT = 4000;

export interface ToastMessageProps {
  onButtonClick?: () => void;
  subtitle?: string;
  title?: string;
}

type StringMeta = {
  content?: string;
  type?: string;
};

/* pokud string obsahuje text zvyrazneny specialnim znakem (buttonSeparator), pridame mu
type 'button', abychom ho mohli vykreslit na libovolne pozici dle textace */
export const getStringsWithMeta = (raw?: string, buttonSeparator?: string): StringMeta[] => {
  if (!raw) {
    return [];
  }
  const separator = buttonSeparator || '%';
  const parts = raw.split(separator);

  const parsed = parts.map((part, index) => {
    if (index === 1) {
      return { type: 'button', content: part };
    }
    return { type: 'text', content: part };
  });

  const result = parsed.filter((p) => p.content !== '');
  return result;
};

const ToastMessage = ({ subtitle, title, onButtonClick }: ToastMessageProps) => {
  const [canShowMessage, setCanShowMessage] = useState(true);
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const startTimeout = !!title && !subtitle;
  const visible = canShowMessage && !!title;

  const messages = getStringsWithMeta(subtitle, BUTTON_SEPARATOR);

  useEffect(() => {
    // Zobrazime toast pri zmene chyby
    setCanShowMessage(true);
  }, [title]);

  // Zajistime, ze pri chybejicim tlacitku pro reload chybova hlaska po chvili zmizi
  useEffect(() => {
    if (!startTimeout) {
      return () => {};
    }

    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }

    timeoutRef.current = setTimeout(() => {
      setCanShowMessage(false);
    }, TOAST_MESSAGE_TIMEOUT);

    return () => {
      if (timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
    };
  }, [startTimeout]);

  return (
    <div aria-atomic="true" aria-live="polite" aria-relevant="all">
      <div
        className={classNames(toastMessageClassnames.wrapper, {
          visible,
        })}
      >
        <IconErrorCircle aria-hidden className={toastMessageClassnames.icon} />
        <div>
          <div className={toastMessageClassnames.title}>{title}</div>
          <div className={toastMessageClassnames.subtitle}>
            {messages.map((message) =>
              message.type === 'text' ? (
                <span key={message.content}>{message.content}</span>
              ) : (
                <PlayerNativeButton
                  className={toastMessageClassnames.reloadButton}
                  data-testid="ToastReload"
                  key={message.content}
                  onClick={onButtonClick}
                >
                  {message.content}
                </PlayerNativeButton>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(ToastMessage);
