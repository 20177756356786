/* eslint-disable max-lines */
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
import {
  AnalyticsEvents,
  AnalyticsSubscriber,
  GtmLivePlaylistResponse,
  NielsenMainContentPayload,
  GemiusMainContentPayload,
} from '@czechtv/analytics';
import { Encoder } from '@czechtv/utils';
import { shouldPlayDash } from '../../utils/shouldPlayDash';
import { PlayerRouterProvider } from '../../Providers/Router/usePlayerRouter';
import { getValidPreviewImage } from '../../utils/getValidPreviewImage';
import {
  PlayerDynamicImportProvider,
  PlayerSetupProvider,
} from '../../Providers/Setup/usePlayerSetup';
import windowRouter from '../../Providers/Router/windowRouter';
import basicDynamicImport from '../../Providers/Setup/DynamicImport/basicDynamicImport';
import {
  AutoplayCapability,
  Queue,
  PlayerStreamType,
  PlayerVariantEnum,
  Product,
  RefetchPlaylistController,
  StreamQuality,
  CachedAds,
  PlayerAdType,
  LiveStreamEndReason,
  UserVideoProgressMeta,
  NO_GAP_LIVE_TIMESHIFT_BUFFER,
  TIMESHIFT_GAP_DURATION,
  VideoSubtitles,
  VideoIndex,
  ExternalLiveStreams,
  ExternalVastConfig,
} from '../../constants';
import { PlayerError } from '../../Providers/Client/PlayerError';
import {
  PlayerLoaderRef,
  PlayerLoaderRefProvider,
} from '../../Providers/Client/PlayerLoaderRefContext';
import { PlayerClient } from '../../Providers/Client';
import { useComponentReloader } from '../../utils/useComponentReloader';
import { PlayerErrorOverlay } from '../Error/Overlay/Overlay';
import PlayerAnalyticsProvider from '../../Providers/Analytics/useAnalyticsContext';
import PlayerContextProvider from '../../Player/PlayerContext';
import {
  PlayerClientProvider,
  usePlayerClientContext,
} from '../../Providers/Client/PlayerClientContext';
import { PlayerLoadingOverlay } from '../PlayerLoadingOverlay';
import { useDefaultAnalyticsSubscribers } from '../Analytics/useDefaultAnalyticsSubscribers';
import AnalyticsContextFactory from '../../Providers/Analytics/AnalyticsContextFactory';
import { autoPlayTester } from '../../utils/autoPlayTester';
import { getPlaylistData } from '../../utils/playlists/usePlaylist';
import {
  GEMIUS_MAIN_CONTENT_EMPTY,
  PlaylistData,
  NIELSEN_MAIN_CONTENT_EMPTY,
  PlaylistOptions,
} from '../../utils/playlists/constants';
import { getAudioOnlyInfoFromStreamUrl } from '../../utils/audioOnly';
import Player from '../../Player';
import { DRM } from '../../utils/drm';
import { legacyGetAdData, getNewAdsAsPlayerStream } from '../../utils/ads';
import { getOffsetFromStreamUrl, getRawQueue } from '../../utils/playlists/utils';
import { PlaylistError } from '../Error/playlistError/playlistError';
import { getCookie, IVYS_ID_TOKEN_COOKIE_NAME } from '../../utils/cookies';
import { log } from '../../utils/logger';
import { LivePlayerControlsItems, VODPlayerControlsItems } from './controls';
import { PlayerProvider } from './Provider/PlayerProvider';
import { VODAnalyticsData } from './VODtypings';

export interface LiveAnalyticsData {
  gemius: GemiusMainContentPayload;
  gtm: GtmLivePlaylistResponse;
  nielsen: NielsenMainContentPayload;
}

// konfigurace playeru zvenci - z iframu nebo komponenty
export interface ExternalPlayerConfig {
  // Token nutný pro poptání playlistů v admin módu
  accessToken?: string;
  // nastaveni cileni pro AdOcean
  adOceanCustomTargeting?: string[];
  // nastaveni sekcí/rubrik pro AdOcean
  adOceanSections?: string[];
  // specialni string formatovany pro cileni rubrik (CT Sport)
  additionalAdKeywords?: string;
  // Má player běžet v admin módu?
  adminMode?: boolean;
  // věkové omezení
  ageRestriction?: string;
  // Schova vsechny ovladaci prvky
  allControlsHidden?: boolean;
  analyticsSubscribers?: AnalyticsSubscriber[];
  // Chceme mit zapnuty autoplay?
  autoplay?: boolean;
  borderRadius?: boolean;
  // možno použít pro přeskočení kontroly X-GEOIP-COUNTRY
  bypassGeoIp?: boolean;
  // token pro poptání obsahu bez licence
  bypassLicenseToken?: string;
  // pokud true, ignorujeme ulozena nastaveni pro prihlaseneho i neprihlaseneho uzivatele
  bypassUserSettings?: boolean;
  // oříznuti videa u starých playlistů
  cropEnd?: number;
  cropStart?: number;
  // URL na ceskou televizi pouzivana k reportovani chyb
  czechTVBaseUri?: string;
  // za jak dlouho po pauznuti videa je potreba reloadnout stream url z playlistu
  debugStreamPausedTimeout?: number;
  // za jak dlouho expiruji stream urls a je nutne si je poptat znovu
  debugStreamUrlExpiredTimeout?: number;
  // Chceme vynutit pozdejsi volani playlistu? (Az ve chvili, kdy se spusti prehravani)
  delayLoadingPlaylist?: boolean;
  // Zapina dev rezim (pouziva se pro analytiku pro logovani pod testovacimi id)
  dev?: boolean;
  // Vypnout zobrazování reklam?
  disableAds?: boolean;
  // zakáže funkcionalitu airplay
  disableAirplay?: boolean;
  // zakáže funkcionalitu chromecast
  disableChromecast?: boolean;
  // Vypnout zobrazování pegi upozorneni
  disableLabeling?: boolean;
  // zobrazit title a showTitle v zahlavi?
  displayHeaderInfo?: boolean;
  // info o délce videa
  duration?: number;
  // Dynamic import pro integraci v Next.js aplikaci
  dynamicImportProvider?: PlayerDynamicImportProvider;
  endTimestamp?: number;
  // Pro nouzové přehrání vygenerovaných mpd a hls streamu bez volani playlistu (napr. zachrana stranka pri MS v hokeji)
  externalLiveStreams?: ExternalLiveStreams;
  fairplayAccessToken: string;
  // URL na Fairplay certifikat licencni sever
  fairplayLicenseCertificateUrl?: string;
  // URL na Fairplay DRM licencni sever
  fairplayLicenseServerUrl?: string;
  // pro volani linku pro ulozeni informaci pri prechodu na FAQ stranku
  faqReportApiUrl?: string;
  // odkaz na FAQ stranku
  faqUrl?: string;
  // U splashscreenu vypina pomer stran a zapina promenlivou velikost
  fluidAspect?: boolean;
  // pokusit se rovnou loadnout audio description
  forceAudioDescription?: boolean;
  // rovnou loadnout pouze zvuk
  forceAudioOnly?: { audioOnly: boolean; isExternalAudioDescription: boolean };
  // vynutit zamutovane video
  forceMuted?: boolean;
  // ID pro reportovani do Gemiusu
  gemiusId?: string;
  // ID playeru pro reportovani do Gemiusu
  gemiusPlayerId?: string;
  // schovat v preview overlayi udaj o delce videa
  hideDurationPreview?: boolean;
  //
  liveMode?: number;
  // legacy reseni, potrebuje CT Sport - viz. tabulka v utils
  maxAutoQuality?: number;
  // legacy reseni, potrebuje CT Sport - viz. tabulka v utils
  maxQuality?: number;
  // nyni nepouzivame, asi se k tomu v budoucnu vratime
  maxStreamQuality?: StreamQuality;
  // ID pro reportovani do Nielsenu
  nielsenAppId?: string;
  // Zapnout debugging Nielsenu?
  nielsenDebug?: boolean;
  // Můžeme odstranit mezeru mezi timeshiftem a live?
  noGapTimeshift?: boolean;
  // cb pro errory exponovany ven z playeru
  onError?: (ev: PlayerError | PlaylistError | Error | null) => void;
  // cb pro eventy exponovany ven z playeru
  onEvent?: (ev: AnalyticsEvents) => void;
  // cb po loadu playlistu
  onPlaylistData?: (data: PlaylistData) => void;
  // param dle starých playlistů - kde je player použitý? (Kvůli typu reklam a color themes.)
  origin?: string;
  // URL stránky kde se nachází iframe player
  parentUrl?: string;
  // ref playeru pro ovládání zvenku
  playerRef?: {
    current: PlayerLoaderRef | null;
  };
  // Verze balicku playeru
  playerVersion?: string;
  // URI na LIVE nové playlisty
  playlistLiveUri?: string;
  // URL na staré playlisty
  playlistUri?: string;
  // URI na VOD nové playlisty
  playlistVodUri?: string;
  // URL nahledoveho obrazku playeru, pokud neni zapnuty autoplay
  previewImageUrl?: string;
  // URL na nahledove obrazky pro timeline
  previewTrackBaseUrl?: string;
  // Produkt, ktery prehravac pouziva
  product: Product;
  // hash pro promo videa
  promoHash?: string;
  // za jak dlouho se promo
  promoTimeout?: number;
  // Next router pro pouziti v Next.js aplikaci (kvuli odkazum z playeru ven)
  router?: PlayerRouterProvider;
  // Domena pouzita pro generovani odkazu ke sdileni
  shareVideoDomain?: string;
  // Protokol pouzity pro generovani odkazu ke sdileni
  shareVideoProtocol?: 'http' | 'https';
  // id pořadu
  showId?: string;
  // název pořadu
  showTitle?: string;
  // začátek přehrávání
  startTimeInSeconds?: number;
  startTimestamp?: number;
  // Za jak dlouho chceme nastavit nutnost opětovného poptání playlistů, když se zdržíme v Preview
  // Default je 5 min
  startupRefetchPlaylistTimeout?: number;
  // Chceme vypnout logovani analytiky?
  suppressAnalytics?: boolean;
  // tagy  (pro AdMetadata)
  tags?: string;
  // pouzivat fallback na stare playlisty, pokud nove selzou
  useLegacyPlaylistFallback?: boolean;
  // pouzit nove reklamy?
  useNewAds?: boolean;
  // muzeme nastavit, ze tzv. "zivy IDEC" se bude prehravat z nove CDNky (zavolaji se nove LIVE pl.)
  useNewCdnForLiveIdec?: boolean;
  // pouzit nove playlisty?
  useNewPlaylist?: boolean;
  // chceme pouzit vyhodnoveni licenci v MDA a nove chybove hlasky?
  // false - vyhodnoveni licenci v MM -> PL
  usePlayability?: boolean;
  // externi id uživatele
  userId?: string;
  // nazev cookie obsahujici userId po prihlaseni na produktu
  userIdTokenCookieName?: string;
  // url endpointu na hlaseni rozkoukanosti prihlaseneho uzivatele
  userVideoProgressReportingUrl?: string;
  // externi id prostoru pro sestaveni legacy VASTu (2xpreroll, 1xpostroll - kazdy jedna creative)
  vastConfig?: ExternalVastConfig | null;
  // název videa
  videoTitle?: string;
  widevineAccessToken: string;
  // URL na Widevine DRM licencni sever
  widevineLicenseServerUrl?: string;
}

// konfigurace playeru zevnitr - rozsiruje externi konfiguraci, kterou predava dal
// nepotrebuje uz tokeny, ktere jsou v tuto chvili jiz zpracovane
export interface InternalPlayerConfig
  extends Omit<
    ExternalPlayerConfig,
    'widevineAccessToken' | 'widevineLicenseServerUrl' | 'fairplayAccessToken'
  > {
  // assetId ze stareho playlistu - uzitecne pro nazev kanalu
  assetId?: string;
  // Automaticky spusti video
  autoPlay?: boolean;
  // bonusId
  bonusId?: string;
  borderRadius?: boolean;
  // Casovy udaj konce oriznuti puvodniho videa
  // encoder
  encoder?: string;
  // Gemius objekt pro hlavní obsah
  gemius?: GemiusMainContentPayload;
  // Jsou v playlistu reklamy?
  hasAds?: boolean;
  // idec
  idec?: string | null;
  // indexId
  indexId?: string;
  // Indexy
  indexes?: VideoIndex[];
  // Vekove omezeni
  labeling?: string | null;
  // id hlavniho obsahu obsahu
  mainContentId: string;
  // Název videa: buď parametr zvenčí nebo info z playlistu
  metaTitle?: string;
  // Ztlumit zvuk
  mute?: boolean;
  // Nielsen objekt pro hlavní obsah
  nielsen?: NielsenMainContentPayload;
  // počáteční options pro pozdější dodatečné poptání playlistu
  playlistOptions?: PlaylistOptions;
  // URL nahledoveho obrazku playeru, pokud neni zapnuty autoplay
  // id sezony (pro AdMetadata)
  seasonId?: string;
  // Zacit rozkoukane video v konkretnim case
  startTime?: number;
  // Titulky
  subtitles?: VideoSubtitles[];
  // pole podporovaných DRM, pro vnitřní použití ihned po loadu
  supportedDRMs?: DRM[];
}

export interface ControlledPlayerLoaderProps {
  config: InternalPlayerConfig;
  externalUserId?: string;
  initialPlaylistData: PlaylistData;
  // Callback pro chyby playeru - TODO
  onError?: (errorEvent: PlayerError | PlaylistError | Error) => void;
  // Callback pro interni eventy playeru - TODO
  onEvent?: (event: AnalyticsEvents) => void;
  onLiveStreamEnded?: (reason?: LiveStreamEndReason) => void;
  // Reference na objekt, ktery umoznuje ovladat player zvenci
  playerRef?: {
    current: PlayerLoaderRef | null;
  };
  refetchPlaylistController?: RefetchPlaylistController;
  userVideoProgressMeta?: UserVideoProgressMeta;
}
export interface PlayerLoaderProps extends ControlledPlayerLoaderProps {
  destroy?: () => void;
  userId?: string;
}

const getInitialAdsConfig = ({
  initialPlaylistData,
  disableAds,
}: {
  disableAds?: boolean;
  initialPlaylistData: PlaylistData;
}) => ({
  preRolls: {
    parsed: [],
    source: !disableAds ? initialPlaylistData.vast?.preRoll?.[0] : undefined,
    wasParsed: false,
  },
  postRolls: {
    parsed: [],
    source: !disableAds ? initialPlaylistData.vast?.postRoll?.[0] : undefined,
    wasParsed: false,
  },
});

export const PlayerLoader = (props: PlayerLoaderProps) => {
  const {
    config: {
      assetId,
      product,
      previewImageUrl,
      startTime,
      showId,
      showTitle,
      nielsenAppId,
      gemiusPlayerId,
      gemiusId,
      shareVideoDomain = '',
      shareVideoProtocol = 'https',
      router = windowRouter,
      dynamicImportProvider = basicDynamicImport,
      dev = false,
      nielsenDebug = false,
      allControlsHidden = false,
      // toto je v tehle fazi uz pouze udaj pro analytiku
      // kdyz prislo pres props autoPlay=false, znamena to, ze se nejdriv objevilo
      // PlayerPreview (viz VOD/LivePlayerLoader) a pak az divak stiskl tlacitko play
      // nebo naopak byl autoPlay=true a zacal se rovnou vykreslovat PlayerLoader bez preview
      // v tento moment je pak klasicky autoPlay pro video element vzdy true
      // pokud neselze detekce autoPlaye
      autoPlay: desiredAutoPlay = false,
      suppressAnalytics: defaultSuppressAnalytics,
      analyticsSubscribers: providedAnalyticsSubscribers = [],
      indexes,
      subtitles,
      labeling,
      videoTitle,
      metaTitle,
      previewTrackBaseUrl,
      idec,
      disableAds,
      bypassUserSettings,
      bonusId,
      mainContentId,
      indexId,
      encoder,
      forceAudioDescription,
      parentUrl,
      playlistOptions,
      debugStreamPausedTimeout,
      debugStreamUrlExpiredTimeout,
      playerVersion,
      nielsen,
      gemius,
      supportedDRMs,
      displayHeaderInfo,
      disableAirplay,
      disableChromecast,
      useNewAds,
      borderRadius,
      forceMuted,
    },
    onEvent,
    onError,
    onLiveStreamEnded,
    refetchPlaylistController,
    initialPlaylistData,
    userId,
    userVideoProgressMeta,
  } = props;
  const [playlistData, setPlaylistData] = useState<PlaylistData>(initialPlaylistData);
  const [autoPlayCapability, setAutoPlayCapability] = useState(AutoplayCapability.UNKNOWN);
  const { key, reloadComponent } = useComponentReloader();
  const playerWrapperRef = React.createRef<HTMLDivElement>();
  const [playbackId] = useState(uuid());
  const playerClientContext = usePlayerClientContext();
  const playerClient = playerClientContext?.playerClient;
  const suppressAnalytics = defaultSuppressAnalytics || !playlistData;
  const [queue, setQueue] = useState<Queue | null>(null);
  const [queuePosition, setQueuePosition] = useState(0);
  const refetchFlagRef = useRef<boolean>(!!refetchPlaylistController?.shouldRefetchPlaylist);
  const forceAudioOnlyFlagRef = useRef({
    audioOnly: !!playlistOptions?.forceAudioOnly?.audioOnly,
    isExternalAudioDescription: !!forceAudioDescription,
  });
  // toto slouzi pouze pro nove reklamy
  const cachedPrerolls = useRef<CachedAds>(
    getInitialAdsConfig({ initialPlaylistData, disableAds }).preRolls
  );
  const cachedPostrolls = useRef<CachedAds>(
    getInitialAdsConfig({ initialPlaylistData, disableAds }).postRolls
  );

  const setForcedAudioOnlyFlag = useCallback(
    (audioOnly: boolean, isExternalAudioDescription: boolean) => {
      forceAudioOnlyFlagRef.current = { audioOnly, isExternalAudioDescription };
    },
    []
  );

  const fetchPostrolls = useCallback(async () => {
    cachedPostrolls.current.parsed = await getNewAdsAsPlayerStream({
      params: cachedPostrolls.current.source,
      type: PlayerAdType.postRoll,
    });
    cachedPostrolls.current.wasParsed = true;
    // kdyz nedostaneme postrolly (vrati se prazdne pole), nechame priznak wasParsed na false
    if (!cachedPostrolls.current.parsed.length) {
      cachedPostrolls.current.wasParsed = false;
    }
  }, []);

  const resetAds = useCallback(() => {
    const { preRolls, postRolls } = getInitialAdsConfig({ initialPlaylistData, disableAds });
    cachedPrerolls.current = preRolls;
    cachedPostrolls.current = postRolls;
  }, [initialPlaylistData, disableAds]);

  const getRefetchFlag = useCallback(() => {
    return refetchFlagRef.current;
  }, []);

  const setRefetchFlag = useCallback((value: boolean) => {
    refetchFlagRef.current = value;
  }, []);

  useEffect(() => {
    if (!onError) {
      return () => {};
    }
    const unsub = log.subscribe({
      name: 'onErrorCallback',
      methods: {
        error: (values) => {
          if (values.error) {
            onError(values.error as Error);
          }
        },
      },
    });

    return () => {
      unsub();
    };
  }, [onError]);

  useEffect(() => {
    if (autoPlayCapability !== AutoplayCapability.UNKNOWN) {
      return;
    }
    async function detectAutoplay() {
      let autoPlayCapabilityState = AutoplayCapability.TESTING;
      setAutoPlayCapability(autoPlayCapabilityState);
      const { autoPlay, muted } = await autoPlayTester();
      if (autoPlay) {
        autoPlayCapabilityState = !forceMuted ? AutoplayCapability.FULL : AutoplayCapability.MUTED;
      } else if (muted) {
        autoPlayCapabilityState = AutoplayCapability.MUTED;
      } else {
        autoPlayCapabilityState = !forceMuted ? AutoplayCapability.NONE : AutoplayCapability.MUTED;
      }
      setAutoPlayCapability(autoPlayCapabilityState);
      log.info({
        message: `Autostart: ${desiredAutoPlay}. Browser autoplay: ${autoPlayCapabilityState}.`,
      });
    }
    void detectAutoplay();
  }, [autoPlayCapability, desiredAutoPlay, forceMuted]);

  const getQueue = useCallback(
    async (position: number, refetch?: boolean): Promise<Queue> => {
      // pokud je flag nastaveny na refetch, provolame nejdriv znovu playlisty
      // nova data spusti znovu useEffect
      let refetchedPlaylistData: PlaylistData | null = null;
      if ((refetchFlagRef.current || refetch) && playlistOptions) {
        refetchedPlaylistData = await getPlaylistData({
          ...playlistOptions,
          forceAudioOnly: {
            audioOnly: !!forceAudioOnlyFlagRef.current.audioOnly,
            isExternalAudioDescription: !!forceAudioOnlyFlagRef.current.isExternalAudioDescription,
          },
          supportedDRMs: supportedDRMs || [],
        });
        setRefetchFlag(false);
        setPlaylistData(refetchedPlaylistData);
      }

      // V pripade noveho reseni reklam si je nazacatku fetchneme a nacachujeme
      if (useNewAds && position === 0 && !cachedPrerolls.current.wasParsed) {
        // Na zacatku zkusime poptat pole novych reklam
        cachedPrerolls.current.parsed = await getNewAdsAsPlayerStream({
          params: cachedPrerolls.current.source,
          type: PlayerAdType.preRoll,
        });
        cachedPrerolls.current.wasParsed = true;
      }

      /* 
      Udelame si transformaci puvodnich playlistu do fronty.
      Neresime poptavani reklam, pouze predame odkaz na reklamni system.
      Reklamy chceme vzdy poptavat az v momente, kdy jsou treba - do te doby jsou ve formatu
      "adSource".
      V pripade noveho reseni reklam pouzivame nacachovane reklamy (cachedPrerolls/Postrolls),
      ktere poptavame zvlast (vraci pole), jsou uz prehratelne (PlayerStreamType.ad).
      Prerolly poptavame na zacatku a vzhledem k tomu, ze si potrebujeme na nechat ve fronte
      misto na alespon jeden postRoll, ve fronte se nam na poslednim miste vraci adPlaceholder.
      V momente, kdy skonci v Playeru hlavni obsah a prehravac narazi na tento placeholder, pokusi
      se poptat reklamy a naplnit cachedPostrolls. Fronta bud vrati na posledni pozici reklamu nebo
      reklamy (v tom pripade se fronta jeste natahne), a nebo vrati samotny adPlaceholder, ktery
      je neprehratelny, v tom pripade playback konci. 
      */
      const newQueue = getRawQueue(refetchedPlaylistData || playlistData, {
        useNewAds: !!useNewAds,
        disableAds,
        cachedPrerolls: cachedPrerolls.current,
        cachedPostrolls: cachedPostrolls.current,
      });

      if (newQueue.length && newQueue[position]) {
        // zabyvame se polozkou na aktualni pozici "queuePosition"
        const queueItem = newQueue[position];

        // legacy reklamy (1 vast = 1 reklama)
        // pokud je soucasna polozka reklama, zkusime, jestli nam ji reklamni system vrati
        if (queueItem.type === PlayerStreamType.adSource) {
          const legacyAdData = await legacyGetAdData({
            url: queueItem.url,
            type: queueItem.category,
            index: queueItem.index,
          });
          // pokud ano pouzijeme data a z polozky na "poptavani" vznikne prehratelna polozka
          if (legacyAdData) {
            const { dashUrl, hlsUrl, ...rest } = legacyAdData;
            const url = shouldPlayDash() ? dashUrl : hlsUrl;
            newQueue[position] = {
              id: Date.now().toString(),
              url,
              index: queueItem.index,
              type: PlayerStreamType.ad,
              category: queueItem.category,
              meta: { ...rest },
            };
            // prenastavime frontu
            const queue = {
              items: newQueue,
              current: newQueue[position],
              position,
              length: newQueue.length,
            };
            return queue;
          }
          // pokud reklama nic nevrati, neprehravame a posuneme pozici, pokud je to mozne
          if (position + 1 < newQueue.length) {
            return getQueue(position + 1);
          }
        }
      }
      // v ostatnich pripadech vratime frontu
      // (current je bud normalni obsah, labeling, jiz transformovana reklama nebo zbytek postrollu
      // ve tvaru adSource nebo adPlaceholder, coz je reklama, kterou reklamni system nechce
      // prehravat - zpet v Playeru, kdyz prijde v props nova fronta s neprehratelnym postrollem,
      // tak to znamena, ze koncime)
      const queue = {
        items: newQueue,
        current: newQueue[position],
        position,
        length: newQueue.length,
      };
      return queue;
    },
    [playlistOptions, useNewAds, playlistData, disableAds, supportedDRMs, setRefetchFlag]
  );

  useEffect(() => {
    const updateQueueState = async () => {
      const queue = await getQueue(queuePosition);
      setQueue(queue);
      setQueuePosition(queue.position);
    };
    void updateQueueState();
  }, [queuePosition, getQueue]);

  const defaultAnalyticsSubscribers = useDefaultAnalyticsSubscribers({
    product,
    dev,
    gemiusId,
    gemiusPlayerId,
    nielsenAppId,
    nielsenDebug,
    suppressAnalytics,
  });

  const config = useMemo(
    () => ({
      SHARE_VIDEO_DOMAIN: shareVideoDomain,
      SHARE_VIDEO_PROTOCOL: shareVideoProtocol,
    }),
    [shareVideoDomain, shareVideoProtocol]
  );

  // zde muzeme pripadne pridat i nejake cb nebo promenne, ktere chceme zobrazit v debug overlayi
  const debugging = useMemo(
    () => ({
      streamPausedTimeout: debugStreamPausedTimeout,
      streamUrlExpiredTimeout: debugStreamUrlExpiredTimeout,
      playerVersion,
    }),
    [debugStreamPausedTimeout, debugStreamUrlExpiredTimeout, playerVersion]
  );

  const analyticsSubscribers: AnalyticsSubscriber[] = suppressAnalytics
    ? []
    : ([...defaultAnalyticsSubscribers, ...providedAnalyticsSubscribers] as AnalyticsSubscriber[]);

  if (onEvent) {
    analyticsSubscribers.push({
      callback: onEvent,
      name: 'onEvent',
    });
  }

  const previewImage = getValidPreviewImage(previewImageUrl);

  if (
    !queue ||
    [AutoplayCapability.TESTING, AutoplayCapability.UNKNOWN].includes(autoPlayCapability)
  ) {
    return <PlayerLoadingOverlay borderRadius={borderRadius} />;
  }

  let initialAnalyticsData: VODAnalyticsData | LiveAnalyticsData | undefined;
  if (playlistData.type === 'vod') {
    initialAnalyticsData = {
      gemius: gemius
        ? { ...gemius }
        : {
            ...GEMIUS_MAIN_CONTENT_EMPTY,
          },
      gtm: {
        id: mainContentId,
        title: metaTitle || showTitle || '',
      },
      nielsen: nielsen
        ? { ...nielsen }
        : {
            ...NIELSEN_MAIN_CONTENT_EMPTY,
          },
    };
  }

  if (playlistData.type === 'live') {
    initialAnalyticsData = {
      gemius: gemius
        ? { ...gemius }
        : {
            ...GEMIUS_MAIN_CONTENT_EMPTY,
          },
      gtm: {
        id: mainContentId || assetId || '',
        title: metaTitle || '',
      },
      nielsen: nielsen
        ? { ...nielsen }
        : {
            ...NIELSEN_MAIN_CONTENT_EMPTY,
          },
    };
  }

  if (!initialAnalyticsData || !playlistOptions) {
    return (
      <PlayerProvider borderRadius={borderRadius}>
        {/* Prázdný video tag pro SEO účely */}
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <video hidden poster={previewImage} />
        <PlayerErrorOverlay />
      </PlayerProvider>
    );
  }

  const previewTrackStartOffset =
    indexId && playlistData.streamUrls[0]
      ? getOffsetFromStreamUrl(playlistData.streamUrls[0].main, 'startOffset')
      : 0;

  const noGapTimeshift = !!playlistOptions.noGapTimeshift && playlistData.type === 'live';
  // v pubu maji specificke potreby pro zobrazovani playeru
  const isPubMode = playlistOptions.origin === 'pub';
  const isAudioOnly = getAudioOnlyInfoFromStreamUrl(playlistData.streamUrls);
  return (
    <PlayerProvider borderRadius={borderRadius} key={key} wrapperRef={playerWrapperRef}>
      <PlayerSetupProvider
        analyticsSubscribers={analyticsSubscribers}
        autoPlayCapability={autoPlayCapability}
        config={config}
        defaultPlayerClient={playerClient}
        disableAds={disableAds}
        disablePlayerCustomizations={allControlsHidden}
        dynamicImportProvider={dynamicImportProvider}
        forceAudioDescription={forceAudioDescription}
        playbackId={playbackId}
        playerVariant={
          playlistData.type === 'live' && queue.current.type !== PlayerStreamType.ad
            ? PlayerVariantEnum.LIVE
            : PlayerVariantEnum.VOD
        }
        playerWrapperRef={playerWrapperRef}
        product={product}
        reload={reloadComponent}
        router={router}
        showSimpleVideoHeader={!displayHeaderInfo}
      >
        <AnalyticsContextFactory
          {...(playlistData.type === 'live'
            ? { encoder: (encoder || playlistData.encoder || '') as Encoder }
            : {})}
          analyticsData={initialAnalyticsData}
          assetId={assetId}
          desiredAutoPlay={desiredAutoPlay}
          externalId={idec || undefined}
          isAudioOnly={isAudioOnly}
          mainContentId={mainContentId}
          origin={playlistOptions.origin}
          product={product}
          showId={showId}
          showTitle={showTitle}
          title={videoTitle}
          type={playlistData.type}
        >
          <PlayerAnalyticsProvider
            timeshiftGapDuration={
              noGapTimeshift ? NO_GAP_LIVE_TIMESHIFT_BUFFER : TIMESHIFT_GAP_DURATION
            }
          >
            <PlayerContextProvider
              {...(playlistData.type === 'live'
                ? { encoder: playlistData.encoder || ('' as Encoder) }
                : {})}
              bonusId={bonusId}
              cropEnd={props.config.cropEnd}
              cropStart={props.config.cropStart}
              defaultAgeRestriction={labeling}
              defaultIndexes={indexes || []}
              defaultShowTitle={showTitle}
              defaultVideoTitle={videoTitle}
              disableAirplay={disableAirplay}
              disableChromecast={disableChromecast}
              endTimestamp={playlistOptions.endTimestamp}
              idec={idec}
              indexId={indexId}
              isAudioOnly={isAudioOnly}
              isNewPlaylist={!!playlistOptions.useNewPlaylist}
              liveMode={playlistOptions.liveMode}
              mainContentId={mainContentId}
              noGapTimeshift={noGapTimeshift}
              parentUrl={parentUrl}
              playlistOptions={playlistOptions}
              previewImage={previewImage}
              previewTrackBaseUrl={previewTrackBaseUrl || ''}
              previewTrackStartOffset={previewTrackStartOffset}
              showId={showId}
              startTimestamp={playlistOptions.startTimestamp}
              subtitles={subtitles}
            >
              <Player
                bypassUserSettings={bypassUserSettings}
                controlsItems={
                  playlistData.type === 'vod' ? VODPlayerControlsItems : LivePlayerControlsItems
                }
                debugging={debugging}
                fetchPostrolls={fetchPostrolls}
                getQueue={getQueue}
                getRefetchFlag={getRefetchFlag}
                hideControlsWhenPaused={isPubMode}
                initialDebugMode={isPubMode}
                previewImage={previewImage}
                queue={queue}
                queuePosition={queuePosition}
                refetchPlaylistController={refetchPlaylistController}
                resetAds={resetAds}
                setForcedAudioOnlyFlag={setForcedAudioOnlyFlag}
                setQueuePosition={setQueuePosition}
                setRefetchFlag={setRefetchFlag}
                startTime={startTime}
                subtitles={subtitles}
                userId={userId}
                userVideoProgressMeta={userVideoProgressMeta}
                onLiveStreamEnded={onLiveStreamEnded}
              />
            </PlayerContextProvider>
          </PlayerAnalyticsProvider>
        </AnalyticsContextFactory>
      </PlayerSetupProvider>
    </PlayerProvider>
  );
};

export const ControlledPlayerLoader = memo((props: ControlledPlayerLoaderProps) => {
  const [shouldRender, setShouldRender] = useState(true);
  const [playerClient, setPlayerClient] = React.useState<PlayerClient | undefined>();
  const [userId, setUserId] = useState<string | null | undefined>(null);

  useEffect(() => {
    if (shouldPlayDash()) {
      import('../../Providers/Client/Shaka/ShakaPlayerClient')
        .then((client) => {
          setPlayerClient(new client.ShakaPlayerClient());
        })
        .catch((error) => {
          log.error({ error, message: 'Error importing ShakaClient in PlayerLoader' });
        });
    } else {
      import('../../Providers/Client/Native/NativeClient')
        .then((client) => {
          setPlayerClient(new client.NativeClient());
        })
        .catch((error) => {
          log.error({ error, message: 'Error importing NativeClient in PlayerLoader' });
        });
    }
  }, []);

  useEffect(() => {
    if (!props.userVideoProgressMeta) {
      log.info({ message: 'Skipping user. Required video ids were not provided.' });
      setUserId(undefined);
      return;
    }
    if (props.externalUserId) {
      log.info({ message: 'Using external user id!' });
      setUserId(props.externalUserId);
      return;
    }
    try {
      const tokenId = getCookie(props.config.userIdTokenCookieName || IVYS_ID_TOKEN_COOKIE_NAME);
      if (!tokenId) {
        setUserId(undefined);
        return;
      }
      const [, payloadContent] = tokenId.split('.');
      const rawData = Buffer.from(payloadContent, 'base64');
      const result = JSON.parse(rawData.toString());
      if (result.user_id) {
        log.info({ message: 'User is logged in.' });
        setUserId(result.user_id);
      } else {
        setUserId(undefined);
      }
    } catch (e) {
      log.error({ error: e, message: 'Unable to parse user id from idToken.' });
      setUserId(undefined);
    }
  }, [props.externalUserId, props.userVideoProgressMeta, props.config.userIdTokenCookieName]);

  const onPlayerLoaderRefChange = useCallback(
    (playerLoaderRef: PlayerLoaderRef) => {
      if (!props.playerRef) {
        return;
      }

      props.playerRef.current = playerLoaderRef;
    },
    [props.playerRef]
  );

  if (!shouldRender || !playerClient || userId === null) {
    return <PlayerLoadingOverlay borderRadius={props.config.borderRadius} />;
  }

  return (
    <PlayerClientProvider playerClient={playerClient}>
      <PlayerLoaderRefProvider onPlayerLoaderRefChange={onPlayerLoaderRefChange}>
        <PlayerLoader {...props} destroy={() => setShouldRender(false)} userId={userId} />
      </PlayerLoaderRefProvider>
    </PlayerClientProvider>
  );
});
