/* eslint-disable canonical/filename-no-index */
/* eslint-disable max-lines */
/* eslint-disable perfectionist/sort-classes */
import React, { Component, ComponentType, KeyboardEvent, MouseEvent, RefObject } from 'react';
import throttle from 'lodash/throttle';
import clamp from 'lodash/clamp';
import memoize from 'lodash/memoize';
import compose from 'lodash/fp/compose';
import debounce from 'lodash/debounce';
import { formatTime, storageAvailable } from '@czechtv/utils';
import { isMobileIOS, isTouch } from '@czechtv/styles';
import { AnalyticsPlayerContext } from '@czechtv/analytics';
import { StreamQualityController } from '../components/Loader/VODtypings';
import {
  PlayerLoaderRefContextValue,
  withPlayerLoaderRef,
} from '../Providers/Client/PlayerLoaderRefContext';
import {
  PlayerAnalyticsValues,
  withPlayerAnalytics,
} from '../Providers/Analytics/useAnalyticsContext';
import { PlayerSetupValues, withPlayerSetup } from '../Providers/Setup/usePlayerSetup';
import {
  BufferingEvent,
  createUniqueVideoId,
  INDEX_LOOKUP_THROTTLE,
  PLAYER_VERSION,
  PlayerVariantEnum,
  ScreenMode,
  VOLUME_CHANGE_EVENT_THROTTLE,
  PlayerStreamType,
  PlayerMode,
  VideoLoadOptions,
  LANG_CODE_AD,
  AutoplayCapability,
  Debugging,
  RefetchPlaylistController,
  TIMESHIFT_CONTROLS_END_OFFSET,
  TIMESHIFT_END_OFFSET,
  Queue,
  PlayerAdType,
  SEEKING_THROTTLE,
  PlayerStream,
  FULL_LIVE_STREAM_LENGTH,
  LiveMode,
  LiveStreamEndReason,
  UserVideoProgressMeta,
  UserVideoProgressPayload,
  END_CREDITS_MAX_IN_SECONDS,
  RETURN_TO_LIVE_AFTER_PAUSE_IN_MS,
  VideoIndex,
  MANIFEST_DEFAULT_SUBTILES_TAG,
} from '../constants';
import { ClosedCaptions } from '../components/ClosedCaptions/ClosedCaptions';
import { usesNativeFullscreen } from '../utils/usesNativeFullscreen';
import { log } from '../utils/logger';
import { PlayerSubtitles } from '../Providers/Client';
import { AudioTrackSelectOptions, PlayerAudioTrack } from '../utils/types';
import PlayerClientState from '../Providers/Client/state';
import Video from '../components/Video/Video';
import { isNetworkOnline } from '../components/Error/playerError';
import ErrorToastMessage from '../components/Error/ToastMessage/ToastMessage';
import { getErrorMessage } from '../components/Error/errorMessage';
import { PlayerErrorCategory } from '../components/Error/PlayerErrorCategory';
import { PlayerError } from '../Providers/Client/PlayerError';
import {
  enterFullscreen,
  getScreenMode,
  hasWebkitFullscreen,
  leaveFullscreen,
} from '../utils/screenMode';
import { getIsDrmOnlyFromUrl } from '../utils/drm';
import { TimerEvent, TIMER_EVENT } from '../utils/timer';
import { getAudioLanguageLabel } from '../utils/getAudioLanguageLabel';
import { ChromecastState } from '../utils/chromecast/useChromecast';
import { getReadyOverlayDuration } from '../utils/getDuration';
import { getInitialResolution } from '../utils/shaka/utils';
import Controls from './Controls/Controls';
import { OverlayIconType } from './Overlay/VOD/Icon/typeDefs';
import { VideoIndexList } from './Controls/VOD/VideoIndexList/VideoIndexList';
import KeyboardControlFactory, {
  KeyboardControl,
} from './Overlay/VOD/KeyboardControl/KeyboardControl';
import Overlay, { OverlayHalf } from './Overlay/VOD/VOD';
import { PlayerControlsItem } from './Controls/items';
import { PlayerContext, PlayerContextValues } from './PlayerContext';
import { VideoHeader } from './Controls/Common/VideoHeader/VideoHeader';
import {
  PLAYER_SETTINGS_LOCAL_STORAGE_CACHE_KEY,
  PlayerSettings,
  DEFAULT_PLAYER_SETTINGS,
  getLocalStoragePlayerSettings,
  TimeDisplaySwitch,
} from './playerSettings';
import {
  MAX_CACHE_VIDEO_COUNT,
  VideoInProgress,
  VIDEOS_IN_PROGRESS_LOCAL_STORAGE_CACHE_KEY,
} from './videosInProgress';
import { DebugTools } from './DebugTools/DebugTools/DebugTools';
import { Warning } from './DebugTools/Warning/Warning';
import { ExtraControls } from './Controls/Common/ExtraControls/ExtraControls';
import { getResolutionLabelForAnalytics } from './Controls/Common/SettingsMenu/SettingsMenu';

interface Props extends Partial<PlayerLoaderRefContextValue> {
  autofocus?: boolean;
  borderRadius?: boolean;
  bypassUserSettings?: boolean;
  controlsItems?: PlayerControlsItem[];
  debugging?: Debugging;
  fetchPostrolls: () => Promise<void>;
  getQueue: (position: number, refetch?: boolean) => Promise<Queue>;
  getRefetchFlag: () => boolean;
  hideControlsWhenPaused?: boolean;
  initialDebugMode?: boolean;
  mainContentDuration?: number;
  onLiveStreamEnded?: (reason?: LiveStreamEndReason) => void;
  playerAnalytics: PlayerAnalyticsValues;
  playerSetup: PlayerSetupValues;
  previewImage?: string;
  qualityController?: StreamQualityController;
  queue: Queue;
  queuePosition: number;
  refetchPlaylistController?: RefetchPlaylistController;
  resetAds: () => void;
  setForcedAudioOnlyFlag: (audioOnly: boolean, isExternalAudioDescription: boolean) => void;
  setQueuePosition: React.Dispatch<React.SetStateAction<number>>;
  setRefetchFlag: (value: boolean) => void;
  startTime?: number;
  subtitles?: PlayerSubtitles[] | null;
  userId?: string;
  userVideoProgressMeta?: UserVideoProgressMeta;
}

interface State {
  activeIndex: VideoIndex | null;
  adClicked: boolean;
  adaptiveResolution: number | null;
  audioTracks: PlayerAudioTrack[] | null;
  buffered: number;
  canSeekToStartTimestamp: boolean;
  canUseAutoplay: boolean;
  controlsHovered: boolean;
  controlsVisible: boolean;
  debugMode: boolean;
  duration: number;
  hiddenControlsDisabled: boolean;
  isExternalAudioDescription: boolean;
  // zjišťujeme, jestli je uživatel v live playlistu nebo v timeshift playlistu
  isLiveStream: boolean | null;
  isMuted: boolean;
  isPlayerSettingsInitiated: boolean;
  isTextTrackVisible: boolean;
  isVideoOnly: boolean;
  liveTimelineInSeconds: number;
  playerMode?: PlayerMode;
  playerState: PlayerClientState;
  screenMode: ScreenMode;
  selectedAudioTrack: PlayerAudioTrack | null;
  selectedResolution: number | null;
  selectedSubtitle: PlayerSubtitles | null;
  skipAdInSeconds?: number;
  startTime?: number;
  subtitles: PlayerSubtitles[] | null;
  timeDisplay?: TimeDisplaySwitch;
  volume: number;
  wasUnmuted: boolean;
}

export const PLAYER_STATE_POLL_INTERVAL = 300;
const PLAYER_VIDEO_PROGRESS_CACHE_INTERVAL = 10000;
const PLAYER_BUFFERED_INTERVAL = 1000;
const PLAYER_NETWORK_ERROR_INTERVAL = 1000;
const PLAYER_LIVE_TIMESHIFT_INTERVAL = 1000;
const PLAYER_PREV_INDEX_BUFFER = 10;
const STREAM_URL_EXPIRED_TIMEOUT = 50 * 60 * 1000;
const STREAM_PAUSED_TIMEOUT = 25 * 60 * 1000;
export const PLAYER_USER_INACTIVE_TIMEOUT = 2000;
export const PLAYER_SEEK_BACK_SECONDS = 10;
export const PLAYER_SEEK_FORWARD_SECONDS = 10;
export const PLAYER_VOLUME_KEYPRESS_DELTA = 0.1;
export const PLAYER_MOUSE_LEAVE_DEBOUNCE = 200;
const RECENT_PLAYBACK_START_BUFFER = 3000;
const CONTROLS_ANIMATION_OFF_TIMEOUT = 2000;

const getCanUseAutoPlay = (capability: AutoplayCapability) => {
  return [AutoplayCapability.FULL, AutoplayCapability.MUTED].includes(capability);
};

class Player extends Component<Props, State> {
  declare context: PlayerContextValues;

  videoRef: RefObject<HTMLVideoElement> = React.createRef();

  overlayRef: RefObject<HTMLDivElement> = React.createRef();

  playerStatePollInterval: ReturnType<typeof setTimeout> | null = null;

  playerVideoProgressCacheInterval: ReturnType<typeof setTimeout> | null = null;

  playerBufferingInterval: ReturnType<typeof setTimeout> | null = null;

  timeShiftInterval: ReturnType<typeof setTimeout> | null = null;

  userInactiveTimeout: ReturnType<typeof setTimeout> | null = null;

  streamUrlExpiredTimeout: ReturnType<typeof setTimeout> | null = null;

  streamPausedTimeout: ReturnType<typeof setTimeout> | null = null;

  checkPlayerStateInterval: ReturnType<typeof setTimeout> | null = null;

  keyboardControl: KeyboardControl | null = null;

  mounted = false;

  requestedSubtitles: PlayerSubtitles | null = null;

  persitentSettingsUpdated = {
    muted: false,
    volume: false,
  };

  isStreamLoaded = false;

  // kvuli seekovani z finished overlaye si potrebujeme pamamtovat posledni duration pred postrolly
  lastKnownMainContentDuration?: number = undefined;

  // pouzivame pro load od konkretniho casu pri seekovani z finished overlaye
  seekToTimeWhenFinished?: number = undefined;

  // flag pokud nechceme menit current currentQueue, ale chceme pouze reloadnout tu samou polozku
  forcePlaySamePosition = false;

  streamPausedTimeoutInMs = this.props.debugging?.streamPausedTimeout || STREAM_PAUSED_TIMEOUT;

  streamUrlExpiredTimeoutInMs =
    this.props.debugging?.streamUrlExpiredTimeout || STREAM_URL_EXPIRED_TIMEOUT;

  analyticsContextSnapshot?: AnalyticsPlayerContext = undefined;

  dontResetPlaytime = false;

  shouldTryReloadSubtitles = false;

  isMobileIOS = isMobileIOS();

  wasPlayingBeforeTabChange = false;

  userVideoProgressErrorLogged = false;

  recentPlaybackStart = false;

  controlsAnimationDisabled = true;

  pausedAtVideoCurrentTime: number | undefined = undefined;

  pausedTimestamp: number | undefined = undefined;

  isFirstLoad = true;

  // další blbost kvůli analytice, v nové verzi se bude analytika přizpůsobovat playeru a ne player analytice
  playEventCount = 0;

  constructor(props: Props) {
    super(props);

    const playerSettings = this.getDefaultPlayerSettings();

    this.state = {
      activeIndex: null,
      adaptiveResolution: null,
      adClicked: false,
      audioTracks: null,
      buffered: 0,
      canSeekToStartTimestamp: false,
      // Pokud je zakazan autoplay, nebudeme ho pouzivat
      canUseAutoplay: getCanUseAutoPlay(this.props.playerSetup.autoPlayCapability),
      controlsHovered: false,
      controlsVisible: false,
      debugMode: !!this.props.initialDebugMode,
      duration: 0,
      hiddenControlsDisabled: false,
      isExternalAudioDescription: false,
      isLiveStream: null,
      isMuted: this.props.playerSetup.autoPlayCapability === AutoplayCapability.MUTED,
      isPlayerSettingsInitiated: false,
      isTextTrackVisible: playerSettings.subtitlesActive,
      isVideoOnly: false,
      playerMode: PlayerMode.content,
      playerState: PlayerClientState.INVALID,
      screenMode: ScreenMode.NORMAL,
      selectedAudioTrack: null,
      selectedResolution: playerSettings.resolution,
      selectedSubtitle: null,
      skipAdInSeconds: undefined,
      startTime: this.props.startTime,
      timeDisplay: playerSettings.timeDisplay,
      subtitles: null,
      liveTimelineInSeconds: FULL_LIVE_STREAM_LENGTH,
      volume: isTouch() ? 1 : playerSettings.volume,
      wasUnmuted: false,
    };
  }

  getDefaultPlayerSettings = (): PlayerSettings => {
    if (this.props.bypassUserSettings) {
      return DEFAULT_PLAYER_SETTINGS;
    }
    const parsedSettings = getLocalStoragePlayerSettings();
    if (!parsedSettings) {
      return DEFAULT_PLAYER_SETTINGS;
    }
    try {
      const { captionColorVariant, captionFontSize, liveCaptionsOn } = this.context;
      const parsedSubtitles = parsedSettings.subtitles;
      let subtitles = null;
      if (parsedSubtitles && this.props.subtitles) {
        const match = this.props.subtitles.find(
          (availableSubtitles) => availableSubtitles.code === parsedSubtitles.code
        );
        subtitles = match || null;
      }

      return {
        volume: !Number.isNaN(parsedSettings.volume)
          ? Number(parsedSettings.volume)
          : DEFAULT_PLAYER_SETTINGS.volume,
        muted: !!parsedSettings.muted,
        resolution: parsedSettings.resolution ? Number(parsedSettings.resolution) : null,
        subtitles,
        subtitlesActive: !!parsedSettings.subtitlesActive,
        audioTrack: parsedSettings.audioTrack || null,
        additionalConfig: {
          captionColorVariant,
          captionFontSize,
          liveCaptionsOn,
        },
        timeDisplay: parsedSettings?.timeDisplay || DEFAULT_PLAYER_SETTINGS.timeDisplay,
      };
    } catch (exception) {
      return DEFAULT_PLAYER_SETTINGS;
    }
  };

  componentDidMount() {
    this.mounted = true;
    const { playerVariant, playerClient } = this.props.playerSetup;
    const { isMuted } = this.state;
    const analyticsClient = this.props.playerAnalytics.client;
    const {
      encoderTimeDiff,
      timeshiftGapDuration,
      setPlaybackRate,
      setCurrentStreamUrl,
      setActiveIndexCheckCallback,
    } = this.context;

    playerClient.setAnalyticsClient(analyticsClient as any);
    playerClient.setOnCanPlayListener(this.onCanPlay);
    playerClient.setOnPlaybackEndListener(this.onPlaybackEnd);
    playerClient.setOnPlaybackStartListener(this.onPlaybackStart);
    playerClient.setAdaptiveResolutionChangeListener(this.onAdaptiveResolutionChange);
    playerClient.setErrorListener(this.onError);
    playerClient.setBufferingListener(this.onBuffering);
    playerClient.setTimeChangeListener(this.onThrottledTimeChange);
    setActiveIndexCheckCallback(this.onThrottledTimeChange);
    playerClient.setReloadListener(async () =>
      this.reloadStream({ startTime: this.props.startTime || 0, play: false })
    );
    playerClient.setSelectedAudioTrackCb(() => this.state.selectedAudioTrack);
    playerClient.setOnResetPlaybackRateCb(() => setPlaybackRate(1));

    playerClient.setUpdateCurrentStreamUrlCb(setCurrentStreamUrl);

    playerClient.onChangeVideoOnlyState = (isVideoOnly) => {
      this.setState({ isVideoOnly });
    };

    playerClient.onAddLiveSubtitlesCallback = (subtitles) => {
      this.setState({ subtitles }, () => {
        // kvůli zapnutí titulků při přechodu mezi timeshiftem a live
        if (this.state.isTextTrackVisible || this.context.liveCaptionsOn) {
          void this.onTextTrackSelected(subtitles[0], true);
        }
      });
    };

    if (encoderTimeDiff) {
      playerClient.setEncoderTimeDiff(encoderTimeDiff);
    }

    if (!playerClient.isBrowserSupported()) {
      // TODO: Error handling
      log.error({ message: 'Browser not supported!' });
      return;
    }

    window.addEventListener('online', this.onNetworkStatusChange);

    if (
      !!this.context.liveMode &&
      [LiveMode.liveWithStartAndEndDefined, LiveMode.liveWithStartDefined].includes(
        this.context.liveMode
      )
    ) {
      window.addEventListener(TIMER_EVENT, this.onTimer as EventListener);
    }

    if (this.videoRef.current) {
      playerClient.setVideoRef(this.videoRef.current);
      playerClient.setOnWebkitEndFullscreenListener(this.syncNativeOptionsOnFullscreenLeave);
      analyticsClient.trigger({
        type: 'PlayerHTMLElement',
        data: this.videoRef.current,
      });
      this.videoRef.current.muted = isMuted;
      this.videoRef.current.addEventListener(
        'webkitpresentationmodechanged',
        this.onScreenModeChange
      );
    }

    this.playerStatePollInterval = setInterval(() => {
      const { playerState } = this.state;
      const currentPlayerState = playerClient.getState();

      if (currentPlayerState !== playerState) {
        this.setState({
          playerState: currentPlayerState,
        });
      }
    }, PLAYER_STATE_POLL_INTERVAL);

    this.playerVideoProgressCacheInterval = setInterval(() => {
      const { chromecastState } = this.context;
      const currentPlayerState = playerClient.getState();
      const isPlaying =
        currentPlayerState === PlayerClientState.PLAYING ||
        chromecastState === ChromecastState.PLAYING;
      if (isPlaying && playerVariant === PlayerVariantEnum.VOD) {
        void this.updateVideosInProgressCache();
      }
    }, PLAYER_VIDEO_PROGRESS_CACHE_INTERVAL);

    this.playerBufferingInterval = setInterval(() => {
      const { buffered } = this.state;
      const newBufferedIntervals = playerClient.getBuffered();
      const newBuffered =
        newBufferedIntervals && newBufferedIntervals.length > 0
          ? newBufferedIntervals.end(newBufferedIntervals.length - 1) /
            (playerClient.getDuration() / 100)
          : 0;

      if (newBuffered !== buffered) {
        this.setState({
          buffered: newBuffered,
        });
      }
    }, PLAYER_BUFFERED_INTERVAL);

    this.streamUrlExpiredTimeout = setTimeout(() => {
      this.setRefetchPlaylistFlag(true, 'stream url expired timeout');
    }, this.streamUrlExpiredTimeoutInMs);

    if (playerVariant === PlayerVariantEnum.LIVE) {
      this.timeShiftInterval = setInterval(() => {
        const { setTimeShift, timeShift } = this.context;
        // Time shift nebudeme updatovat, pokud je vypnuty
        // zobrazení timeshiftu se zobrazí, ale uživatel začne s časovou manipulovat
        if (timeShift === null) {
          return;
        }

        // aktualizuje timeshift tak, že spočítá zpoždění za aktuálním streamem
        // a v případě že jsme na timeshiftu, připočte zpoždění samotného streamu za živým
        const newTimeShift = Math.round(
          (playerClient.getLiveEdgeDelay() || 0) + (this.isOnTimeshift() ? timeshiftGapDuration : 0)
        );
        setTimeShift(newTimeShift);
      }, PLAYER_LIVE_TIMESHIFT_INTERVAL);
    }

    // TODO: Pouze priklady, je potreba doplnit zpracovani
    const { supportedDRM, mediaSourceSupported } = this.props.playerSetup;

    log.info({ message: `player version ${PLAYER_VERSION}` });
    log.info({ message: `supportedDRM ${supportedDRM}` });
    log.info({ message: `isMediaSourceSupported ${mediaSourceSupported}` });

    if (this.isMobileIOS) {
      document.addEventListener('visibilitychange', this.handleTabChange);
      this.videoRef.current?.addEventListener('play', this.onNativeFullScreenPlayButton);
    }

    // Nastavi KeyboardControl
    this.keyboardControl = KeyboardControlFactory({
      fullscreenEnter: this.toggleFullscreen,
      toggleMute: this.toggleMuteUnmute,
      seekBack: this.onSeekBackButtonPressed,
      seekForward: this.onSeekForwardButtonPressed,
      playPause: this.togglePlayPause,
      volumeUp: () => this.onVolumeKeyPressed(PLAYER_VOLUME_KEYPRESS_DELTA),
      volumeDown: () => this.onVolumeKeyPressed(-PLAYER_VOLUME_KEYPRESS_DELTA),
      nextIndex: this.onNextIndex,
      prevIndex: this.onPrevIndex,
      audioDescription: this.onAudioDescription,
      seekFraction: this.onSeekFraction,
      subtitles: this.onSubtitles,
      switchDebugMode: this.onSwitchDebugMode,
      keyboardControlsHide: this.keyboardControlsHide,
    });

    if (this.props.setPlayerLoaderRef) {
      this.props.setPlayerLoaderRef({
        ...(this.props.playerLoaderRef ? this.props.playerLoaderRef : {}),
        destroy: this.destroy,
        pause: this.onPauseButtonPressed,
        play: this.onPlayButtonPressed,
        stop: () => {
          playerClient.pause();
          void this.onProgressBarClick(0);
        },
        // lepsi pojmenovani by bylo treba seekBy, ale kvuli legacy kompatibilite nechavame
        seek: (seconds: number) => {
          if (seconds === 10) {
            void this.onSeekForwardButtonPressed();
          } else if (seconds === -10) {
            void this.onSeekBackButtonPressed();
          } else {
            const currentTime = playerClient.getCurrentTime() || 0;
            void this.setCurrentTimeHandler(
              Math.max(0, Math.min(playerClient.getDuration(), currentTime + seconds))
            );
          }
        },
        seekTo: (seconds: number) => {
          void this.setCurrentTimeHandler(
            Math.max(0, Math.min(playerClient.getDuration(), seconds))
          );
        },
        toggleMute: async (value) => {
          const { isMuted } = this.state;
          if (isMuted === value) {
            return;
          }
          await this.toggleMuteUnmute();
        },
        toggleFullscreen: async (value) => {
          const { screenMode } = this.state;
          const isFullscreen = screenMode === ScreenMode.FULLSCREEN;
          if (isFullscreen === value) {
            return;
          }
          await this.toggleFullscreen();
        },
        setVolume: (value: number) => {
          this.onVolumeChange(value);
        },
        getVolume: () => {
          return this.videoRef.current?.volume;
        },
        getIsMuted: () => {
          return this.videoRef.current?.muted;
        },
      });
    }

    this.setState({
      activeIndex: this.getActiveIndex(),
      // duration by se zde nemusela nastavovat, protože player ještě není iniciovaný
      // byl by ale nutný refator testů
      duration: playerClient.getDuration(),
      isLiveStream: this.props.playerSetup.playerVariant === PlayerVariantEnum.LIVE ? true : null,
    });
    // video se vubec nesnazime loadnout, pokud by se melo zaseknout na autoplayi
    // na iOS to zpusobuje problemy - pokud nelze video prehrat ani zamutovane, cekame na user interaction
    if (!this.isFirstLoad || getCanUseAutoPlay(this.props.playerSetup.autoPlayCapability)) {
      void this.loadVideo();
    } else {
      playerClient.setState(PlayerClientState.READY);
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { client } = this.props.playerAnalytics;
    const availableSubtitles = this.props.subtitles || prevState.subtitles;
    const { selectedAudioTrack } = this.state;
    const analyticsContext = client.getContext();
    const { playerVariant, playerClient } = this.props.playerSetup;
    const { timeshiftGapDuration, isAudioOnly } = this.context;

    if (analyticsContext) {
      client.setContext({
        ...analyticsContext,
        audioOnly: !!isAudioOnly,
        signLanguage: false,
        subtitles: availableSubtitles?.length
          ? this.state.selectedSubtitle?.title || 'Vypnuto'
          : undefined,
        audiotrack: selectedAudioTrack ? getAudioLanguageLabel(selectedAudioTrack) : undefined,
        quality: getResolutionLabelForAnalytics(this.state.selectedResolution),
        screenmode: this.state.screenMode,
      });
    }

    if (playerVariant === PlayerVariantEnum.LIVE && !this.timeShiftInterval) {
      this.timeShiftInterval = setInterval(() => {
        const { setTimeShift, timeShift } = this.context;
        // Time shift nebudeme updatovat, pokud je vypnuty
        // zobrazení timeshiftu se zobrazí, ale uživatel začne s časovou manipulovat
        if (timeShift === null) {
          return;
        }

        // aktualizuje timeshift tak, že spočítá zpoždění za aktuálním streamem
        // a v případě že jsme na timeshiftu, připočte zpoždění samotného streamu za živým
        const newTimeShift = Math.round(
          (playerClient.getLiveEdgeDelay() || 0) + (this.isOnTimeshift() ? timeshiftGapDuration : 0)
        );
        setTimeShift(newTimeShift);
      }, PLAYER_LIVE_TIMESHIFT_INTERVAL);
    }

    if (playerVariant !== PlayerVariantEnum.LIVE && this.timeShiftInterval) {
      clearInterval(this.timeShiftInterval);
    }

    // pokud se měnilo nastavení fullscreen, odpálíme event
    if (this.state.screenMode !== prevState.screenMode) {
      client.trigger({ type: 'PlayerSettingsChangeScreenmode' });
    }

    // pokud chceme zapnout titulky na zacatku, udelame tak v momentu, kdy mame
    // textTracky inicializovane a realne dostupne titulky ve state a nastavime pouze jednou
    if (this.requestedSubtitles && this.state.subtitles?.length) {
      void this.onTextTrackSelected(this.requestedSubtitles, true);
      this.requestedSubtitles = null;
    }

    // pokud se do playeru vratila polozka typu adSource nebo adPlaceholder a jsme na konci fronty,
    // tak to znamena, ze jsme uplne na konci (reklamni system nevratil postRoll a nevznikl nam
    // PlayerStreamType.ad)
    const isLastItemNonPlayable =
      [PlayerStreamType.adSource, PlayerStreamType.adPlaceholder].includes(
        this.props.queue.current.type
      ) && this.props.queue.position === this.props.queue.length - 1;

    if (
      isLastItemNonPlayable &&
      this.props.playerSetup.playerClient.getState() !== PlayerClientState.FINISHED
    ) {
      this.onFinished();
      return;
    }

    // pokud je polozka prehratelna a lisi se od prave prehravane nebo forcePlaySamePosition===true
    // loadneme video
    if (
      (!isLastItemNonPlayable &&
        this.props.queue.position !== prevProps.queue.position &&
        JSON.stringify(this.props.queue.current) !== JSON.stringify(prevProps.queue.current)) ||
      this.forcePlaySamePosition
    ) {
      void this.loadVideo(undefined, this.seekToTimeWhenFinished);
      this.seekToTimeWhenFinished = undefined;
      this.forcePlaySamePosition = false;
    }

    if (this.props.playerSetup.playerVariant !== prevProps.playerSetup.playerVariant) {
      this.setState({
        isLiveStream: this.props.playerSetup.playerVariant === PlayerVariantEnum.LIVE ? true : null,
      });
    }

    // Aktualizace aktualniho casu videa podle props
    if (
      prevProps.startTime !== this.props.startTime &&
      typeof this.props.startTime !== 'undefined'
    ) {
      const newTime = Math.min(this.state.duration, this.props.startTime);
      this.props.playerSetup.playerClient.setCurrentTime(newTime);
    }
  }

  handleTabChange = async () => {
    if (document.hidden) {
      const { playerClient } = this.props.playerSetup;
      this.wasPlayingBeforeTabChange = playerClient.getState() === PlayerClientState.PLAYING;
      return;
    }
    const isAd = this.props.queue.current.type === PlayerStreamType.ad;
    if (isAd) {
      await this.onSkipAd();
    } else {
      await this.reloadStream({ play: this.wasPlayingBeforeTabChange });
    }
  };

  getFullLiveStreamLength = () => {
    return this.state.liveTimelineInSeconds;
  };

  onTimer = (ev: CustomEvent<TimerEvent>) => {
    const { liveMode, startTimestamp, endTimestamp, timeshiftGapDuration } = this.context;
    if (
      !!liveMode &&
      [LiveMode.liveWithStartAndEndDefined, LiveMode.liveWithStartDefined].includes(liveMode) &&
      startTimestamp
    ) {
      const { timestamp: now } = ev.detail;
      const liveTimeline = (now - startTimestamp) / 1000;
      this.setState({ liveTimelineInSeconds: liveTimeline });

      if (!this.state.canSeekToStartTimestamp && liveTimeline >= timeshiftGapDuration) {
        this.setState({ canSeekToStartTimestamp: true });
      }

      const { onLiveStreamEnded } = this.props;
      if (
        this.isOnLive() &&
        endTimestamp &&
        this.context.liveMode === LiveMode.liveWithStartAndEndDefined &&
        now >= endTimestamp
      ) {
        onLiveStreamEnded?.(LiveStreamEndReason.streamEndedDuringPlayback);
      }
    }
  };

  destroy = async () => {
    const { playerClient } = this.props.playerSetup;
    this.mounted = false;

    const { playerWrapperRef } = this.props.playerSetup;

    if (playerWrapperRef.current) {
      playerWrapperRef.current.removeEventListener('fullscreenchange', this.onScreenModeChange);
      playerWrapperRef.current.removeEventListener(
        hasWebkitFullscreen(playerWrapperRef.current)
          ? 'webkitfullscreenchange'
          : 'fullscreenchange',
        this.onScreenModeChange
      );
    }

    if (this.videoRef.current) {
      this.videoRef.current.removeEventListener(
        'webkitpresentationmodechanged',
        this.onScreenModeChange
      );
    }

    window.removeEventListener('online', this.onNetworkStatusChange);
    window.removeEventListener(TIMER_EVENT, this.onTimer as EventListener);
    document.removeEventListener('visibilitychange', this.handleTabChange);
    this.videoRef.current?.removeEventListener('play', this.onNativeFullScreenPlayButton);

    // případné chyby se logují přímo v clientovi, proto nečekáme na promise
    void playerClient.dispose();

    if (this.playerStatePollInterval) {
      clearInterval(this.playerStatePollInterval);
    }
    if (this.playerVideoProgressCacheInterval) {
      clearInterval(this.playerVideoProgressCacheInterval);
    }
    if (this.playerBufferingInterval) {
      clearInterval(this.playerBufferingInterval);
    }
    if (this.timeShiftInterval) {
      clearInterval(this.timeShiftInterval);
    }
    if (this.userInactiveTimeout) {
      clearTimeout(this.userInactiveTimeout);
    }
    if (this.streamPausedTimeout) {
      clearTimeout(this.streamPausedTimeout);
    }
    if (this.streamUrlExpiredTimeout) {
      clearTimeout(this.streamUrlExpiredTimeout);
    }
    if (this.checkPlayerStateInterval) {
      clearInterval(this.checkPlayerStateInterval);
    }
    this.onOverlayMouseLeave.cancel();
  };

  componentWillUnmount() {
    this.destroy();
  }

  onNetworkStatusChange = () => {
    const { playerError, setPlayerError } = this.context;

    if (!playerError) {
      return;
    }

    const isOnline = isNetworkOnline();

    // pokud byla v přehrávači chyba network charakteru
    // browser v té době nebyl připojený k internetu
    // a teď je, zrušíme error.

    if (
      playerError.category === PlayerErrorCategory.NETWORK &&
      !playerError.wasNetworkOnline &&
      isOnline
    ) {
      this.checkPlayerStateInterval = setInterval(() => {
        const { playerState } = this.state;
        if (this.checkPlayerStateInterval && playerState === PlayerClientState.PLAYING) {
          setPlayerError(null);
          clearInterval(this.checkPlayerStateInterval);
        }
      }, PLAYER_NETWORK_ERROR_INTERVAL);
    } else if (this.checkPlayerStateInterval) {
      clearInterval(this.checkPlayerStateInterval);
    }
  };

  onNativeFullScreenPlayButton = async () => {
    const { screenMode, playerState } = this.state;
    if (screenMode === ScreenMode.FULLSCREEN && playerState === PlayerClientState.FINISHED) {
      this.setState({ startTime: 0 });
      await this.onReplayButtonClick();
    }
  };

  onResetBeforeStreamChange = () => {
    this.setState({ skipAdInSeconds: undefined, adClicked: false });
  };

  onPlaybackStart = () => {
    this.overlayRef.current?.focus();
    if (this.state.playerMode === PlayerMode.content) {
      const { autoplay } = this.props.playerAnalytics.client.getContext();
      if (this.playEventCount === 0) {
        this.props.playerAnalytics.onPlay({ interaction: !autoplay });
        this.playEventCount += 1;
      }
    }

    if (this.props.queue.items && this.state.skipAdInSeconds === undefined) {
      const currentStreamData = this.props.queue.current;
      const skipOffset =
        currentStreamData.type === PlayerStreamType.ad ? currentStreamData.meta.skipOffset : null;
      this.setState({ skipAdInSeconds: skipOffset !== null ? skipOffset : 0 });
    }

    this.recentPlaybackStart = true;
    this.controlsAnimationDisabled = true;
    this.setHiddenControlsDisabled(false);

    setTimeout(() => {
      this.recentPlaybackStart = false;
    }, RECENT_PLAYBACK_START_BUFFER);

    setTimeout(() => {
      this.controlsAnimationDisabled = false;
    }, CONTROLS_ANIMATION_OFF_TIMEOUT);
  };

  onFinished = () => {
    const {
      playerAnalytics,
      playerSetup: { playerClient },
    } = this.props;
    void this.updateVideosInProgressCache();
    playerAnalytics.onEnd(this.analyticsContextSnapshot);
    playerClient.setState(PlayerClientState.FINISHED);
    this.onResetBeforeStreamChange();
    this.setState((prev) => ({
      playerMode: PlayerMode.content,
      duration: this.lastKnownMainContentDuration || prev.duration,
    }));
    this.streamPausedTimeout = setTimeout(() => {
      this.setRefetchPlaylistFlag(true, 'stream paused timeout');
    }, this.streamPausedTimeoutInMs);
  };

  // analytika po dohrani postrollu potrebuje zpetne info o hlavnim obsahu
  setMainContentAnalyticsSnapshot = () => {
    this.analyticsContextSnapshot = this.props.playerAnalytics.client.getContext();
  };

  // Skoncil playback jednoho
  onPlaybackEnd = () => {
    if (this.props.queue && this.props.queuePosition < this.props.queue.items.length - 1) {
      if (this.props.queue.current.type === PlayerStreamType.main) {
        this.setMainContentAnalyticsSnapshot();
      }
      this.onResetBeforeStreamChange();
      void this.playNextStream(this.props.queuePosition + 1);
    } else {
      this.onFinished();
    }
  };

  playNextStream = async (position?: number) => {
    const onCanPlay = async () => {
      void this.onCanPlay();
      this.videoRef.current?.removeEventListener('canplaythrough', onCanPlay);
    };
    const nextItem = this.props.queue.items[this.props.queuePosition + 1];
    if (nextItem && nextItem.type === PlayerStreamType.adPlaceholder) {
      await this.props.fetchPostrolls();
    }

    // loadneme video a o zbytek se postaraji eventy - nutno zavolat znovu this.onCanPlay,
    // ktera se vola vzdy jen jednou pri prehravani noveho streamu
    this.videoRef.current?.addEventListener('canplaythrough', onCanPlay);
    this.props.setQueuePosition((prev) => (typeof position === 'number' ? position : prev + 1));
  };

  onThrottledTimeChange = throttle(() => {
    this.setState({
      activeIndex: this.getActiveIndex(),
    });
  }, INDEX_LOOKUP_THROTTLE);

  getCachedStreamData = () => this.props.queue.items;

  setRefetchPlaylistFlag = (value: boolean, reason: string) => {
    // this.refetchPlaylistFlag = value;
    this.props.setRefetchFlag(value);
    log.debug({
      message: `RefetchPlaylistFlag has just been set to: ${value}. Reason: ${reason}`,
    });
  };

  reloadStream = async ({ startTime, play = false }: { play?: boolean; startTime?: number }) => {
    const { playerClient, playerVariant } = this.props.playerSetup;
    const isLive = this.isOnLive();
    const isTimeshift = this.isOnTimeshift();
    const now = Date.now();
    const getTimeToSeekToAfterReload = () => {
      if (playerVariant === PlayerVariantEnum.VOD) {
        return startTime ?? playerClient.getCurrentTime();
      }
      if (isTimeshift) {
        const diff = this.pausedTimestamp ? now - this.pausedTimestamp : undefined;
        if (diff && playerClient.isNativePlayer && this.pausedAtVideoCurrentTime) {
          const nativeSeekTime = this.pausedAtVideoCurrentTime - diff / 1000;
          return nativeSeekTime;
        }
        return startTime ?? this.pausedAtVideoCurrentTime;
      }
      return undefined;
    };
    // pokud bylo video v timeshiftu dlouho pauznute,
    // nebudeme se snazit reloadovat stream, ale prejdeme do live
    if (
      isTimeshift &&
      this.pausedTimestamp &&
      this.pausedTimestamp + RETURN_TO_LIVE_AFTER_PAUSE_IN_MS < now
    ) {
      this.pausedAtVideoCurrentTime = undefined;
      this.pausedTimestamp = undefined;
      await this.onProgressBarClick(100);
      return;
    }
    const time = getTimeToSeekToAfterReload();
    let type: 'timeshift' | 'live' | undefined;
    if (playerVariant === PlayerVariantEnum.LIVE) {
      type = isLive ? 'live' : 'timeshift';
    }

    if (play) {
      const onCanPlay = async () => {
        void this.onCanPlay();
        this.videoRef.current?.removeEventListener('canplaythrough', onCanPlay);
      };
      this.videoRef.current?.addEventListener('canplaythrough', onCanPlay);
    }
    const queue = await this.props.getQueue(this.props.queuePosition, true);
    this.resetRefetchTimers();
    this.shouldTryReloadSubtitles = true;
    this.pausedAtVideoCurrentTime = undefined;
    return this.loadVideo(type, time, queue.current);
  };

  onForceAudioOnlySwitch = async () => {
    const { forcedAudioOnly, setForcedAudioOnly } = this.context;
    try {
      this.props.setForcedAudioOnlyFlag(!forcedAudioOnly, this.state.isExternalAudioDescription);
      this.requestedSubtitles = this.state.selectedSubtitle;
      await this.reloadStream({ play: true, startTime: this.getCurrentTime() });
      if (!forcedAudioOnly) {
        this.props.playerAnalytics.client.trigger({ type: 'PlayerAudioOnlyOn' });
      } else {
        this.props.playerAnalytics.client.trigger({ type: 'PlayerAudioOnlyOff' });
      }
      setForcedAudioOnly(!forcedAudioOnly);
      await this.onAudioTrackSelected({
        audioTrack: this.state.selectedAudioTrack,
        bypassAnalytics: true,
      });
    } catch (e) {
      this.props.setForcedAudioOnlyFlag(!forcedAudioOnly, this.state.isExternalAudioDescription);
      log.error({
        message: 'Failed to reload stream after audio only switch',
        error: e,
      });
    }
  };

  selectDefaultAudioTrack = () => {
    const defaultAudioTrack = this.state.audioTracks?.[0] || null;
    void this.onAudioTrackSelected({
      audioTrack: defaultAudioTrack,
      bypassAnalytics: true,
      forceSwitch: true,
    });
  };

  getInternalAudioDescriptionTrack = () => {
    const { audioTracks } = this.state;
    const isInternalAudioDescriptionAvailable = audioTracks?.find(
      (track) => track.role === 'alternate'
    );
    return isInternalAudioDescriptionAvailable;
  };

  onAudioDescription = async (turnOff?: boolean) => {
    const internalAudioDescriptionTrack = this.getInternalAudioDescriptionTrack();
    if (internalAudioDescriptionTrack) {
      if (this.state.selectedAudioTrack?.id === internalAudioDescriptionTrack.id) {
        this.selectDefaultAudioTrack();
        return;
      }
      void this.onAudioTrackSelected({
        audioTrack: internalAudioDescriptionTrack,
      });
      return;
    }
    if (!this.props.queue.current.audioDescription) {
      return;
    }
    const { isExternalAudioDescription } = this.state;
    if (!isExternalAudioDescription && !turnOff) {
      void this.onAudioTrackSelected({
        audioTrack: { id: LANG_CODE_AD, language: LANG_CODE_AD },
      });
    } else {
      this.selectDefaultAudioTrack();
    }
  };

  // potrbujeme expiraci hlidat i pri pohybu po casove ose
  // pripadne znovu poptat playlisty a dostat cerstve stream urls
  setCurrentTimeHandler = async (value: number) => {
    const { isChromecastSession, onChromecastSeek } = this.context;
    if (isChromecastSession) {
      onChromecastSeek(value);
      // pro Chromecast nic dalsiho neresime
      return;
    }
    // seekovani z finished overlaye
    if (this.state.playerState === PlayerClientState.FINISHED) {
      const onCanPlay = async () => {
        void this.onCanPlay();
        this.videoRef.current?.removeEventListener('canplaythrough', onCanPlay);
      };

      this.props.playerSetup.playerClient.setState(PlayerClientState.LOADING);
      this.seekToTimeWhenFinished = value;
      const postrollsCount = this.props.queue.items.filter(
        (item) =>
          [PlayerStreamType.ad, PlayerStreamType.adSource, PlayerStreamType.adPlaceholder].includes(
            item.type
          ) && item.category === PlayerAdType.postRoll
      ).length;
      this.shouldTryReloadSubtitles = true;
      if (postrollsCount === 0) {
        const queue = await this.props.getQueue(this.props.queuePosition, true);
        this.resetRefetchTimers();
        this.videoRef.current?.addEventListener('canplaythrough', onCanPlay);
        await this.loadVideo(undefined, value, queue.current);
      } else {
        this.setRefetchPlaylistFlag(true, 'seeking from finished overlay');
        this.videoRef.current?.addEventListener('canplaythrough', onCanPlay);
        this.props.setQueuePosition((prev) => prev - postrollsCount);
      }
      return;
    }
    // pri normalnim seekovani hlidame, zda neexpirovala stream url, jinal nejdriv poptame playlist
    if (this.props.getRefetchFlag()) {
      await this.reloadStream({ startTime: value, play: !this.videoRef.current?.paused });
      return;
    }
    this.props.playerSetup.playerClient.setCurrentTime(value);
    if (this.state.playerState === PlayerClientState.PAUSED) {
      if (this.streamPausedTimeout) {
        clearTimeout(this.streamPausedTimeout);
      }
      this.pausedAtVideoCurrentTime = value;
      this.pausedTimestamp = Date.now();
      this.streamPausedTimeout = setTimeout(() => {
        this.setRefetchPlaylistFlag(true, 'stream paused timeout');
      }, this.streamPausedTimeoutInMs);
    }
  };

  reloadSubtitlesOnReplay = async () => {
    const subtitles = this.state.selectedSubtitle;
    this.resetRefetchTimers();
    if (subtitles) {
      await this.onTextTrackSelected(null);
      const onLoadedData = async () => {
        if (this.props.queue.current.type === PlayerStreamType.main) {
          await this.onTextTrackSelected(subtitles);
          this.videoRef.current?.removeEventListener('loadeddata', onLoadedData);
        }
      };
      this.videoRef.current?.addEventListener('loadeddata', onLoadedData);
    }
    this.shouldTryReloadSubtitles = false;
  };

  // reset po loadu novych stream urls
  resetRefetchTimers = () => {
    this.setRefetchPlaylistFlag(false, 'got new stream urls');
    if (this.streamPausedTimeout) {
      clearTimeout(this.streamPausedTimeout);
    }
    if (this.streamUrlExpiredTimeout) {
      clearTimeout(this.streamUrlExpiredTimeout);
    }
    // mame cerstve url, znovu hlidame expiraci
    this.streamUrlExpiredTimeout = setTimeout(() => {
      this.setRefetchPlaylistFlag(true, 'stream url expired timeout');
    }, this.streamUrlExpiredTimeoutInMs);
  };

  // by default načítá první video z props, ale je možné definovat vlastní hodnotu
  async loadVideo(type?: 'live' | 'timeshift', startTime?: number, playerStream?: PlayerStream) {
    this.isFirstLoad = false;
    const { subtitles } = this.props;

    const { setPlayerError, playlistOptions } = this.context;
    const { playerClient } = this.props.playerSetup;
    playerClient.setState(PlayerClientState.LOADING);
    let videoStartTime = typeof startTime !== 'undefined' ? startTime : this.state.startTime;

    try {
      const { current: queuePlayerStream } = this.props.queue;
      const currentStream = playerStream || queuePlayerStream;

      const currentStreamData: Queue['current'] = {
        ...currentStream,
        url:
          type === 'timeshift' && currentStream.timeshift
            ? currentStream.timeshift
            : currentStream.url,
      };
      if (this.props.getRefetchFlag()) {
        this.resetRefetchTimers();
      }
      if (this.shouldTryReloadSubtitles) {
        await this.reloadSubtitlesOnReplay();
      }

      // unload volame pouze pokud player jiz mel nejaky stream nacteny
      if (this.isStreamLoaded) {
        await playerClient.unload();
      }

      let widevineLicenseServerUrl;
      let fairplayLicenseServerUrl;
      let fairplayLicenseCertificateUrl;
      if (currentStreamData.drm) {
        const { drm } = currentStreamData;
        if ('widevineLicenseServerUrl' in drm) {
          widevineLicenseServerUrl = drm.widevineLicenseServerUrl;
        }
        if ('fairplayLicenseServerUrl' in drm) {
          fairplayLicenseServerUrl = drm.fairplayLicenseServerUrl;
          fairplayLicenseCertificateUrl = drm.fairplayLicenseCertificateUrl;
        }
      }
      const isDrmOnly = getIsDrmOnlyFromUrl(currentStreamData.url);
      await playerClient.init({
        ...(isDrmOnly
          ? {
              drm: {
                widevineLicenseServerUrl,
                fairplayLicenseServerUrl,
                fairplayLicenseCertificateUrl,
              },
            }
          : {}),
        isLive: this.props.playerSetup.playerVariant === PlayerVariantEnum.LIVE,
        subtitles:
          currentStreamData.type !== PlayerStreamType.ad && subtitles ? [...subtitles] : null,
        maxAutoQuality: playlistOptions.maxAutoQuality,
        maxQuality: playlistOptions.maxQuality,
      });
      // nastavime state titulku podle props, pripadne rovnou pridame textTracky (ios)
      if (subtitles && currentStreamData.type !== PlayerStreamType.ad) {
        this.setState({ subtitles: playerClient.initSubtitles(subtitles) });
      }
      this.setState({
        playerMode:
          currentStreamData.type === PlayerStreamType.ad ? PlayerMode.ads : PlayerMode.content,
        hiddenControlsDisabled: false,
      });

      if (currentStreamData.type === PlayerStreamType.ad) {
        videoStartTime = 0;
      }

      const options: VideoLoadOptions = {
        dontResetPlaytime: this.dontResetPlaytime,
        hasExternalSubtitles: !!this.props.subtitles?.length,
        startTime: videoStartTime,
        selectStream: undefined,
        resolution: this.state.selectedResolution,
        onPlayNextStream: this.playNextStream,
      };

      if (this.props.playerSetup.playerVariant === PlayerVariantEnum.LIVE) {
        const { audioTracks } = this.state;
        if (this.state.selectedAudioTrack && audioTracks && audioTracks.length > 1) {
          const onLoadedAudioTracks = () => {
            void this.onAudioTrackSelected({
              audioTrack: this.state.selectedAudioTrack,
              forceSwitch: true,
              bypassAnalytics: true,
            });
            this.videoRef.current?.removeEventListener('loadeddata', onLoadedAudioTracks);
          };
          this.videoRef.current?.addEventListener('loadeddata', onLoadedAudioTracks);
        }
      }

      await playerClient.load(currentStreamData, options);
      this.isStreamLoaded = true;
      /*  Pokud prehravame live, tak nechceme resetovat analytiku progressu videa pri prepinani
          mezi timeshiftem/live.
          Nastavime az po prvotnim loadu ziveho vysilani - pred live muze byt preroll,
          ktery potrebujeme vyresetovat */
      if (this.props.playerSetup.playerVariant === PlayerVariantEnum.LIVE) {
        this.dontResetPlaytime = true;
      }
      // kvuli prehravani vice streamu aktualizujeme i state duration
      const duration = playerClient.getDuration();
      if (currentStreamData.type === PlayerStreamType.main) {
        this.lastKnownMainContentDuration = duration;
      }
      this.setState({
        duration,
      });
      if (currentStreamData.category === 'index') {
        this.context.setStartOffset(currentStreamData.startOffset || 0);
      }
    } catch (exception: unknown) {
      if (this.mounted) {
        setPlayerError(exception);
      }
    }
  }

  hideControls = () => {
    if (this.state.controlsVisible) {
      this.setState({ controlsVisible: false });
    }
  };

  showControls = () => {
    if (this.userInactiveTimeout) {
      clearTimeout(this.userInactiveTimeout);
    }
    if (!this.state.controlsVisible || this.state.hiddenControlsDisabled) {
      this.setState({
        hiddenControlsDisabled: false,
        controlsVisible: true,
      });
    }

    this.userInactiveTimeout = setTimeout(this.hideControls, PLAYER_USER_INACTIVE_TIMEOUT);
  };

  setHiddenControlsDisabled = (value: boolean) => {
    this.setState({ hiddenControlsDisabled: value });
  };

  getProgressPercentage(): number {
    const { playerClient } = this.props.playerSetup;

    return Math.round((playerClient.getCurrentTime() / playerClient.getDuration()) * 100);
  }

  async play() {
    const { playerClient, key, playersControllerContext } = this.props.playerSetup;

    if (playersControllerContext) {
      playersControllerContext.stopOtherVideos(key);
    }

    if (this.isFirstLoad) {
      void this.loadVideo();
      return;
    }

    try {
      await playerClient.play();
      this.context.setIndexListVisible(false);

      return await Promise.resolve();
    } catch (error) {
      // nechceme posílat do sentry, proto na levelu info
      log.info({ error, message: 'Video play was blocked' });
      playerClient.setState(PlayerClientState.READY);
      this.setState({ canUseAutoplay: false });
    }
    return Promise.resolve();
  }

  onCanPlay = async () => {
    const { autofocus } = this.props;
    const { duration } = this.state;
    const { playerClient } = this.props.playerSetup;
    const { forcedAudioOnly } = this.context;

    const audioTracks = forcedAudioOnly ? this.state.audioTracks : playerClient.getAudioTracks();

    if (audioTracks && audioTracks.length) {
      this.setState({
        audioTracks,
      });
    }

    if (this.state.playerState !== PlayerClientState.READY) {
      this.initPlayerSettings();
    }
    this.setState({ isPlayerSettingsInitiated: true });

    if (!duration) {
      this.setState({
        duration: playerClient.getDuration(),
      });
    }

    // Pokud bysme nemeli pouzivat autoplay, tak se o to ani nebudeme pokouset
    // plati pouze pro prvni stream, pri dalsich se s autoplayem nezabyvame
    if (!this.shouldUseAutoplay() && this.props.queuePosition === 0) {
      const { playerError } = this.context;
      if (!playerError) {
        playerClient.setState(PlayerClientState.READY);
      }
      return Promise.resolve();
    }

    try {
      await this.play();
    } catch {
      log.debug({ message: 'Disabled autoplay' });
    }

    if (autofocus && this.overlayRef.current) {
      this.overlayRef.current.focus();
    }
    return Promise.resolve();
  };

  // posun myší v overlay prodlouží čas zobrazení controls
  onOverlayMove = () => {
    this.showControls();
  };

  onAdButtonClick = async () => {
    this.onPauseButtonPressed();
    const { playerClient } = this.props.playerSetup;
    const stream = playerClient.getCurrentStreamData();
    if (stream?.type === PlayerStreamType.ad && stream.meta.clickUrl) {
      playerClient.onAdClickThrough();
      this.setState({ adClicked: true });
      window.open(stream.meta.clickUrl, '_blank')?.focus();
    }
  };

  onOverlayTap = () => {
    const { playerClient } = this.props.playerSetup;
    // ve stavu on ready tap spustí video
    if (playerClient.getState() === PlayerClientState.READY) {
      void this.onOverlayClick();
      return;
    }

    // pokud nejdou viditelné controls, zobrazí je
    if (!this.areControlsVisible()) {
      this.showControls();
      return;
    }

    // v opačném případě se chová jako klasický click
    void this.onOverlayClick();
  };

  onOverlayClick = async () => {
    const { adClicked } = this.state;
    const { playerClient } = this.props.playerSetup;
    const playerState = playerClient.getState();
    // pokud jeste nebyla reklama rozklikla, klik na overlay ji otevre
    // dale uz nabizime pouze tlacitko zjistit vice
    if (
      this.props.queue.current.type === PlayerStreamType.ad &&
      playerState === PlayerClientState.PLAYING &&
      !adClicked
    ) {
      await this.onAdButtonClick();
      return;
    }
    const { hbbtvStreamingActiveDevice } = this.context;
    this.showControls();
    if (!hbbtvStreamingActiveDevice) {
      await this.togglePlayPause();
    }
  };

  onOverlayDoubleClick = (
    _e: MouseEvent<HTMLDivElement>,
    overlayHalf: OverlayHalf,
    isMobileDevice: boolean
  ) => {
    if (!isMobileDevice) {
      return this.toggleFullscreen();
    }
    if (overlayHalf === 'left') {
      return this.onSeekBackButtonPressed();
    }
    return this.onSeekForwardButtonPressed();
  };

  // když uživatel s myší odejde, chceme skrýt controls dřív
  onOverlayMouseLeave = debounce(() => {
    this.setState({
      controlsVisible: false,
    });
  }, PLAYER_MOUSE_LEAVE_DEBOUNCE);

  // když se vrátí, zrušíme skrytí
  onOverylayMouseEnter = () => {
    this.onOverlayMouseLeave.cancel();
  };

  onError = (error: PlayerError) => {
    const { setPlayerError } = this.context;
    setPlayerError(error);
  };

  onBuffering = (e: BufferingEvent) => {
    if (e.buffering && this.state.playerMode === PlayerMode.content) {
      this.props.playerAnalytics.onBuffering();
    }
  };

  onAdaptiveResolutionChange = (resolution: number) => {
    this.setState(
      {
        adaptiveResolution: resolution,
      },
      () => {
        this.props.playerAnalytics.onAdaptiveResolutionChange();
      }
    );
  };

  resetPlayer = async () => {
    // pozor - je nutne vypnout pro replay zpet i audioDescription,
    // aby se pri replayi zase zapnul s tim, ze se nejdriv nactou i ostatni audioTracky
    await this.props.resetAds();
    return new Promise((resolve) => {
      this.setState(
        {
          startTime: undefined,
          isExternalAudioDescription: false,
          adClicked: false,
        },
        () => {
          resolve(true);
        }
      );
    });
  };

  initPlayerSettings(playerSettings?: PlayerSettings) {
    if (!this.videoRef.current) {
      return;
    }

    const { volume, resolution, muted, subtitlesActive, subtitles, audioTrack, timeDisplay } =
      playerSettings || this.getDefaultPlayerSettings();

    const { playerClient } = this.props.playerSetup;
    const audioTracks = playerClient.getAudioTracks();

    const setAudioTrack = () => {
      const includeSetTrack = audioTracks?.find((track) => track.id === audioTrack?.id);
      const defaultTrack = audioTracks?.[0];
      if (includeSetTrack) {
        return audioTrack;
      }
      if (defaultTrack) {
        return defaultTrack;
      }
      return null;
    };

    const initialVolume = volume === 0 ? DEFAULT_PLAYER_SETTINGS.volume : volume;
    const volumeToSet = isTouch() ? 1 : initialVolume;
    // volume a muted nastavujeme napřímo
    // při použití onVolumeChange se nám mění  i mute
    // nastavujeme pouze jednou: chceme reflektovat zmenu hlasitosti treba v reklamach
    if (!this.persitentSettingsUpdated.volume) {
      this.videoRef.current.volume = volumeToSet;
      this.setState({ volume: volumeToSet });
      this.updatePlayerSettingsCache({
        volume: volumeToSet,
      });
      this.persitentSettingsUpdated.volume = true;
    }

    if (
      this.props.playerSetup.autoPlayCapability !== AutoplayCapability.MUTED &&
      !this.persitentSettingsUpdated.muted
    ) {
      this.videoRef.current.muted = muted;
      this.setState({ isMuted: muted, wasUnmuted: !muted });
      this.updatePlayerSettingsCache({
        muted,
      });
      this.persitentSettingsUpdated.muted = true;
    }

    this.setState({ timeDisplay: timeDisplay });
    this.updatePlayerSettingsCache({ timeDisplay: timeDisplay });

    if (!this.isMainContent()) {
      return;
    }

    const { playlistOptions } = this.context;
    const { maxAutoQuality, maxQuality } = playlistOptions;

    this.onResolutionSelected(getInitialResolution(resolution, maxQuality, maxAutoQuality));
    void this.onAudioTrackSelected({
      audioTrack: this.props.playerSetup.forceAudioDescription
        ? { id: LANG_CODE_AD, language: LANG_CODE_AD }
        : setAudioTrack(),
      bypassAnalytics: true,
    });

    if (subtitlesActive && subtitles && !this.requestedSubtitles && !this.state.selectedSubtitle) {
      this.requestedSubtitles = subtitles;
    }
  }

  getActiveIndex(): VideoIndex | null {
    const { indexes } = this.context;
    const currentTime = this.getCurrentTime();
    if (!indexes) {
      return null;
    }

    const activeIndexes = indexes.filter(
      (index) => index.startTime <= currentTime && index.stopTime >= currentTime
    );
    if (!(activeIndexes && activeIndexes.length)) {
      return null;
    }

    return activeIndexes.pop() || null;
  }

  toggleMuteUnmute = async (hideMuteButton?: boolean) => {
    const { onChromecastMute, isChromecastSession } = this.context;

    if (isChromecastSession) {
      onChromecastMute();
      return;
    }

    this.props.playerAnalytics.client.trigger({ type: 'PlayerSettingsChangeMute' });
    if (this.videoRef.current) {
      const { isMuted } = this.state;
      this.videoRef.current.muted = !isMuted;
      if (isMuted) {
        this.setState({
          isMuted: false,
          wasUnmuted: hideMuteButton !== undefined ? hideMuteButton : true,
        });
        this.updatePlayerSettingsCache({
          muted: false,
        });
      } else {
        this.setState({ isMuted: true });
        this.updatePlayerSettingsCache({
          muted: true,
        });
      }
    }
  };

  togglePlayPause = async () => {
    const { playerClient } = this.props.playerSetup;
    const state = playerClient.getState();

    if (state === PlayerClientState.PLAYING) {
      this.onPauseButtonPressed();
    } else if (
      // v případě posunování obsahu se přehrávač někdy dostane do stavu seeking
      [PlayerClientState.PAUSED, PlayerClientState.READY].includes(state)
    ) {
      await this.onPlayButtonPressed();
    }
  };

  keyboardControlsHide = () => {
    const { keyboardControlsHideState, setKeyboardControlsHideState } = this.context;
    const { playerClient } = this.props.playerSetup;
    const state = playerClient.getState();
    if (state === PlayerClientState.PAUSED) {
      // schovame debug okenko, aby jim neprekazelo pri porizovani screenshotu
      if (this.state.debugMode && !keyboardControlsHideState) {
        this.setState({ debugMode: false });
      }
      setKeyboardControlsHideState(!keyboardControlsHideState);
    }
  };

  areControlsVisible = () => {
    const { controlsHovered, controlsVisible } = this.state;
    const { preventHideControls, indexListVisible, keyboardControlsHideState } = this.context;
    const { playerClient } = this.props.playerSetup;

    const playerState = playerClient.getState();

    // v ready a loading controls nikdy nezobrazujeme, nesedí s layoutem
    if ([PlayerClientState.READY, PlayerClientState.LOADING].includes(playerState)) {
      return false;
    }

    if (this.recentPlaybackStart) {
      return true;
    }

    // dle UX - pokud jsou viditelné indexy, schovat controls
    if (indexListVisible) {
      return false;
    }

    // pokud uživatel použije klavesovou skratku, schovat controls
    if (keyboardControlsHideState) {
      return false;
    }

    // pro audiopřehrávač zobrazujeme controls vždy
    if (this.context.isAudioOnly) {
      return true;
    }

    // ve stavu pause a finished se controls ukazují vždy!
    if (
      [PlayerClientState.PAUSED, PlayerClientState.FINISHED].includes(playerState) &&
      !this.props.hideControlsWhenPaused
    ) {
      return true;
    }

    // pokud má uživatel kurzor nad ovládacími prvky, neskrýváme controls
    if (controlsHovered) {
      return true;
    }

    // uživatelská interakce s přehrávačem oddaluje skrytí prvků
    if (controlsVisible) {
      return true;
    }

    // některé prvky zabraňují skrytí controls
    if (preventHideControls) {
      return true;
    }

    return false;
  };

  onScreenModeChange = () => {
    if (!this.videoRef.current) {
      return;
    }

    this.setState({
      screenMode: getScreenMode(this.videoRef.current),
    });
  };

  enterFullscreen = async () => {
    const { playerWrapperRef } = this.props.playerSetup;

    if (!playerWrapperRef.current) {
      return Promise.reject();
    }

    return enterFullscreen(playerWrapperRef.current);
  };

  syncNativeOptionsOnFullscreenLeave = () => {
    if (!usesNativeFullscreen() || !this.videoRef.current) {
      return;
    }

    const { muted } = this.videoRef.current;
    if (this.state.isMuted !== muted) {
      this.setState({ isMuted: muted });
      this.updatePlayerSettingsCache({
        muted,
      });
    }

    const textTracks = Array.from(this.videoRef.current.textTracks || []);
    const currentlySelectedTextTrack = textTracks.find((track) => track.mode === 'showing');
    if (currentlySelectedTextTrack) {
      currentlySelectedTextTrack.mode = 'hidden';
    }
    if (!currentlySelectedTextTrack && this.state.selectedSubtitle) {
      void this.onTextTrackSelected(null, true);
    } else if (
      currentlySelectedTextTrack &&
      currentlySelectedTextTrack.id !== this.state.selectedSubtitle?.code
    ) {
      const subtitle = this.state.subtitles?.find(
        (subtitle) => subtitle.code === currentlySelectedTextTrack.id
      );
      if (subtitle) {
        void this.onTextTrackSelected(subtitle, true);
        this.setState({ isTextTrackVisible: true });
      }
    }

    const audioTracks = Array.from(this.videoRef.current.audioTracks || []);
    const currentlySelectedAudioTrack = audioTracks.find((track) => track.enabled);

    if (currentlySelectedAudioTrack?.language !== this.state.selectedAudioTrack?.language) {
      const audioTrack = this.state.audioTracks?.find(
        (track) => track.language === currentlySelectedAudioTrack?.language
      );
      if (audioTrack) {
        void this.onAudioTrackSelected({ audioTrack, bypassAnalytics: true });
      }
    }
  };

  syncNativeOptionsOnFullscreenEnter = () => {
    if (!usesNativeFullscreen()) {
      return;
    }
    const textTracks = Array.from(this.videoRef.current?.textTracks || []);
    const { selectedSubtitle } = this.state;
    // pokusime se pokracovat v zobrazovani vybranych titulku i v nativnim fullscreenu
    textTracks.forEach((_, index) => {
      textTracks[index].mode = 'disabled';
    });
    if (selectedSubtitle?.textTrack) {
      selectedSubtitle.textTrack.mode = 'showing';
      // schovame custom titulky
      this.setState({ isTextTrackVisible: false });
    }
  };

  toggleFullscreen = async () => {
    const { playerWrapperRef } = this.props.playerSetup;
    if (playerWrapperRef.current) {
      // V idealnim pripade bychom listener nastavovali pri mountu
      // - tak to take puvodne bylo, ale kvuli tomu, ze `playerWrapperRef` se nastavuje az pozdeji,
      // tak by to nefungovalo
      playerWrapperRef.current.addEventListener(
        hasWebkitFullscreen(playerWrapperRef.current)
          ? 'webkitfullscreenchange'
          : 'fullscreenchange',
        this.onScreenModeChange
      );
    }

    this.overlayRef.current?.focus();

    if (this.state.screenMode === ScreenMode.FULLSCREEN) {
      return leaveFullscreen();
    }

    this.syncNativeOptionsOnFullscreenEnter();
    return this.enterFullscreen();
  };

  getPlayerSettings = (): PlayerSettings => {
    const {
      volume,
      isMuted,
      selectedResolution,
      isTextTrackVisible,
      selectedSubtitle,
      selectedAudioTrack,
      timeDisplay,
    } = this.state;
    const { captionColorVariant, captionFontSize, liveCaptionsOn } = this.context;

    return {
      audioTrack: selectedAudioTrack,
      muted: isMuted,
      resolution: selectedResolution,
      subtitles: selectedSubtitle,
      subtitlesActive: isTextTrackVisible,
      volume,
      additionalConfig: {
        captionColorVariant,
        captionFontSize,
        liveCaptionsOn,
      },
      timeDisplay,
    };
  };

  // pri zmene klavesami priskrtime odesilani eventu, aby jich nechodilo zbytecne moc
  onThrottledChangeVolumeEvent = throttle(
    () => {
      this.props.playerAnalytics.client.trigger({ type: 'PlayerSettingsChangeVolume' });
    },
    VOLUME_CHANGE_EVENT_THROTTLE,
    { trailing: false }
  );

  onVolumeKeyPressed = (delta: number) => {
    if (this.videoRef.current) {
      const { isChromecastSession, getChromecastVolume } = this.context;
      const currentVolume = isChromecastSession
        ? getChromecastVolume()
        : this.videoRef.current.volume;
      const volume = clamp(currentVolume + delta, 0, 1);
      this.onThrottledChangeVolumeEvent();
      this.onVolumeChange(volume);
    }
  };

  onSubtitles = () => {
    const { isChromecastSession, selectedChromecastSubtitles } = this.context;
    const { selectedSubtitle: selectedPlayerSubtitles, subtitles } = this.state;
    const { subtitles: defaultSubtitles } = this.getDefaultPlayerSettings();
    if (!subtitles) {
      return;
    }
    const activeSubtitles = isChromecastSession
      ? selectedChromecastSubtitles
      : selectedPlayerSubtitles;
    // pokud jsou titulky aktivni, vypneme
    if (activeSubtitles) {
      void this.onTextTrackSelected(null);
      return;
    }
    // zvolime ulozene titulky
    void this.onTextTrackSelected(defaultSubtitles || subtitles[0]);
  };

  onSeekFraction = async (fraction: number) => {
    const { setLiveActivated } = this.context;
    const { playerVariant } = this.props.playerSetup;
    const { duration } = this.state;

    if (this.props.queue.current.type !== PlayerStreamType.main) {
      return;
    }

    if (playerVariant === PlayerVariantEnum.LIVE) {
      if (fraction === 0) {
        const videoTimeShiftStart = this.getVideoTimeShiftStart();
        await this.shiftLivePlayer(videoTimeShiftStart || 0);
      }
      const fullLiveStreamLength = this.getFullLiveStreamLength();

      await this.shiftLivePlayer(fullLiveStreamLength - fullLiveStreamLength * fraction * 0.1);
      setLiveActivated(false);
      return;
    }
    if (fraction === 0) {
      await this.setCurrentTimeHandler(0);
    } else {
      await this.setCurrentTimeHandler(duration * fraction * 0.1);
    }
  };

  onNextIndex = async () => {
    const { indexes } = this.context;
    const { setSeekedRecently } = this.props.playerAnalytics;
    const index = this.getActiveIndex();
    const currentTime = this.getCurrentTime();
    setSeekedRecently();
    if (indexes && index) {
      const indexPosition = indexes.findIndex((item) => item.indexId === index.indexId);
      if (indexes[indexPosition + 1]) {
        const indexStartTime = indexes[indexPosition + 1].startTime;
        await this.setCurrentTimeHandler(indexStartTime);
        this.props.playerAnalytics.onIndexStartBar();
      }
    } else if (indexes && !index) {
      const nextIndex = indexes.find((item) => item.startTime > currentTime);
      if (nextIndex) {
        const indexStartTime = nextIndex.startTime;
        await this.setCurrentTimeHandler(indexStartTime);
        this.props.playerAnalytics.onIndexStartBar();
      }
    }
  };

  onPrevIndex = async () => {
    const { indexes } = this.context;
    const { setSeekedRecently } = this.props.playerAnalytics;
    const index = this.getActiveIndex();
    const currentTime = this.getCurrentTime();
    setSeekedRecently();
    if (indexes && index) {
      const indexPosition = indexes.findIndex((item) => item.indexId === index.indexId);
      if (
        index.startTime + PLAYER_PREV_INDEX_BUFFER < currentTime &&
        index.stopTime > currentTime
      ) {
        const indexStartTime = index.startTime;
        await this.setCurrentTimeHandler(indexStartTime);
        this.props.playerAnalytics.onIndexStartBar();
      } else if (indexes[indexPosition - 1]) {
        const indexStartTime = indexes[indexPosition - 1].startTime;
        await this.setCurrentTimeHandler(indexStartTime);
        this.props.playerAnalytics.onIndexStartBar();
      }
    } else if (indexes && !index) {
      const prevIndex = indexes.find((item) => item.stopTime < currentTime);
      if (prevIndex) {
        const indexStartTime = prevIndex.startTime;
        await this.setCurrentTimeHandler(indexStartTime);
        this.props.playerAnalytics.onIndexStartBar();
      }
    }
  };

  onKeyPressed = (event: KeyboardEvent<HTMLDivElement>) => {
    const { hbbtvStreamingActiveDevice } = this.context;
    if (this.keyboardControl) {
      this.keyboardControl(event, hbbtvStreamingActiveDevice, this.state.controlsVisible);
    }
    // abychom vzdy meli soucasny state, zmenime controlsVisible po key evente
    this.showControls();
  };

  onPlayButtonPressed = async () => {
    const {
      setOverlayIcon,
      timeShift,
      setKeyboardControlsHideState,
      onChromecastPlayPause,
      isChromecastSession,
    } = this.context;

    if (isChromecastSession) {
      onChromecastPlayPause();
      return;
    }

    const { playerVariant } = this.props.playerSetup;
    if (playerVariant === PlayerVariantEnum.LIVE) {
      // Pokud měl uživatel player pausnutý tak dlouho, že má dojít ke změně z live na timeshift
      // vyvoláme to přenastavením pozice videa
      await this.shiftLivePlayer(timeShift || 0);
    }
    if (this.props.getRefetchFlag()) {
      await this.reloadStream({ play: true });
    } else {
      this.pausedAtVideoCurrentTime = undefined;
      await this.play();
      if (this.streamPausedTimeout) {
        clearTimeout(this.streamPausedTimeout);
      }
    }
    if (!this.mounted) {
      return;
    }
    setOverlayIcon(OverlayIconType.PLAY);
    setKeyboardControlsHideState(false);
  };

  onHbbtvDeviceSelect = () => {
    const { playerClient } = this.props.playerSetup;
    playerClient.pause();
  };

  onPauseButtonPressed = () => {
    const {
      setTimeShift,
      timeShift,
      setLiveActivated,
      setOverlayIcon,
      setKeyboardControlsHideState,
      onChromecastPlayPause,
      isChromecastSession,
    } = this.context;

    if (isChromecastSession) {
      onChromecastPlayPause();
      return;
    }

    const { playerVariant, playerClient } = this.props.playerSetup;
    playerClient.pause();

    playerClient.onPauseClicked();

    setOverlayIcon(OverlayIconType.PAUSE);
    setKeyboardControlsHideState(false);

    if (playerVariant === PlayerVariantEnum.LIVE) {
      // Pri zapauzovani live videa zobrazime timeshift
      setTimeShift(timeShift || 0);
      setLiveActivated(false);
    }
    this.pausedAtVideoCurrentTime = playerClient.getCurrentTime();
    this.pausedTimestamp = Date.now();
    this.streamPausedTimeout = setTimeout(() => {
      this.setRefetchPlaylistFlag(true, 'stream paused timeout');
    }, this.streamPausedTimeoutInMs);
  };

  onSoundOffButtonPressed = () => {
    const { onChromecastMute, isChromecastSession } = this.context;

    if (isChromecastSession) {
      onChromecastMute();
      return;
    }
    if (this.videoRef.current) {
      this.videoRef.current.muted = false;
      this.props.playerAnalytics.client.trigger({ type: 'PlayerSettingsChangeMute' });

      const { isMuted } = this.state;
      if (isMuted) {
        this.setState({ isMuted: false, wasUnmuted: true });
        this.updatePlayerSettingsCache({
          muted: false,
        });
      }
    }
  };

  onSoundOnButtonPressed = () => {
    const { onChromecastMute, isChromecastSession } = this.context;

    if (isChromecastSession) {
      onChromecastMute();
      return;
    }
    if (this.videoRef.current) {
      this.videoRef.current.muted = true;
      this.props.playerAnalytics.client.trigger({ type: 'PlayerSettingsChangeMute' });

      const { isMuted } = this.state;
      if (!isMuted) {
        this.setState({ isMuted: true, wasUnmuted: true });

        this.updatePlayerSettingsCache({
          muted: true,
        });
      }
    }
  };

  onControlsHover = () => {
    this.setState({ controlsHovered: true });
  };

  onControlsHoverOut = () => {
    this.setState({ controlsHovered: false });
  };

  getVideoTimeShiftStart = (): number | null => {
    const { videoStartsAt, timeshiftGapDuration } = this.context;

    if (!videoStartsAt) {
      return null;
    }

    const now =
      Math.round(new Date().getTime() / 1000) + (this.isOnLive() ? timeshiftGapDuration : 0);

    return now - Math.round(Math.max(videoStartsAt.getTime(), 0) / 1000);
  };

  onTimeShiftStartButtonPressed = async () => {
    const { startTimestamp } = this.context;
    if (
      !!this.context.liveMode &&
      [LiveMode.liveWithStartAndEndDefined, LiveMode.liveWithStartDefined].includes(
        this.context.liveMode
      ) &&
      startTimestamp
    ) {
      if (!this.isOnTimeshift()) {
        await this.shiftLivePlayer(startTimestamp);
      }
      await this.onProgressBarClick(0);
      this.props.playerAnalytics.onTimeshiftStartButtonPressed();
      return;
    }
    const { setSeekedRecently } = this.props.playerAnalytics;
    const videoTimeShiftStart = this.getVideoTimeShiftStart();
    setSeekedRecently();
    const fullLiveStreamLength = this.getFullLiveStreamLength();
    if (videoTimeShiftStart && videoTimeShiftStart <= fullLiveStreamLength) {
      await this.shiftLivePlayer(videoTimeShiftStart);
      this.props.playerAnalytics.onTimeshiftStartButtonPressed();
    }
  };

  onVolumeChange = (volume: number): void => {
    if (!this.videoRef.current) {
      return;
    }
    const { isChromecastSession, onChromecastVolumeChange } = this.context;

    if (isChromecastSession) {
      onChromecastVolumeChange(volume);
      return;
    }

    const isMuted = volume === 0;

    this.videoRef.current.volume = volume;
    this.videoRef.current.muted = isMuted;

    if (!isMuted && !this.state.wasUnmuted) {
      this.setState({ wasUnmuted: true });
    }

    this.setState({
      volume,
      isMuted,
    });

    this.updatePlayerSettingsCache({
      volume,
      muted: isMuted,
    });

    // pokud měníme hlasitost, ukážeme to uživateli
    this.showControls();
  };

  getCurrentTime = () => {
    const { playerClient } = this.props.playerSetup;
    const { isChromecastSession, getChromecastCurrentTime } = this.context;
    return isChromecastSession ? getChromecastCurrentTime() : playerClient.getCurrentTime();
  };

  onProgressBarClick = async (progress: number) => {
    const { timeShift, setLiveActivated, isChromecastSession, onChromecastSeek } = this.context;

    const { setSeekedRecently } = this.props.playerAnalytics;
    const { playerVariant, playerClient } = this.props.playerSetup;
    const { duration } = this.state;
    const { timeshiftGapDuration, liveStreamDuration } = this.context;

    if (isChromecastSession) {
      const newTime = (duration * progress) / 100;
      onChromecastSeek(newTime);
      return;
    }

    const currentTime = progress === 100 && duration ? duration : playerClient.getCurrentTime();
    setSeekedRecently();
    // prodloužíme zobrazení controls
    this.showControls();

    if (playerVariant === PlayerVariantEnum.VOD) {
      const newTime = (duration * progress) / 100;
      await this.setCurrentTimeHandler(newTime);

      // analytika VOD
      if (currentTime < newTime) {
        this.props.playerAnalytics.onSeekForward();
      } else if (currentTime > newTime) {
        this.props.playerAnalytics.onSeekBack();
      }
      return;
    }
    const fullLiveStreamLength = this.getFullLiveStreamLength();
    // dle % kliknutí na časovou osu, spočítáme požadované zpoždění za živým vysílání
    const diff = fullLiveStreamLength - fullLiveStreamLength * (progress / 100);
    if (progress === 100) {
      setLiveActivated(true);
    } else {
      setLiveActivated(false);
    }

    // analytika live a timeshift
    const useLive = diff <= liveStreamDuration;
    const useTimeshift = !useLive;

    const triggerChangeToTimeshift = this.isOnLive() && useTimeshift;
    const triggerChangeToLive = this.isOnTimeshift() && useLive;

    await this.shiftLivePlayer(diff);

    if (triggerChangeToTimeshift) {
      this.props.playerAnalytics.onTimeshiftStart();
      return;
    }
    if (diff < timeshiftGapDuration && triggerChangeToLive) {
      this.props.playerAnalytics.onTimeshiftBackToLive();
      return;
    }

    const currProgress = timeShift
      ? ((fullLiveStreamLength - timeShift) / fullLiveStreamLength) * 100
      : 100;

    if (currProgress < progress && this.isOnTimeshift() && !useLive) {
      this.props.playerAnalytics.onTimeshiftSeekForward();
    } else if (currProgress > progress && this.isOnTimeshift() && !useLive) {
      this.props.playerAnalytics.onTimeshiftSeekBack();
    }
  };

  // má player aktuálně načtené živé video?
  isOnLive = (): boolean => {
    return this.state.isLiveStream === true;
  };

  // má player aktuálně načtené timeshift video?
  isOnTimeshift = (): boolean => {
    return this.state.isLiveStream === false;
  };

  // funkce pro posunutí časové osy na živém vysílání
  shiftLivePlayer = async (diff: number, fallbackTo: 'timeshift' | 'live' = 'live') => {
    const { setTimeShift, liveStreamDuration, timeshiftGapDuration } = this.context;
    const { playerClient } = this.props.playerSetup;
    // uživatel manipuloval s časovou osou, promítneme to v timeshiftu
    setTimeShift(diff);

    // chceme uživatele přesunout do živého?
    const useLive = diff <= (fallbackTo === 'live' ? timeshiftGapDuration : liveStreamDuration);

    // je aktuálně video pausnuté
    const isNotPlaying =
      this.state.playerState === PlayerClientState.PAUSED ||
      this.state.playerState === PlayerClientState.BUFFERING;

    let wasStreamChanged = false;

    // pokud je to třeba, změníme stream videa
    if (useLive && this.isOnTimeshift()) {
      this.props.playerAnalytics.client.trigger({ type: 'PlayerLiveStreamChanging' });
      const queue = await this.props.getQueue(this.props.queuePosition, true);
      this.resetRefetchTimers();
      await this.loadVideo('live', undefined, queue.current);
      if (!this.mounted) {
        return;
      }
      wasStreamChanged = true;
      this.setState({ isLiveStream: true });
    } else if (!useLive && this.isOnLive()) {
      this.props.playerAnalytics.client.trigger({ type: 'PlayerLiveStreamChanging' });
      const queue = await this.props.getQueue(this.props.queuePosition, true);
      this.resetRefetchTimers();
      await this.loadVideo('timeshift', undefined, queue.current);
      /* Native client musi nastavit novy currentTime, kdyz se prepne do timeshiftu a dostava
      v Safari spravne hodnoty, ktere nejsou dostupne v live: viz. progress event v Native client */
      playerClient.setDiff(diff - timeshiftGapDuration);

      if (!this.mounted) {
        return;
      }
      wasStreamChanged = true;
      this.setState({ isLiveStream: false });
    }

    const end = playerClient.getLiveSeekRangeEnd();

    // nastavíme čas videa na základě požadovaného zpoždění za živým
    await this.setCurrentTimeHandler(
      this.isOnLive() ? end - diff : end - (diff - timeshiftGapDuration)
    );

    if (wasStreamChanged) {
      this.props.playerAnalytics.client.trigger({ type: 'PlayerLiveStreamChanged' });
    }

    // pokud video hrálo nebo chceme koukat živě, chceme pokračovat v přehrávání
    if (!isNotPlaying || (this.isOnLive() && diff === 0)) {
      await this.play();
    }
  };

  onSeekBackButtonPressed = throttle(async () => {
    const { timeShift, setLiveActivated, isChromecastSession, onChromecastSeekBy } = this.context;
    const { setSeekedRecently } = this.props.playerAnalytics;
    const { playerClient, playerVariant } = this.props.playerSetup;
    const fullLiveStreamLength = this.getFullLiveStreamLength();

    if (isChromecastSession) {
      onChromecastSeekBy(-PLAYER_SEEK_BACK_SECONDS);
      return;
    }

    if (
      playerVariant === PlayerVariantEnum.LIVE &&
      (timeShift || 0) + TIMESHIFT_CONTROLS_END_OFFSET >= fullLiveStreamLength
    ) {
      return;
    }

    const currentTime = playerClient.getCurrentTime();
    setSeekedRecently();

    this.context.setOverlayIcon(OverlayIconType.SEEK_BACK);

    if (playerVariant === PlayerVariantEnum.VOD) {
      await this.setCurrentTimeHandler(Math.max(0, currentTime - PLAYER_SEEK_BACK_SECONDS));
      this.props.playerAnalytics.onSeekBackButtonPressed(false);
    }

    if (playerVariant === PlayerVariantEnum.LIVE) {
      setLiveActivated(false);
      const fullLiveStreamLength = this.getFullLiveStreamLength();
      if ((timeShift || 0) >= fullLiveStreamLength - TIMESHIFT_END_OFFSET) {
        await this.shiftLivePlayer(fullLiveStreamLength, 'timeshift');
      }
      await this.shiftLivePlayer((timeShift || 0) + PLAYER_SEEK_BACK_SECONDS, 'timeshift');
      this.props.playerAnalytics.onSeekBackButtonPressed(this.isOnTimeshift());
    }
  }, SEEKING_THROTTLE);

  onSeekForwardButtonPressed = throttle(async () => {
    const { playerClient, playerVariant } = this.props.playerSetup;
    const { timeShift, liveActivated, setLiveActivated, isChromecastSession, onChromecastSeekBy } =
      this.context;

    if (isChromecastSession) {
      onChromecastSeekBy(PLAYER_SEEK_BACK_SECONDS);
      return;
    }

    if (liveActivated && playerVariant === PlayerVariantEnum.LIVE) {
      return;
    }
    const { setSeekedRecently } = this.props.playerAnalytics;
    const currentTime = playerClient.getCurrentTime();
    setSeekedRecently();

    this.context.setOverlayIcon(OverlayIconType.SEEK_FORWARD);

    const { duration } = this.state;

    if (playerVariant === PlayerVariantEnum.VOD) {
      const newTime = Math.min(duration, currentTime + PLAYER_SEEK_FORWARD_SECONDS);
      await this.setCurrentTimeHandler(newTime);
      this.props.playerAnalytics.onSeekForwardButtonPressed(false);
    }

    if (playerVariant === PlayerVariantEnum.LIVE) {
      const calcPosition = Math.max(0, (timeShift || 0) - PLAYER_SEEK_FORWARD_SECONDS);
      if (calcPosition === 0) {
        setLiveActivated(true);
      } else {
        setLiveActivated(false);
      }
      await this.shiftLivePlayer(calcPosition, 'live');
      this.props.playerAnalytics.onSeekForwardButtonPressed(this.isOnTimeshift());
    }
  }, SEEKING_THROTTLE);

  onResolutionSelected = (desiredResolution: number | null): void => {
    const { playerClient } = this.props.playerSetup;
    const { client } = this.props.playerAnalytics;
    const availableResolutions = playerClient.getAvailableResolutions();
    const resolution =
      !!desiredResolution && availableResolutions.includes(desiredResolution)
        ? desiredResolution
        : null;
    const isDifferent = this.state.selectedResolution !== resolution;

    this.setState(
      {
        selectedResolution: resolution,
      },
      () => {
        if (resolution !== undefined && isDifferent && this.state.isPlayerSettingsInitiated) {
          client.trigger({ type: 'PlayerSettingsChangeResolution' });
        }
      }
    );
    this.updatePlayerSettingsCache({
      resolution,
    });

    if (resolution !== null && !isNaN(resolution)) {
      playerClient.setAdaptiveResolutionEnabled(false);
      playerClient.setResolution(resolution);
    } else {
      playerClient.setAdaptiveResolutionEnabled(true);
    }
  };

  isMainContent = () => {
    if (this.props.queue.current.type === PlayerStreamType.main) {
      return true;
    }
    return false;
  };

  updatePlayerSettingsCache = (updateSettings: Partial<PlayerSettings>): void => {
    if (
      !storageAvailable('localStorage') ||
      !this.isMainContent() ||
      this.props.bypassUserSettings
    ) {
      return;
    }
    const currentLocalStorageSettings = getLocalStoragePlayerSettings() || this.getPlayerSettings();
    localStorage.setItem(
      PLAYER_SETTINGS_LOCAL_STORAGE_CACHE_KEY,
      JSON.stringify({ ...currentLocalStorageSettings, ...updateSettings })
    );
  };

  updateUserVideoProgress = async (
    userId: string,
    userVideoProgressMeta: UserVideoProgressMeta
  ) => {
    if (this.props.bypassUserSettings) {
      return;
    }
    const { playerClient } = this.props.playerSetup;
    const currentTime = this.getCurrentTime();

    // kvuli kratkym videim
    if (currentTime < END_CREDITS_MAX_IN_SECONDS) {
      return;
    }

    const { idec, sidp, userVideoProgressReportingUrl } = userVideoProgressMeta;

    const duration = playerClient.getDuration();

    const isFinished = currentTime >= duration - END_CREDITS_MAX_IN_SECONDS;

    const payload: UserVideoProgressPayload = {
      sidp,
      idec,
      progress: Math.floor(currentTime),
      finished: isFinished,
      deviceId: userId,
      userId,
    };

    try {
      await fetch(userVideoProgressReportingUrl, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json',
        },
      });
    } catch (error) {
      // pokud bude nejaky problem, nebudeme kazdych 10s logovat, odesleme pouze jednou
      if (!this.userVideoProgressErrorLogged) {
        this.userVideoProgressErrorLogged = true;
        log.error({ error, message: 'Unable to report the current user video progress.' });
      }
    }
  };

  updateVideosInProgressCache = async (): Promise<void> => {
    if (
      !this.isMainContent() ||
      this.props.playerSetup.playerVariant !== PlayerVariantEnum.VOD ||
      this.props.bypassUserSettings
    ) {
      return;
    }

    const { userVideoProgressMeta, userId } = this.props;

    if (userId && userVideoProgressMeta) {
      void this.updateUserVideoProgress(userId, userVideoProgressMeta);
      return;
    }

    if (!storageAvailable('localStorage')) {
      return;
    }

    const { product } = this.props.playerSetup;
    const { mediaMetaId, indexId, versionId, idec, bonusId } = this.context;
    const { duration } = this.state;
    const currentTime = this.getCurrentTime();

    if (!mediaMetaId && !idec && !bonusId && !indexId) {
      return;
    }

    const externalId = idec || bonusId;

    const currentVideoProgress: VideoInProgress = {
      id: indexId || bonusId || mediaMetaId || idec || undefined,
      indexId,
      duration,
      progressPercentage: (currentTime / duration) * 100 || 0,
      progressSec: currentTime,
      // TODO pridat verzi videa
      // versionId: string,
      viewDate: new Date(),
    };

    const videosInProgress = new Map(
      JSON.parse(
        localStorage.getItem(`${product}/${VIDEOS_IN_PROGRESS_LOCAL_STORAGE_CACHE_KEY}`) || '[]'
      )
    );

    videosInProgress.set(
      createUniqueVideoId({
        mediaMetaId,
        indexId,
        versionId,
        externalId,
      }),
      currentVideoProgress
    );

    // pokud je map vetsi nez 100 odeber posledni polozku
    if (videosInProgress.size > MAX_CACHE_VIDEO_COUNT) {
      const lastItem = videosInProgress.keys().next().value;
      videosInProgress.delete(lastItem);
    }

    localStorage.setItem(
      `${product}/${VIDEOS_IN_PROGRESS_LOCAL_STORAGE_CACHE_KEY}`,
      JSON.stringify([...videosInProgress])
    );
  };

  onReplayButtonClick = async () => {
    const onCanPlay = async () => {
      void this.onCanPlay();
      this.videoRef.current?.removeEventListener('canplaythrough', onCanPlay);
    };

    this.props.playerAnalytics.onReplay();
    await this.resetPlayer();
    this.shouldTryReloadSubtitles = true;
    if (this.props.queue.length > 1) {
      this.setRefetchPlaylistFlag(true, 'Replay');
      await this.playNextStream(0);
    } else {
      this.videoRef.current?.addEventListener('canplaythrough', onCanPlay);
      const queue = await this.props.getQueue(0, true);
      this.resetRefetchTimers();
      await this.loadVideo(undefined, undefined, queue.current);
    }
  };

  onSkipAd = async () => {
    if (this.props.queue.current) {
      if (this.props.queuePosition < this.props.queue.items.length - 1) {
        this.onResetBeforeStreamChange();
        await this.playNextStream();
      } else {
        this.onFinished();
      }
    }
  };

  onTextTrackSelected = async (picked: PlayerSubtitles | null, bypassAnalytics = false) => {
    const { isChromecastSession, onChromecastChangeSubtitles, setLiveCaptionsOn } = this.context;
    if (isChromecastSession) {
      onChromecastChangeSubtitles(picked || null);
      return;
    }

    const { playerClient } = this.props.playerSetup;
    const { subtitles } = this.state;

    if (!subtitles) {
      return;
    }
    const hasManifestSubtitleTrack = this.state.subtitles?.some(
      (subtitle) => subtitle.url === MANIFEST_DEFAULT_SUBTILES_TAG
    );

    // LIVE titulky obsazene v manifestech (jsou jiz drive nastartovane, aktivni textTrack je soucasti stavu)
    if (hasManifestSubtitleTrack) {
      setLiveCaptionsOn(!!picked);
      this.setState(
        {
          isTextTrackVisible: !!picked,
          selectedSubtitle: picked,
        },
        () => {
          const { client } = this.props.playerAnalytics;
          if (!bypassAnalytics) {
            client.trigger({ type: 'PlayerSettingsChangeSubtitles' });
          }
        }
      );
      return;
    }

    // pokud nema zvolena moznost url, jedna se o titulky ve stavu vypnuto
    // titulky schovame a aktualizujeme cache
    if (!picked?.url) {
      this.setState({
        isTextTrackVisible: false,
        selectedSubtitle: null,
      });
      this.updatePlayerSettingsCache({
        subtitlesActive: false,
        subtitles: null,
      });
      return;
    }

    // nastavime titulky jako viditelne z vratime si relevantni textTrack
    const textTrack = playerClient.setTextTrack(picked);
    if (!textTrack) {
      return;
    }

    // aktualizujeme konkretni objekt z pole titulku, aby mel i textTrack
    const updatedSelectedSubtitles = { ...picked, textTrack };
    const updatedSubtitles = subtitles.map((subtitle) =>
      subtitle.code === picked.code ? { ...updatedSelectedSubtitles } : subtitle
    );

    // nastavime selectedSubtitles s textTrackem
    this.setState(
      {
        isTextTrackVisible: true,
        selectedSubtitle: updatedSelectedSubtitles,
        subtitles: updatedSubtitles,
      },
      () => {
        const { client } = this.props.playerAnalytics;
        if (!bypassAnalytics) {
          client.trigger({ type: 'PlayerSettingsChangeSubtitles' });
        }
      }
    );

    this.updatePlayerSettingsCache({
      subtitlesActive: true,
      subtitles: picked,
    });
  };

  onAudioTrackSelected = async ({
    audioTrack: selectedAudioTrack,
    forceSwitch,
    bypassAnalytics,
  }: AudioTrackSelectOptions) => {
    const { playerClient } = this.props.playerSetup;
    const { client } = this.props.playerAnalytics;
    const { isChromecastSession, onChromecastChangeAudioTrack, forcedAudioOnly } = this.context;

    if (isChromecastSession) {
      onChromecastChangeAudioTrack(selectedAudioTrack || null);
      return;
    }
    // pokud dostaneme null, pokusime se zapnout defaultni track
    const defaultAudioTrack = this.state.audioTracks?.length ? this.state.audioTracks[0] : null;
    const audioTrack =
      !selectedAudioTrack && defaultAudioTrack ? defaultAudioTrack : selectedAudioTrack;

    const didAudioChanged = this.state.selectedAudioTrack !== audioTrack || forceSwitch;

    if (!didAudioChanged) {
      return;
    }

    this.setState(
      {
        selectedAudioTrack: audioTrack,
      },
      () => {
        if (didAudioChanged && this.state.isPlayerSettingsInitiated && !bypassAnalytics) {
          client.trigger({ type: 'PlayerSettingsChangeAudio' });
        }
      }
    );

    if (audioTrack) {
      // klasicke prepinani audio stop
      if (audioTrack.id !== LANG_CODE_AD && !this.state.isExternalAudioDescription) {
        playerClient.setAudioTrack(audioTrack);
        // prepnuti streamu mezi mainUrl a audioDescription
      } else if (this.props.queue.current.audioDescription) {
        // refetch
        this.props.setForcedAudioOnlyFlag(forcedAudioOnly, audioTrack.id === LANG_CODE_AD);
        const updatedStreamData = await this.props.getQueue(this.props.queuePosition, true);
        this.resetRefetchTimers();
        playerClient.updateStreamData(updatedStreamData.current);
        try {
          this.setState({ isExternalAudioDescription: audioTrack.id === LANG_CODE_AD });
          await playerClient.audioDescriptionSwitch({ switchTo: audioTrack });
          void this.onTextTrackSelected(this.state.selectedSubtitle);
        } catch (e) {
          this.selectDefaultAudioTrack();
          return;
        }
      }
    }
    this.updatePlayerSettingsCache({
      audioTrack,
    });
  };

  shouldUseAutoplay(): boolean {
    return this.state.canUseAutoplay;
  }

  closeIndexMenu = () => {
    this.context.setIndexListVisible(false);
  };

  openIndexMenu = () => {
    this.context.setIndexListVisible(true);
  };

  onSwitchDebugMode = () => {
    this.setState((prev) => ({ debugMode: !prev.debugMode }));
    if (this.overlayRef.current) {
      this.overlayRef.current.focus();
    }
  };

  onIndexMenuItemSelected = async (activeIndexId: string) => {
    const {
      indexes,
      isChromecastSession,
      onChromecastSeek,
      onChromecastPlayPause,
      chromecastState,
    } = this.context;
    const { setSeekedRecently } = this.props.playerAnalytics;
    const activeIndex = indexes?.find((index) => index.indexId === activeIndexId);
    if (!activeIndex) {
      return;
    }

    if (isChromecastSession) {
      onChromecastSeek(activeIndex.startTime);
    } else {
      setSeekedRecently();
      await this.setCurrentTimeHandler(activeIndex.startTime);
      this.closeIndexMenu();
    }

    this.setState({
      activeIndex,
    });

    if (isChromecastSession) {
      if (chromecastState !== ChromecastState.PLAYING) {
        onChromecastPlayPause();
      }
      return;
    }

    await this.play();
  };

  onErrorToastMessageButtonClick = () => {
    const { reload } = this.props.playerSetup;
    const { setPlayerError } = this.context;

    setPlayerError(null);
    reload();
    window.location.reload(); // TODO dočasne řešení reloadu pro iFrame
  };

  onTimeDisplayClick = () => {
    if (this.videoRef.current) {
      const { timeDisplay } = this.state;
      if (timeDisplay === TimeDisplaySwitch.REMAINING_TIME) {
        this.setState({
          timeDisplay: TimeDisplaySwitch.CURRENT_TIME,
        });
        this.updatePlayerSettingsCache({
          timeDisplay: TimeDisplaySwitch.CURRENT_TIME,
        });
      } else {
        this.setState({ timeDisplay: TimeDisplaySwitch.REMAINING_TIME });
        this.updatePlayerSettingsCache({
          timeDisplay: TimeDisplaySwitch.REMAINING_TIME,
        });
      }
    }
  };

  // abychom nepřekreslovali opakovaně VideoHeader kvůli objektu meta
  getMemo = memoize(
    (object) => {
      return object;
    },
    (object) => {
      return JSON.stringify(object);
    }
  );

  render() {
    const { controlsItems, previewImage } = this.props;
    const {
      indexes,
      playerError,
      ageRestriction,
      videoTitle,
      showTitle,
      indexListVisible,
      previewTrackBaseUrl,
      externalId,
      isAudioOnly,
      hbbtvStreamingActiveDevice,
      forcedAudioOnly,
      playlistOptions,
    } = this.context;
    const {
      playerClient,
      showSimpleVideoHeader,
      disablePlayerCustomizations,
      playerVariant,
      playerWrapperRef,
    } = this.props.playerSetup;

    const {
      skipAdInSeconds,
      activeIndex,
      adaptiveResolution,
      audioTracks,
      buffered,
      duration,
      hiddenControlsDisabled,
      isMuted,
      isTextTrackVisible,
      isVideoOnly,
      playerMode,
      screenMode,
      selectedAudioTrack,
      selectedResolution,
      selectedSubtitle,
      subtitles,
      volume,
      wasUnmuted,
      canSeekToStartTimestamp,
      adClicked,
      isExternalAudioDescription,
      timeDisplay,
    } = this.state;

    // nechceme zobrazovat naše ovládání včetně overlayů, ale nativní ovládací prvky
    if (disablePlayerCustomizations) {
      return <Video nativeControls previewImage={previewImage} ref={this.videoRef} />;
    }

    const availableResolutions = this.getMemo(playerClient.getAvailableResolutions());

    const stream = playerClient.getCurrentStreamData();
    const showAdButton = stream?.type === PlayerStreamType.ad && stream.meta.clickUrl && adClicked;

    const playerClientState = playerClient.getState();

    let indexTitle: string | null = null;
    if (activeIndex?.title) {
      indexTitle = `${formatTime(activeIndex.startTime, 'G:i:s', 2)}  •  ${activeIndex.title}`;
    }

    // pokud došlo k network chybě, ale přehrávač ještě hraje, neukazujeme chybu
    // ukážeme až v momentě, kdy dojde k zastavení přehrávače
    const hasNetworkErrorButStillPlaying =
      playerError?.category === PlayerErrorCategory.NETWORK &&
      playerClientState === PlayerClientState.PLAYING;

    const errorMessage =
      playerError && !hasNetworkErrorButStillPlaying ? getErrorMessage(playerError) : {};

    const videoTimeShiftStart = this.getVideoTimeShiftStart();
    const fullLiveStreamLength = this.getFullLiveStreamLength();

    const showVideoTimeShiftStartButton = videoTimeShiftStart
      ? videoTimeShiftStart <= fullLiveStreamLength
      : false;

    const showControls = this.areControlsVisible();

    const readyOverlayDuration = getReadyOverlayDuration({
      currentDuration: this.state.duration,
      durationFromPlaylist: this.props.mainContentDuration,
      playerVariant,
      isMainContent: this.props.queue.current.type !== PlayerStreamType.ad,
    });

    const isLab = !!playlistOptions.isLab;

    return (
      <>
        {indexListVisible ? (
          <VideoIndexList
            currentIndex={activeIndex?.indexId}
            indexListVisible={indexListVisible}
            indexes={indexes || []}
            onClickCloseButton={this.closeIndexMenu}
            onClickIndexItem={this.onIndexMenuItemSelected}
          />
        ) : null}
        <Video
          hide={playerClientState === PlayerClientState.LOADING}
          muted={isMuted}
          previewImage={previewImage}
          ref={this.videoRef}
        />
        {/* TODO: VECKO-5256 - Non MVP, mělo by bý vypnutelné přes config */}
        {/* externalId ? <PlayerNextVideo id={externalId} /> : null} */}
        <ErrorToastMessage
          subtitle={errorMessage.subtitle}
          title={errorMessage.title}
          onButtonClick={this.onErrorToastMessageButtonClick}
        />
        {playerClientState !== PlayerClientState.INVALID && (
          <Overlay
            adClicked={adClicked}
            controlsVisible={showControls}
            currentProgress={this.getProgressPercentage()}
            duration={readyOverlayDuration}
            overlayRef={this.overlayRef}
            playerMode={playerMode}
            playerState={playerClientState}
            previewImage={previewImage}
            screenMode={screenMode}
            showAdButton={!!showAdButton}
            showSimpleVideoHeader={showSimpleVideoHeader}
            skipAdInSeconds={skipAdInSeconds}
            onAdButtonClick={this.onAdButtonClick}
            onClick={this.onOverlayClick}
            onDoubleClick={this.onOverlayDoubleClick}
            onKeyPressed={this.onKeyPressed}
            onMouseEnter={this.onOverylayMouseEnter}
            onMouseLeave={this.onOverlayMouseLeave}
            onMouseMove={this.onOverlayMove}
            onPlayButtonPressed={this.onPlayButtonPressed}
            onReplayButtonPressed={this.onReplayButtonClick}
            onSkipAd={this.onSkipAd}
            onTap={this.onOverlayTap}
          >
            {isTextTrackVisible &&
            this.videoRef.current &&
            playerWrapperRef.current &&
            playerClientState !== PlayerClientState.READY &&
            selectedSubtitle?.textTrack ? (
              <ClosedCaptions
                controlsVisible={showControls}
                isLivePlayer={playerVariant !== PlayerVariantEnum.VOD}
                playerContainer={playerWrapperRef.current}
                screenMode={screenMode}
                textTrack={selectedSubtitle.textTrack}
                videoElement={this.videoRef.current}
              />
            ) : null}
            {(controlsItems && !hiddenControlsDisabled) ||
            (controlsItems && playerClientState === PlayerClientState.FINISHED) ? (
              <Controls
                adaptiveResolution={adaptiveResolution}
                animationDisabled={this.controlsAnimationDisabled}
                audioTracks={audioTracks}
                availableResolutions={availableResolutions}
                buffered={buffered}
                currentTime={playerClient.getCurrentTime()}
                duration={duration}
                getFullLiveStreamLength={this.getFullLiveStreamLength}
                isExternalAudioDescription={isExternalAudioDescription}
                isMuted={isMuted}
                isOnTimeshift={this.isOnTimeshift()}
                isVideoOnly={isVideoOnly}
                items={controlsItems}
                playerMode={playerMode}
                playerRef={playerWrapperRef}
                playerState={playerClientState}
                screenMode={screenMode}
                selectedAudioTrack={selectedAudioTrack}
                selectedResolution={selectedResolution}
                selectedTextTrack={selectedSubtitle}
                setHiddenControlsDisabled={this.setHiddenControlsDisabled}
                showVideoTimeShiftStartButton={
                  canSeekToStartTimestamp || showVideoTimeShiftStartButton
                }
                streamUrl={playerClient.getAssetUri()}
                textTracks={subtitles}
                thumbnailsUrl={previewTrackBaseUrl}
                timeDisplaySwitch={timeDisplay}
                timeShift={this.context.timeShift}
                videoRef={this.videoRef}
                visible={showControls}
                volume={volume}
                onAudioTrackSelected={this.onAudioTrackSelected}
                onControlsMouseEnter={this.onControlsHover}
                onControlsMouseLeave={this.onControlsHoverOut}
                onForceAudioOnlySwitch={this.onForceAudioOnlySwitch}
                onFullscreenButtonPressed={this.toggleFullscreen}
                onHbbtvDeviceSelect={this.onHbbtvDeviceSelect}
                onInteraction={this.showControls}
                onPauseButtonPressed={this.onPauseButtonPressed}
                onPlayButtonPressed={this.onPlayButtonPressed}
                onProgressBarClick={this.onProgressBarClick}
                onReplayButtonPressed={this.onReplayButtonClick}
                onResolutionSelected={this.onResolutionSelected}
                onSeekBackButtonPressed={this.onSeekBackButtonPressed}
                onSeekForwardButtonPressed={this.onSeekForwardButtonPressed}
                onSoundOffButtonPressed={this.onSoundOffButtonPressed}
                onSoundOnButtonPressed={this.onSoundOnButtonPressed}
                onTextTrackSelected={this.onTextTrackSelected}
                onTimeDisplayClick={this.onTimeDisplayClick}
                onTimeShiftStartButtonPressed={this.onTimeShiftStartButtonPressed}
                onVolumeChange={this.onVolumeChange}
              >
                {playerMode !== PlayerMode.ads && (
                  <VideoHeader
                    hbbtvStreamingActiveDevice={!!hbbtvStreamingActiveDevice}
                    id={externalId}
                    indexTitle={indexTitle}
                    meta={this.getMemo({
                      indexes: indexes || [],
                      showTitle: showTitle || undefined,
                      ageRestriction: ageRestriction || undefined,
                      title: videoTitle || undefined,
                      duration,
                    })}
                    playerClient={playerClient}
                    showSimpleVideoHeader={showSimpleVideoHeader}
                    visible={showControls}
                    onIndexListVisible={this.openIndexMenu}
                    onInteraction={this.showControls}
                    onMouseEnter={this.onControlsHover}
                    onMouseLeave={this.onControlsHoverOut}
                  />
                )}
              </Controls>
            ) : null}
            {isMuted || isAudioOnly || forcedAudioOnly || isVideoOnly ? (
              <ExtraControls
                controlsVisible={showControls}
                isAudioOnly={!!isAudioOnly}
                isLoading={playerClientState === PlayerClientState.LOADING}
                isMuted={isMuted}
                isVideoOnly={isVideoOnly}
                toggleMuteUnmute={this.toggleMuteUnmute}
                wasUnmuted={wasUnmuted}
              />
            ) : null}
            {this.state.debugMode && this.videoRef.current ? (
              <DebugTools
                debugging={this.props.debugging}
                duration={this.state.duration}
                videoEl={this.videoRef.current}
                onClose={this.onSwitchDebugMode}
                onForceAudioOnlySwitch={this.onForceAudioOnlySwitch}
              />
            ) : null}
            {isLab && <Warning message="O2 LAB" />}
          </Overlay>
        )}
      </>
    );
  }
}

Player.contextType = PlayerContext;

export type PlayerProps = Omit<
  Props,
  | 'playerSettings'
  | 'isMediaSourceSupported'
  | 'supportedDRM'
  | 'analyticsContext'
  | 'playerSetup'
  | 'playerAnalytics'
  | 'playerDynamicData'
>;

export default compose(
  withPlayerLoaderRef,
  withPlayerSetup,
  withPlayerAnalytics
)(Player) as ComponentType<PlayerProps>;
