type ResolutionKey =
  | 'resolution1080'
  | 'resolution720'
  | 'resolution576'
  | 'resolution540'
  | 'resolution404'
  | 'resolution360'
  | 'resolution288'
  | 'resolution180'
  | 'resolution144'
  | 'resolutionAuto';

type ResolutionMessage = {
  defaultMessage: string;
  defaultMobileMessage: string;
};

type ResolutionMessages = {
  [key in ResolutionKey]: ResolutionMessage;
};

const createResolutionMessage = (
  desktopLabel: string,
  mobileLabel: string = desktopLabel
): ResolutionMessage => ({
  defaultMessage: desktopLabel,
  defaultMobileMessage: mobileLabel,
});

export const resolutionMessages: ResolutionMessages = {
  resolution1080: createResolutionMessage('Full HD'),
  resolution720: createResolutionMessage('HD'),
  resolution576: createResolutionMessage('Vysoká', '576p'),
  resolution540: createResolutionMessage('Vysoká', '540p'),
  resolution404: createResolutionMessage('Střední', '404p'),
  resolution360: createResolutionMessage('Střední', '360p'),
  resolution288: createResolutionMessage('Nízká', '288p'),
  resolution180: createResolutionMessage('Nízká', '180p'),
  resolution144: createResolutionMessage('Nejnižší', '144p'),
  resolutionAuto: createResolutionMessage('Auto'),
};

export const getResolutionLabel = (resolution: number | null, shortNames?: boolean): string => {
  const resolutionKey = resolution ? `resolution${resolution}` : 'resolutionAuto';

  if (resolutionKey in resolutionMessages) {
    const resolutionMessage = resolutionMessages[resolutionKey as keyof ResolutionMessages];

    return shortNames ? resolutionMessage.defaultMobileMessage : resolutionMessage.defaultMessage;
  }

  const fallbackMessage = resolutionMessages['resolutionAuto'];
  return shortNames ? fallbackMessage.defaultMobileMessage : fallbackMessage.defaultMessage;
};
