import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import throttle from 'lodash/throttle';
import classNames from 'classnames';
import { useFocusVisibleClassName, useWidth } from '@czechtv/components';
import { usePlayerContext } from '../../../PlayerContext';
import { Timestamps } from '../../../../components/ProgressBar/Timestamps/Timestamps';
import { LiveButton } from '../LiveButton/LiveButton';
import ProgressBar from '../../../../components/ProgressBar/ProgressBar';
import { formatMessage } from '../../../../utils/formatMessage';
import { liveProgressBarClassnames } from './LiveProgressBar.css';

export interface LiveProgressBarProps {
  buffered?: number;
  getFullLiveStreamLength: () => number;
  hideProgressBar: boolean;
  indicatorColor?: string;
  isLoading?: boolean;
  onClick: (progress: number) => void;
  thumbnailsUrl?: string;
}

export const LiveProgressBar = ({
  buffered,
  isLoading,
  indicatorColor,
  onClick,
  thumbnailsUrl,
  getFullLiveStreamLength,
  hideProgressBar,
}: LiveProgressBarProps) => {
  // pozice pro zobrazování indexu
  const [pointerPosition, setPointerPosition] = useState<undefined | number>(undefined);
  const {
    setPreventHideControls,
    timeShift,
    liveActivated,
    liveStreamDuration,
    timeshiftGapDuration,
  } = usePlayerContext();

  const [isDragging, setIsDragging] = useState(false);
  const progressBarContainerRef = useRef<HTMLDivElement>(null);
  const progressBarTimestampsWidth = useWidth(progressBarContainerRef);
  const focusVisibleClassName = useFocusVisibleClassName();

  const fullLiveStreamLength = getFullLiveStreamLength();
  const progress = timeShift
    ? Math.round(((fullLiveStreamLength - timeShift) / fullLiveStreamLength) * 100)
    : 100;

  useEffect(() => {
    if (isDragging) {
      setPreventHideControls(true);
    } else {
      setPreventHideControls(false);
    }
  }, [isDragging, setPreventHideControls]);

  const isTimeShiftActive = !!timeShift;

  const isInAvailableZone = useCallback(
    (progress: number): boolean =>
      progress > 100 - (liveStreamDuration / fullLiveStreamLength) * 100 ||
      progress < 100 - (timeshiftGapDuration / fullLiveStreamLength) * 100,
    [fullLiveStreamLength, liveStreamDuration, timeshiftGapDuration]
  );

  const calcProgress = (e: { pageX: number }): number => {
    if (!progressBarContainerRef.current) {
      return 0;
    }
    const { left, right } = progressBarContainerRef.current.getBoundingClientRect();

    const relativeProgress = e.pageX - left;
    const relativeMaxProgress = right - left;

    const newProgress = (relativeProgress / relativeMaxProgress) * 100;

    return Math.min(100, Math.max(0, newProgress));
  };

  const onProgressBarMouseEnter = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    setPointerPosition(calcProgress(e));
  }, []);

  const onProgressBarMouseLeave = useCallback(() => {
    if (isDragging) {
      return;
    }
    setPointerPosition(undefined);
  }, [isDragging]);

  // TODO: Implement keypress callback for accessibility
  const onProgressBarKeyPress = useCallback(() => {}, []);

  const onTouchStart = useCallback((event: React.TouchEvent) => {
    setPointerPosition(calcProgress(event.touches[0]));
  }, []);

  const onTouchEnd = useCallback(
    (event: React.TouchEvent) => {
      // nechceme pro touch spoustet mouse eventy (delaji to browsery kvuli zpetne kompatibilite)
      event.preventDefault();
      const curPointerPosition = pointerPosition || 0;
      if (isInAvailableZone(curPointerPosition)) {
        onClick(curPointerPosition);
      }
      setPointerPosition(undefined);
      setIsDragging(false);
    },
    [isInAvailableZone, onClick, pointerPosition]
  );

  const onTouchMove = useCallback((event: React.TouchEvent<HTMLDivElement>) => {
    if (!event.touches[0]) {
      return;
    }
    setPointerPosition(calcProgress(event.touches[0]));
    setIsDragging(true);
  }, []);

  const bodyMouseMove = useMemo(
    () =>
      throttle((e: MouseEvent) => {
        const progress = calcProgress(e);
        if (isInAvailableZone(progress)) {
          setIsDragging(true);
          setPointerPosition(progress);
        }
      }, 50),
    [isInAvailableZone]
  );

  useEffect(() => {
    return () => bodyMouseMove.cancel();
  }, [bodyMouseMove]);

  const unavailableTime = useMemo(
    () => ({
      from: ((fullLiveStreamLength - timeshiftGapDuration) / fullLiveStreamLength) * 100,
      to: ((fullLiveStreamLength - liveStreamDuration) / fullLiveStreamLength) * 100,
    }),
    [fullLiveStreamLength, liveStreamDuration, timeshiftGapDuration]
  );

  const onLiveButtonClick = useCallback(() => onClick(100), [onClick]);

  // náhrada za drag event, kde nelze manipulovat s kurzorem myši
  const onMouseDown = useCallback(() => {
    const onMouseOut = (e: MouseEvent) => {
      const progress = calcProgress(e);
      if (isInAvailableZone(progress)) {
        onClick(progress);
        setPointerPosition(undefined);
      }
      setIsDragging(false);

      // po konci dragu odstraníme listenery
      document.removeEventListener('mousemove', bodyMouseMove);
      document.removeEventListener('mouseup', onMouseOut);
      document.body.style.cursor = 'auto';
    };

    // nabindujeme eventy pro dragování
    document.addEventListener('mousemove', bodyMouseMove);
    document.addEventListener('mouseup', onMouseOut);
    document.body.style.cursor = 'pointer';
  }, [bodyMouseMove, isInAvailableZone, onClick]);

  const showLiveActivated = timeShift
    ? timeShift < liveStreamDuration && liveActivated
    : liveActivated;

  const progressTimeshiftInMinutes = `${Math.floor(
    (timeShift || fullLiveStreamLength) / 60
  )} minut a ${Math.floor((timeShift || fullLiveStreamLength) % 60)} sekund`;

  return (
    <div className={liveProgressBarClassnames.progressBarComponentWrapper}>
      <div
        aria-label={formatMessage({
          id: 'LiveProgressBarAriaLabelTitle',
          defaultMessage: 'Posuvník přehrávání',
          description: 'Aria label pro ProgressBar',
        })}
        aria-valuemax={100}
        aria-valuemin={0}
        aria-valuenow={progress}
        aria-valuetext={
          showLiveActivated
            ? formatMessage({
                id: 'LiveProgressBar.liveBroadcast',
                defaultMessage: 'živé vysílání',
                description: 'aria label pro živé přehrávání v progressbaru',
              })
            : formatMessage(
                {
                  id: 'LiveProgressBar.fromTotalDuration',
                  defaultMessage:
                    'Zpožděno o {progressTimeshiftInMinutes} minut proti živému vysílání',
                  description:
                    'Zpožděno o {progressTimeshiftInMinutes} minut proti živému vysílání',
                },
                { progressTimeshiftInMinutes }
              )
        }
        className={classNames(
          liveProgressBarClassnames.progressBarContainer,
          focusVisibleClassName
        )}
        data-testid="ProgressBar"
        ref={progressBarContainerRef}
        role="slider"
        style={{ cursor: isLoading ? 'default' : 'pointer' }}
        tabIndex={0}
        onKeyPress={!isLoading ? onProgressBarKeyPress : undefined}
        onMouseDown={!isLoading ? onMouseDown : undefined}
        onMouseLeave={!isLoading ? onProgressBarMouseLeave : undefined}
        onMouseMove={!isLoading ? onProgressBarMouseEnter : undefined}
        onTouchEnd={!isLoading ? onTouchEnd : undefined}
        onTouchMove={!isLoading ? onTouchMove : undefined}
        onTouchStart={!isLoading ? onTouchStart : undefined}
      >
        {!isLoading && !hideProgressBar ? (
          <Timestamps
            getFullLiveStreamLength={getFullLiveStreamLength}
            positionPercentage={pointerPosition}
            progressBarTimestampsWidth={progressBarTimestampsWidth}
            thumbnailsUrl={thumbnailsUrl}
          />
        ) : null}
        {!hideProgressBar && (
          <ProgressBar
            // VECKO-3102 Má tu chodit hodnota v % ale chodí sem hegeš
            buffered={buffered}
            hover={pointerPosition !== undefined}
            indicatorColor={indicatorColor}
            isLoading={isLoading}
            progress={isDragging ? pointerPosition || 0 : progress}
            unavailableTime={unavailableTime}
          />
        )}
      </div>
      <div className={liveProgressBarClassnames.infoSection}>
        <LiveButton
          className={!isTimeShiftActive ? undefined : liveProgressBarClassnames.liveButton}
          live={showLiveActivated || !isTimeShiftActive}
          onClick={onLiveButtonClick}
        />
      </div>
    </div>
  );
};
