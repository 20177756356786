import React from 'react';
import classNames from 'classnames';
import { AspectRatioPlaceholder, Button } from '@czechtv/components';
import GradientOverlay from '../../Player/Overlay/VOD/GradientOverlay/GradientOverlay';
import { useMediaBreakpoints } from '../../utils/playerResponsive';
import PlayerBasedResponsive from '../PlayerBasedResponsive/PlayerBasedResponsive';
import { infoOverlayClassnames } from './InfoOverlay.css';

export interface InfoOverlayProps {
  buttonText: string;
  onClick: () => void;
  short?: string;
  subtitle: string;
  title: string;
}

const InfoOverlay = ({ title, subtitle, short, buttonText, onClick }: InfoOverlayProps) => {
  const { isMaxMediumDesktop, isMaxMediumMobile } = useMediaBreakpoints();

  return (
    <div className={infoOverlayClassnames.overlayContainer}>
      <GradientOverlay className={infoOverlayClassnames.overlay}>
        <div className={infoOverlayClassnames.wrapper}>
          <div className={classNames(infoOverlayClassnames.title)}>{title}</div>
          <div className={infoOverlayClassnames.subtitle}>
            {isMaxMediumMobile && short ? short : subtitle}
          </div>
          {onClick && (
            <div className={infoOverlayClassnames.footer}>
              <Button
                className={infoOverlayClassnames.button}
                size={isMaxMediumDesktop ? 'medium' : 'large'}
                onClick={onClick}
              >
                {buttonText}
              </Button>
            </div>
          )}
        </div>
      </GradientOverlay>
    </div>
  );
};

export const InfoOverlayStandalone = (props: InfoOverlayProps) => {
  return (
    <PlayerBasedResponsive>
      <AspectRatioPlaceholder>
        <InfoOverlay {...props} />
      </AspectRatioPlaceholder>
    </PlayerBasedResponsive>
  );
};
