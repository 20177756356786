import { LANG_CODE_AD, AUDIO_MESSAGES } from '../constants';
import { PlayerAudioTrack } from './types';

export const getAudioLanguageLabel = (
  audioTrack: PlayerAudioTrack,
  shortNames?: boolean
): string => {
  const lowerCasedLanguage = audioTrack.language.toLocaleLowerCase();

  const isInternalAudioDescription = audioTrack.role === 'alternate';

  if (
    lowerCasedLanguage === 'que' ||
    lowerCasedLanguage === 'qp' ||
    lowerCasedLanguage === LANG_CODE_AD ||
    isInternalAudioDescription
  ) {
    if (shortNames) {
      return AUDIO_MESSAGES.audioBlind.defaultMobileMessage as string;
    }

    return AUDIO_MESSAGES.audioBlind.defaultMessage;
  }

  if (shortNames) {
    return (AUDIO_MESSAGES[lowerCasedLanguage]?.defaultMobileMessage ||
      AUDIO_MESSAGES.audioOriginal.defaultMobileMessage) as string;
  }

  return (
    AUDIO_MESSAGES[lowerCasedLanguage]?.defaultMessage ||
    AUDIO_MESSAGES.audioOriginal.defaultMessage
  );
};
