/* eslint-disable canonical/filename-no-index */
import classNames from 'classnames';
import React, { LegacyRef, memo, ReactNode, RefObject, useEffect, useRef } from 'react';
import FocusTrap from 'focus-trap-react';
import { IconClose } from '@czechtv/icons';
import { Scrollbar, useHeight } from '@czechtv/components';
import { PlayerNativeButton } from '../../../../components/PlayerNativeButton/PlayerNativeButton';
import { MenuPopupType } from '../../../../constants';
import { SettingsListItemsEnum } from '../SettingsMenu/SettingsList/SettingsListItem/SettingsListItem';
import { menuPopupClassnames } from './MenuPopup.css';

interface MenuPopupProps {
  activeItem?: string;
  children: ReactNode;
  closeButtonAriaLabel: string;
  hasLeftSectionContent?: boolean;
  manuPopupContentClassName?: string;
  menuPopupRef?: RefObject<HTMLDivElement>;
  menuPopupTitleAriaLabel: string;
  name?: MenuPopupType;
  onClose?: () => void;
  playerRef?: RefObject<HTMLElement>;
  scrollableContent?: boolean;
  setMenuPopupVisible: (visible: MenuPopupType | null) => void;
  titleBarContent?: ReactNode | string | null;
  visible?: boolean;
}

const MenuPopup = ({
  activeItem,
  hasLeftSectionContent = false,
  menuPopupRef,
  menuPopupTitleAriaLabel,
  children,
  closeButtonAriaLabel,
  titleBarContent,
  scrollableContent = false,
  onClose = () => {},
  manuPopupContentClassName: menuPopupContentClassName,
}: MenuPopupProps) => {
  const isCaptions = activeItem === SettingsListItemsEnum.SUBTITLES;

  const minHeight = isCaptions ? 'calc(100% - 54px)' : 'calc(100% - 32px)';
  const innerRef = useRef<HTMLDivElement>(null);
  const outerRef = useRef<HTMLDivElement>(null);
  const innerHeight = useHeight(innerRef);
  const outerHeight = useHeight(outerRef);
  const scrollable = innerHeight + 15 > outerHeight;

  /*
  Menu pro castovani a settings se musi dat zavrit do Focus Trap. Zaroven je treba, aby:
  - slo zavrit pomoci Esc (resi Focus Trap)
  - slo zavrit kliknutim na X a opetovnym kliknutim na ikonku
  - slo zavrit kliknutim mimo menu (zde je problem v menu pro castovani, protoze jeho ref se zmeni
    pri prechodu mezi jeho jednotlivymi obrazovkami, takze event target na 'click' uz nelze najit
    v ramci noveho obsahu refu)
  - musi jit rovnou prepinat mezi jednotlivymi menu
  */
  useEffect(() => {
    const onDocumentClick = (e: MouseEvent) => {
      // klasicke overovani kliknuti do menu
      const isStaticMenuIncluded = e.target && menuPopupRef?.current?.contains(e.target as Node);
      // kliknuti na button v menu
      let isMenuButton = false;
      if (e.target && 'closest' in e.target) {
        const closestButton = (e.target as Element).closest('button');
        isMenuButton = !!closestButton;
      }
      // kombinace obojiho
      const isClickedInside = isStaticMenuIncluded || isMenuButton;
      if (!isClickedInside) {
        onClose();
      }
    };
    document.addEventListener('click', onDocumentClick);
    return () => {
      document.removeEventListener('click', onDocumentClick);
    };
  }, [menuPopupRef, onClose]);

  return (
    <FocusTrap focusTrapOptions={{ allowOutsideClick: true, onDeactivate: onClose }}>
      <div
        aria-modal
        aria-label={menuPopupTitleAriaLabel}
        className={classNames(menuPopupClassnames.menuPopupContainer, {
          hasLeftSectionContent: !hasLeftSectionContent,
        })}
        data-testid="playerSettingsMenu"
        id="settings-menu"
        ref={menuPopupRef}
        role="dialog"
      >
        <div className={classNames(menuPopupClassnames.titleBar, { scrollable, titleBarContent })}>
          {titleBarContent ? (
            <div className={menuPopupClassnames.titleBarContent}>{titleBarContent}</div>
          ) : null}
          <PlayerNativeButton
            aria-label={closeButtonAriaLabel}
            className={classNames(menuPopupClassnames.closeIcon)}
            onClick={onClose}
          >
            <IconClose height={24} width={24} />
          </PlayerNativeButton>
        </div>
        {scrollableContent ? (
          <Scrollbar
            className={classNames(menuPopupClassnames.scrollBarWrapper, {
              titleBarContent,
              scroll: true,
            })}
            ref={outerRef as React.ForwardedRef<HTMLDivElement>}
          >
            <div
              className={classNames(menuPopupClassnames.menuPopupContent, {
                scrollable,
              })}
              ref={innerRef as LegacyRef<HTMLDivElement>}
              style={{ minHeight }}
            >
              {children}
            </div>
          </Scrollbar>
        ) : (
          <div
            className={classNames(
              menuPopupClassnames.menuPopupContent,
              menuPopupContentClassName,
              {}
            )}
            style={{ minHeight }}
          >
            {children}
          </div>
        )}
      </div>
    </FocusTrap>
  );
};

export default memo(MenuPopup);
