/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { memo } from 'react';
import { indexListClassnames } from './IndexList.css';

export interface IndexItemProps {
  description?: string | null;
  imageUrl: string | null;
  indexId: string;
  startTime: number;
  title: string | null;
  url?: string;
}

interface IndexListProps {
  indexes: IndexItemProps[];
  videoDuration: number;
}

const IndexList = ({ indexes, videoDuration }: IndexListProps) => {
  let progressBarIndexList: JSX.Element[] = [];

  if (indexes && videoDuration > 0) {
    progressBarIndexList = indexes.map((item: IndexItemProps) => {
      const position = (item.startTime / videoDuration) * 100;
      return (
        <li
          className={indexListClassnames.progressBarIndex}
          data-testid="IndexListItem"
          key={item.indexId}
          style={{ left: `${position}%` }}
        >
          <div
            className={indexListClassnames.indexIndicator}
            // TODO zjistit jak spravne resit indexy z hlediska pristupnosti
            onKeyDown={() => {}}
          />
        </li>
      );
    });
  }

  return (
    <div className={indexListClassnames.progressBarIndexList} data-testid="IndexList">
      <ul className={indexListClassnames.listWrapper}>{progressBarIndexList}</ul>
    </div>
  );
};

export default memo(IndexList);
