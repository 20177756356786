import React, { memo } from 'react';
import classNames from 'classnames';
import { IconSoundOff } from '@czechtv/icons';
import { useMobileDevice } from '@czechtv/styles';
import { PlayerNativeButton } from '../../../../components/PlayerNativeButton/PlayerNativeButton';
import { PlayerControlsItem, VODPlayerControlsItemsDefinition } from '../../items';
import { useMediaBreakpoints } from '../../../../utils/playerResponsive';
import { usePlayerContext } from '../../../PlayerContext';
import { extraControlsClassnames } from './ExtraControls.css';
import { AudioOnly } from './AudioOnly';

interface ExtraControlsProps {
  className?: string;
  controlsVisible: boolean;
  isAudioOnly: boolean;
  isLoading: boolean;
  isMuted: boolean;
  isVideoOnly: boolean;
  toggleMuteUnmute: () => Promise<void>;
  wasUnmuted: boolean;
}

const ExtraControls = memo(
  ({
    className,
    controlsVisible,
    isMuted,
    isLoading,
    toggleMuteUnmute,
    isAudioOnly,
    isVideoOnly,
    wasUnmuted,
  }: ExtraControlsProps) => {
    const isMobileDevice = useMobileDevice();
    const { isMinMobile } = useMediaBreakpoints();
    const { hbbtvStreamingActiveDevice, isChromecastSession, forcedAudioOnly } = usePlayerContext();

    const onUnmute = (e: React.MouseEvent) => {
      e.stopPropagation();
      void toggleMuteUnmute();
    };

    const shouldShowMutedButton = () => {
      if (isMobileDevice && isMuted && !hbbtvStreamingActiveDevice) {
        return true;
      }
      if (!isMobileDevice && isMuted && !wasUnmuted && !hbbtvStreamingActiveDevice) {
        return true;
      }
      return false;
    };

    if (isLoading || isVideoOnly) {
      return null;
    }

    const showMutedButton = shouldShowMutedButton();

    if (showMutedButton && !isChromecastSession) {
      const showFullButton = !controlsVisible || !isMobileDevice;
      return (
        <div
          className={classNames(extraControlsClassnames.wrapper, className, {
            [extraControlsClassnames.controlsVisible]: controlsVisible,
            isMinMobile,
            isNotMobileDevice: !isMobileDevice,
          })}
        >
          <PlayerNativeButton
            aria-label={VODPlayerControlsItemsDefinition[PlayerControlsItem.UNMUTE]}
            className={extraControlsClassnames.button}
            title={VODPlayerControlsItemsDefinition[PlayerControlsItem.UNMUTE]}
            onClick={onUnmute}
          >
            <div className={extraControlsClassnames.iconWrapper}>
              <IconSoundOff className={extraControlsClassnames.icon} tabIndex={-1} />
            </div>
            {showFullButton && (
              <div className={extraControlsClassnames.title}>Přehrát se zvukem</div>
            )}
          </PlayerNativeButton>
        </div>
      );
    }
    if (isAudioOnly || forcedAudioOnly) {
      return (
        <AudioOnly message={forcedAudioOnly ? 'Přehráváte pouze zvuk' : 'Obsahuje pouze zvuk'} />
      );
    }
    return null;
  }
);

export { ExtraControls };
