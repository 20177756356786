import React, { memo, useEffect, useRef } from 'react';
import { IconPause, IconPlay, IconSeekBack, IconSeekForward, SvgComponent } from '@czechtv/icons';
import { usePlayerContext } from '../../../PlayerContext';
import VideoIconButton from '../../../../components/VideoIconButton/VideoIconButton';
import { OverlayIconType } from './typeDefs';
import { iconClassnames } from './Icon.css';

const OVERLAY_ICON_TIMEOUT = 500;

const getIcon = (
  iconType: OverlayIconType | null
): { Component: SvgComponent; testId: OverlayIconType } | null => {
  switch (iconType) {
    case OverlayIconType.PAUSE:
      return { Component: IconPause, testId: OverlayIconType.PAUSE };

    case OverlayIconType.PLAY:
      return { Component: IconPlay, testId: OverlayIconType.PLAY };

    case OverlayIconType.SEEK_FORWARD:
      return { Component: IconSeekForward, testId: OverlayIconType.SEEK_FORWARD };

    case OverlayIconType.SEEK_BACK:
      return { Component: IconSeekBack, testId: OverlayIconType.SEEK_BACK };

    default:
      return null;
  }
};

const OverlayIcon = () => {
  const { setOverlayIcon, overlay } = usePlayerContext();
  const overlayIconTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    if (overlayIconTimeout.current !== null) {
      clearTimeout(overlayIconTimeout.current);
    }

    if (overlay.icon !== null) {
      overlayIconTimeout.current = setTimeout(() => {
        setOverlayIcon(null);
        overlayIconTimeout.current = null;
      }, OVERLAY_ICON_TIMEOUT);
    }

    return () => {
      if (overlayIconTimeout.current !== null) {
        clearTimeout(overlayIconTimeout.current);
      }
    };
  }, [overlay, setOverlayIcon]);

  const icon = getIcon(overlay.icon);

  return (
    <div
      className={iconClassnames.overlayIconContainer}
      data-testid="playerOverlayIcon"
      key={overlay.updated}
    >
      {icon && (
        <span data-testid={`playerOverlayIcon-${icon.testId}`}>
          <VideoIconButton
            suppressHoverEffect
            Icon={icon.Component}
            className={iconClassnames.overlayIcon}
          />
        </span>
      )}
    </div>
  );
};

export default memo(OverlayIcon);
