import React, { memo } from 'react';
import classNames from 'classnames';
import { labelingClassnames } from './Labeling.css';

interface LabelingProps {
  ageRestriction: string;
  className?: string;
}

const Labeling = ({ ageRestriction, className }: LabelingProps) => {
  return <div className={classNames(labelingClassnames.labeling, className)}>{ageRestriction}</div>;
};

export default memo(Labeling);
