import React, { memo, useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import FocusTrap from 'focus-trap-react';
import { IconClose } from '@czechtv/icons';
import { ImageLoader, useFocusVisibleClassName, Scrollbar } from '@czechtv/components';
import { formatTime } from '@czechtv/utils';
import { useAnalytics } from '@czechtv/analytics-react';
import { AnalyticsPlayerContext } from '@czechtv/analytics';
import { useMediaBreakpoints } from '../../../../utils/playerResponsive';
import { IndexItemProps } from '../ProgressBar/IndexList/IndexList';
import { PlayerNativeButton } from '../../../../components/PlayerNativeButton/PlayerNativeButton';
import { formatMessage } from '../../../../utils/formatMessage';
import { videoIndexListClassnames } from './VideoIndexList.css';

interface VideoIndexListProps {
  currentIndex?: string;
  indexListVisible?: boolean;
  indexes: IndexItemProps[];
  licenseInfo?: string;
  onClickCloseButton: () => void;
  onClickIndexItem: (id: string) => void;
  playerPreview?: boolean;
}

const VideoIndexList = memo(
  ({
    indexListVisible,
    licenseInfo,
    currentIndex,
    indexes,
    onClickIndexItem,
    playerPreview = false,
    onClickCloseButton,
  }: VideoIndexListProps) => {
    const { isMinDesktop, isMinDesktopMedium } = useMediaBreakpoints();
    const [indexActive, setIndexActive] = useState(currentIndex);
    const [indexListFirstClosed, setIndexListFirstClosed] = useState(false);
    // V Safari neni videt focus, pokud neni inset
    const focusVisibleClassName = useFocusVisibleClassName({ inset: true });
    const analytics = useAnalytics();

    const showIndexes = useCallback(() => {
      // pokud indexy nemáme, neukazujeme
      if (indexes.length === 0) {
        return false;
      }
      // pokud došlo k interakci, skryjeme/zobrazíme podle ní
      if (indexListVisible !== undefined) {
        return indexListVisible;
      }
      // na 938+ máme indexy otevřené by default
      if (isMinDesktopMedium) {
        return true;
      }
      // na 768+ máme indexy otevřené pokud není zobrazená informace o licenci
      if (isMinDesktop && !licenseInfo) {
        return true;
      }
      // na ostatních rozlišeních jsou indexy by default skryté
      return false;
    }, [indexListVisible, indexes.length, isMinDesktop, isMinDesktopMedium, licenseInfo]);

    useEffect(() => {
      const focusTrap = (e: KeyboardEvent) => {
        if (e.key === 'Escape') {
          // knihovna neumoznuje zavrit index list escapem kdyz je pausnuty, timto to umoznime
          if (!indexListFirstClosed) {
            onClickCloseButton();
          }
        }
      };
      // kdyz je index list schovany, ale mounted, nepridavame listener
      if (showIndexes()) {
        document.addEventListener('keydown', focusTrap);
      }
      return () => {
        document.removeEventListener('keydown', focusTrap);
      };
    }, [onClickCloseButton, showIndexes, indexListFirstClosed]);

    const onClickListItemHandler = (id: string) => {
      setIndexActive(id);
      onClickIndexItem(id);

      const activeIndex = indexes.find((index) => index.indexId === id);
      if (activeIndex) {
        const analyticsContext: Partial<AnalyticsPlayerContext> = {
          position: activeIndex.startTime,
        };
        const context = analytics.getContext();
        if (context) {
          analytics.setContext({ ...context, ...analyticsContext });
          analytics.trigger({ type: 'PlayerIndexStartList' });
        }
      }
    };

    const closeIndexList = () => {
      setIndexListFirstClosed(true);
      onClickCloseButton();
    };

    useEffect(() => {
      setIndexActive(currentIndex);
    }, [currentIndex]);

    if (!showIndexes()) {
      return null;
    }
    const indexItems = indexes.map((item: IndexItemProps) => {
      const isActiveItem = indexActive === item.indexId;
      return (
        <li key={item.indexId}>
          <PlayerNativeButton
            aria-label={`${item.title} - ${formatTime(item.startTime, 'H:i:s', 2)}`}
            className={classNames(videoIndexListClassnames.listItem, focusVisibleClassName, {
              active: isActiveItem,
            })}
            data-testid="videoIndexListItem"
            onClick={() => onClickListItemHandler(item.indexId)}
          >
            <span className={videoIndexListClassnames.timestamp}>
              {formatTime(item.startTime, 'G:i:s', 2)}
            </span>
            <ImageLoader
              className={videoIndexListClassnames.videoImage}
              src={item.imageUrl ? item.imageUrl : undefined}
            />
            <span
              className={classNames(videoIndexListClassnames.itemTitle, { active: isActiveItem })}
            >
              {item.title}
            </span>
          </PlayerNativeButton>
        </li>
      );
    });

    return (
      <FocusTrap
        focusTrapOptions={{
          onDeactivate: closeIndexList,
          clickOutsideDeactivates: true,
          returnFocusOnDeactivate: true,
          escapeDeactivates: true,
        }}
        // pri preview s otevrenym indexlistem focus trap pred prvnim zavrenim indexlistu pausneme
        paused={
          !indexListFirstClosed &&
          playerPreview &&
          ((isMinDesktop && !licenseInfo) || isMinDesktopMedium)
        }
      >
        <div
          aria-modal
          aria-label={formatMessage(
            {
              id: 'VideoIndexList.title',
              defaultMessage: 'Části ({indexCount})',
              description: 'Titulek seznamu indexů',
            },
            { indexCount: `obsahuje ${indexes.length} částí videa` }
          )}
          className={classNames(videoIndexListClassnames.listWrapper, {
            licenseInfo,
            playerPreview,
          })}
          data-testid="videoIndexList"
        >
          <div className={videoIndexListClassnames.titleWrapper}>
            <div>
              {formatMessage(
                {
                  id: 'VideoIndexList.title',
                  defaultMessage: 'Části ({indexCount})',
                  description: 'Titulek seznamu indexů',
                },
                { indexCount: indexes.length }
              )}
            </div>
            <PlayerNativeButton
              aria-label={formatMessage({
                id: 'VideoIndexList.CloseIndexListAriaLabel',
                defaultMessage: 'Zavřít panel s indexy',
                description: 'Aria-label pro zavření panelu s indexy',
              })}
              className={videoIndexListClassnames.closeButton}
              onClick={onClickCloseButton}
            >
              <IconClose className={videoIndexListClassnames.icon} />
            </PlayerNativeButton>
          </div>
          <Scrollbar className={videoIndexListClassnames.scrollWrapper}>
            <ul className={videoIndexListClassnames.list} data-testid="videoIndexListItems">
              {indexItems}
            </ul>
          </Scrollbar>
        </div>
      </FocusTrap>
    );
  }
);

export { VideoIndexList };
