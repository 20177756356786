/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { formatTime } from '@czechtv/utils';
import { Debugging } from '../../../constants';
import { usePlayerContext } from '../../PlayerContext';
import { CaptionColorVariant, CaptionFontSize } from '../../../utils/closedCaptions';
import { debugToolsClassnames } from './DebugTools.css';

const formatShareTime = (time: number) => {
  return formatTime(time, time >= 60 * 60 ? 'H:i:s' : 'i:s', 2);
};

interface Props {
  debugging?: Debugging;
  duration: number;
  onClose: () => void;
  onForceAudioOnlySwitch: () => Promise<void>;
  videoEl: HTMLVideoElement;
}

export const DebugTools = ({
  videoEl,
  debugging,
  onClose,
  duration: _duration,
  onForceAudioOnlySwitch,
}: Props) => {
  const timeButtonRef = useRef<HTMLButtonElement>(null);
  const playerVersion = debugging?.playerVersion?.replace('^', '');
  const context = usePlayerContext();
  const [showDevTools, setShowDevTools] = useState(false);

  const {
    captionColorVariant,
    captionFontSize,
    setCaptionColorVariant,
    setCaptionFontSize,
    playlistOptions,
    forcedAudioOnly,
  } = context;
  const { useNewPlaylist } = playlistOptions;
  const messageTimeout = useRef<ReturnType<typeof setTimeout> | undefined>();
  const [copiedMessage, setCopiedMessage] = useState<string | undefined>();

  const duration = formatShareTime(_duration);

  useEffect(() => {
    return () => {
      if (messageTimeout.current) {
        clearTimeout(messageTimeout.current);
      }
    };
  }, []);

  const showCopiedMessage = (message: string) => {
    if (messageTimeout.current) {
      clearTimeout(messageTimeout.current);
    }
    messageTimeout.current = setTimeout(() => {
      setCopiedMessage(undefined);
    }, 1000);
    setCopiedMessage(message);
  };

  const handleCopyValue = (e: React.MouseEvent<HTMLButtonElement>) => {
    const value = e.currentTarget.innerText;
    navigator.clipboard
      .writeText(value)
      .then(() => {
        showCopiedMessage(`Zkopírováno ${value}`);
      })
      .catch(() => {
        showCopiedMessage(`Kopírování není povoleno`);
      });
  };

  const id = useMemo(() => {
    const options = context.playlistOptions;
    return (
      options.externalId ||
      options.encoder ||
      options.indexId ||
      options.bonusId ||
      options.mediaId ||
      options.versionId
    );
  }, [context.playlistOptions]);

  useEffect(() => {
    const onTimeUpdate = () => {
      if (timeButtonRef.current) {
        timeButtonRef.current.textContent = formatShareTime(videoEl.currentTime);
      }
    };
    videoEl.addEventListener('timeupdate', onTimeUpdate);
    return () => videoEl.removeEventListener('timeupdate', onTimeUpdate);
  }, [videoEl]);

  const handleSelectChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const { id } = event.target;
      if (id === 'ccSizeSelect') {
        setCaptionFontSize(event.target.value as CaptionFontSize);
      }
      if (id === 'ccColorVariantSelect') {
        setCaptionColorVariant(event.target.value as CaptionColorVariant);
      }
    },
    [setCaptionColorVariant, setCaptionFontSize]
  );

  const handleOpenDevTools = useCallback(() => {
    setShowDevTools((prev) => !prev);
  }, []);

  return (
    <div className={debugToolsClassnames.container} onClick={(e) => e.stopPropagation()}>
      <div
        className={classNames(debugToolsClassnames.copiedMessage, {
          [debugToolsClassnames.showCopiedMessage]: copiedMessage,
        })}
      >
        {copiedMessage}
      </div>
      <button className={debugToolsClassnames.closeButton} type="button" onClick={onClose}>
        X
      </button>
      <button className={debugToolsClassnames.idInfoButton} type="button" onClick={handleCopyValue}>
        {id}
      </button>
      <div className={debugToolsClassnames.timeInfo}>
        <button
          className={debugToolsClassnames.timeButton}
          ref={timeButtonRef}
          title="Čas od začátku"
          type="button"
          onClick={handleCopyValue}
        >
          00:00
        </button>
        /
        <button
          className={debugToolsClassnames.timeButton}
          title="Celková stopáž"
          type="button"
          onClick={handleCopyValue}
        >
          {duration}
        </button>
      </div>
      <div className={debugToolsClassnames.devToolsContainer}>
        <div style={{ width: '100%', textAlign: 'right' }}>
          <button
            className={debugToolsClassnames.moreButton}
            type="button"
            onClick={handleOpenDevTools}
          >
            {showDevTools ? 'Skrýt' : 'Zobrazit další nástroje'}
          </button>
        </div>
        <hr />
        {showDevTools && (
          <div>
            <div>
              <div>
                <b>Titulky</b>
              </div>
              <div className={debugToolsClassnames.selectOptionsContainer}>
                <div className={debugToolsClassnames.selectOption}>
                  <label htmlFor="ccSizeSelect">Velikost písma</label>
                  <select id="ccSizeSelect" value={captionFontSize} onChange={handleSelectChange}>
                    {Object.values(CaptionFontSize).map((value) => (
                      <option key={value} value={value}>
                        {value}
                      </option>
                    ))}
                  </select>
                </div>
                <div className={debugToolsClassnames.selectOptionsContainer}>
                  <div className={debugToolsClassnames.selectOption}>
                    <label htmlFor="ccColorVariantSelect">Barevná varianta</label>
                    <select
                      id="ccColorVariantSelect"
                      value={captionColorVariant}
                      onChange={handleSelectChange}
                    >
                      {Object.values(CaptionColorVariant).map((value) => (
                        <option key={value} value={value}>
                          {value}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div>
              <div>
                <b>Audio only switch</b>
              </div>
              <div className={debugToolsClassnames.switchContainer}>
                <input
                  checked={forcedAudioOnly}
                  disabled={!useNewPlaylist}
                  id="audioOnlySwitch"
                  type="checkbox"
                  onChange={onForceAudioOnlySwitch}
                />
                {!useNewPlaylist && <i>pouze nove pl.</i>}
              </div>
            </div>
            <hr />
          </div>
        )}
      </div>
      {playerVersion && <div className={debugToolsClassnames.playerVersion}>v{playerVersion}</div>}
    </div>
  );
};
