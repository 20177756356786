import React, { memo } from 'react';
import { Spinner } from '@czechtv/components';
import { bufferingOverlayClassnames } from './BufferingOverlay.css';

const VODBufferingOverlay = () => {
  return (
    <div className={bufferingOverlayClassnames.spinnerWrapper}>
      <Spinner className={bufferingOverlayClassnames.spinner} />
    </div>
  );
};

export default memo(VODBufferingOverlay);
