import React from 'react';
import classNames from 'classnames';
import { CaptionsStyleButton } from '../../CaptionsStyle/CaptionsStyleButton/CaptionsStyleButton';
import SettingsListItem, {
  SettingsListItemProps,
  SettingsListItemsEnum,
} from './SettingsListItem/SettingsListItem';
import { settingsListClassnames } from './SettingsList.css';

interface SettingsListProps {
  isCaptionColor?: boolean;
  isCaptionSize?: boolean;
  isInRoot?: boolean;
  listContent: SettingsListItemProps;
  onGoBack?: Function;
  setActiveItem: (label: string) => void;
  setListContent: (listContent: SettingsListItemProps) => void;
  testId?: string;
}

export const SettingsList = ({
  listContent,
  isInRoot,
  isCaptionColor,
  isCaptionSize,
  setListContent,
  setActiveItem,
  testId,
  onGoBack = () => {},
}: SettingsListProps) => {
  const oneItemSettingsList = listContent.valuesList && listContent.valuesList.length < 2;
  const isCaptions = listContent.label === SettingsListItemsEnum.SUBTITLES;

  const onClickListItem = (key: string, selectable: boolean, switchable: boolean) => {
    const clickedItem = listContent.valuesList?.find((item) => item.key === key);
    const activeItem = listContent.valuesList?.find((item) => item.active === true);
    if (switchable) {
      clickedItem?.onSwitch && clickedItem.onSwitch();
      return;
    }
    if (selectable) {
      // selectni aktivni polozku
      if (clickedItem && activeItem) {
        listContent.onSelect && listContent.onSelect(clickedItem);
        onGoBack();
      }
      return;
    }
    if (!clickedItem) {
      return;
    }
    setActiveItem(clickedItem.key);
    setListContent(clickedItem);
  };

  return (
    <div className={settingsListClassnames.wrapper}>
      <ul
        className={classNames(
          settingsListClassnames.list,
          { isInRoot, isCaptionSize, isCaptionColor },
          oneItemSettingsList && settingsListClassnames.center
        )}
        data-testid={testId}
        role="menu"
      >
        {listContent.valuesList?.map((item) => {
          return (
            <SettingsListItem
              active={item.active}
              customClass={item.customClass}
              description={item.description}
              isCaptionColor={isCaptionColor}
              isCaptionSize={isCaptionSize}
              isCaptions={isCaptions}
              isInRoot={isInRoot}
              isQuality={listContent.label === SettingsListItemsEnum.QUALITY}
              itemKey={item.key}
              key={item.key}
              label={item.label}
              value={item.value}
              valuesList={item.valuesList}
              onClickListItem={onClickListItem}
            />
          );
        })}
      </ul>

      {isCaptions && (
        <CaptionsStyleButton
          listContent={listContent}
          setActiveItem={setActiveItem}
          setListContent={setListContent}
        />
      )}
    </div>
  );
};
