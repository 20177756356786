import classNames from 'classnames';
import React, { memo } from 'react';
import { IconCheckMark, IconChevronRight } from '@czechtv/icons';
import { Toggle, ToggleVariant } from '@czechtv/form';
import { useId } from '../../../../../../utils/useId';
import { settingsListItemClassnames } from './SettingsListItem.css';

export interface SettingsListItemProps {
  active?: boolean;
  customClass?: string;
  description?: string;
  isCaptionColor?: boolean;
  isCaptionSize?: boolean;
  isCaptions?: boolean;
  isInRoot?: boolean;
  isQuality?: boolean;
  itemKey?: string;
  key: string;
  label: string;
  onClickListItem?: (key: string, selectable: boolean, switchable: boolean) => void;
  onSelect?: (item: SettingsListItemProps) => void;
  onSwitch?: () => void;
  testId?: string;
  value?: boolean;
  valuesList?: SettingsListItemProps[];
}

export enum SettingsListItemsEnum {
  AUDIO = 'Zvuková stopa',
  AUDIO_ONLY = 'Pouze zvuk',
  PLAYBACK_RATE = 'Rychlost přehrávání',
  QUALITY = 'Kvalita',
  SIGN_LANGUAGE = 'Znakový jazyk',
  SUBTITLES = 'Titulky',
  SUBTITLES_STYLE = 'Vzhled titulků',
}

const SettingsListItem = ({
  label,
  active,
  onClickListItem,
  valuesList,
  isInRoot,
  value,
  isQuality,
  isCaptions,
  isCaptionSize,
  isCaptionColor,
  description,
  customClass,
  itemKey,
}: SettingsListItemProps) => {
  const selectable = !valuesList ? true : false;
  const switchable = !valuesList && typeof value !== 'undefined' ? true : false;
  const activeQuality = valuesList?.find((item) => item.active);
  const id = useId();

  const handleOnClick = () => {
    if (onClickListItem) {
      onClickListItem(itemKey || label, selectable, switchable);
    }
  };

  return (
    <li
      aria-haspopup={isInRoot}
      className={classNames(settingsListItemClassnames.listItem, {
        active,
        switchable,
        isInRoot,
        isCaptionSize,
        isCaptionColor,
      })}
      role="menuitem"
      onTouchMove={() => (document.activeElement as HTMLElement).blur()}
    >
      <button
        className={classNames(
          settingsListItemClassnames.button,
          {
            active,
            selectable,
            isInRoot,
            isQuality,
          },
          customClass
        )}
        type="button"
        onClick={handleOnClick}
      >
        {!isInRoot && (
          <span aria-checked={active} aria-labelledby={id} role="checkbox" tabIndex={-1}></span>
        )}
        {active && !isCaptionColor && !isCaptionSize && (
          <IconCheckMark aria-hidden="true" className={settingsListItemClassnames.selectedIcon} />
        )}

        {isQuality ? (
          <>
            <span className={settingsListItemClassnames.quality} id={id}>
              {label === 'Auto' ? label : description}
            </span>
            <span className={settingsListItemClassnames.qualityNumber} id={id}>
              {label === 'Auto' ? description : label}
            </span>
          </>
        ) : (
          <span id={id}>{label}</span>
        )}

        {valuesList && !isCaptions && (
          <span className={settingsListItemClassnames.selectedItem} id={id}>
            {valuesList.find((value) => value.active)?.label}
            {isInRoot && activeQuality && (
              <span className={settingsListItemClassnames.activeQuality}>
                {activeQuality.description}
              </span>
            )}
            <IconChevronRight
              className={settingsListItemClassnames.chevronRight}
            ></IconChevronRight>
          </span>
        )}
        {switchable && (
          <Toggle
            aria-checked={value}
            isChecked={value}
            name={label}
            variant={ToggleVariant.MENU_TOGGLE}
            onChange={() => {
              return;
            }}
          />
        )}
      </button>
    </li>
  );
};

export default memo(SettingsListItem);
