import React, { ForwardedRef, memo, RefObject, useMemo } from 'react';
import classNames from 'classnames';
import { videoClassnames } from './Video.css';

export interface VideoProps {
  hide?: boolean;
  muted?: boolean;
  nativeControls?: boolean;
  previewImage?: string;
  ref: RefObject<HTMLVideoElement>;
}

const LIVE_O2_PREVIEW_IMAGE_PARTIAL_PATH = 'categories/screenshot/1';

const Video = React.forwardRef(
  (
    { muted, nativeControls = false, previewImage, hide = false }: VideoProps,
    ref: ForwardedRef<HTMLVideoElement>
  ) => {
    // pro poster nepouzivame 'zivy' nahledovy obrazek z o2
    // vyhazuje to do konzole problem s CORS
    const poster = useMemo(
      () => (previewImage?.includes(LIVE_O2_PREVIEW_IMAGE_PARTIAL_PATH) ? undefined : previewImage),
      [previewImage]
    );

    return (
      <>
        <video
          playsInline
          className={classNames(videoClassnames.video, {
            [videoClassnames.hideControls]: !nativeControls,
            // na safari občas video element protekl přes z osu nad loading overlay
            [videoClassnames.hide]: hide,
          })}
          crossOrigin="anonymous"
          data-testid="video-tag"
          muted={!!muted}
          poster={poster}
          ref={ref}
        />
      </>
    );
  }
);

export default memo(Video);
