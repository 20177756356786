import { storageAvailable } from '@czechtv/utils';
import { CaptionColorVariant, CaptionFontSize } from '../utils/closedCaptions';
import { PlayerSubtitles } from '../Providers/Client';
import { PlayerAudioTrack } from '../utils/types';

export enum TimeDisplaySwitch {
  CURRENT_TIME = 'current',
  REMAINING_TIME = 'remaining',
}

export interface AdditionalPlayerConfig {
  captionColorVariant?: CaptionColorVariant;
  captionFontSize?: CaptionFontSize;
  liveCaptionsOn: boolean;
}

export interface PlayerSettings {
  additionalConfig?: AdditionalPlayerConfig;
  audioTrack: PlayerAudioTrack | null;
  muted: boolean;
  resolution: number | null;
  subtitles: PlayerSubtitles | null;
  subtitlesActive: boolean;
  timeDisplay?: TimeDisplaySwitch;
  volume: number;
}

export const DEFAULT_PLAYER_SETTINGS: PlayerSettings = {
  audioTrack: null,
  muted: false,
  resolution: null,
  subtitles: null,
  subtitlesActive: false,
  volume: 0.5,
  additionalConfig: {
    captionColorVariant: CaptionColorVariant.default,
    captionFontSize: CaptionFontSize.default,
    liveCaptionsOn: false,
  },
  timeDisplay: TimeDisplaySwitch.REMAINING_TIME,
};

export const PLAYER_SETTINGS_LOCAL_STORAGE_CACHE_KEY = `ct-vecko/player-settings`;

export const updateLocalStoragePlayerSettings = (updatedValues: Partial<PlayerSettings>) => {
  if (!storageAvailable('localStorage')) {
    return;
  }
  try {
    const currentlocalStorageSettings = localStorage.getItem(
      PLAYER_SETTINGS_LOCAL_STORAGE_CACHE_KEY
    );
    if (!currentlocalStorageSettings) {
      return;
    }
    const settings = JSON.parse(currentlocalStorageSettings) as PlayerSettings;
    const updatedSettings = {
      ...settings,
      ...updatedValues,
    };
    localStorage.setItem(PLAYER_SETTINGS_LOCAL_STORAGE_CACHE_KEY, JSON.stringify(updatedSettings));
  } catch {
    // nedelame nic
  }
};

export const getLocalStoragePlayerSettings = (): PlayerSettings | null => {
  if (!storageAvailable('localStorage')) {
    return null;
  }
  try {
    const currentlocalStorageSettings = localStorage.getItem(
      PLAYER_SETTINGS_LOCAL_STORAGE_CACHE_KEY
    );
    if (!currentlocalStorageSettings) {
      return null;
    }
    return JSON.parse(currentlocalStorageSettings) as PlayerSettings;
  } catch (e) {
    return null;
  }
};
