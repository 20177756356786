import React, { HTMLAttributes } from 'react';
import classNames from 'classnames';
import {
  getThumbnailPositionInImage,
  getThumbnailLeftPosition,
  getThumbnailTopPosition,
} from './thumbnail.helper';
import { thumbnailClassnames } from './Thumbnail.css';

// Komponenta najde v sadě náhledových obrázků ten správný na základě časového razítka

interface Props extends HTMLAttributes<HTMLDivElement> {
  height: number;
  thumbnailsUrl: string;
  timestamp: number;
  width: number;
}

export const ThumbnailImg = ({
  thumbnailsUrl,
  timestamp,
  width,
  height,
  className,
  ...rest
}: Props) => {
  const positionInImage = getThumbnailPositionInImage(timestamp);
  const positionLeft = getThumbnailLeftPosition(positionInImage, width);
  const positionTop = getThumbnailTopPosition(positionInImage, height);

  return (
    <div
      className={classNames(className, thumbnailClassnames.image)}
      data-testid="thumbnailImage"
      style={{
        width: `${width}px`,
        height: `${height}px`,
        backgroundImage: `url('${thumbnailsUrl}')`,
        backgroundPosition: `-${positionLeft}px -${positionTop}px`,
        backgroundSize: `${width * 10}px ${height * 10}px`,
      }}
      {...rest}
    />
  );
};
