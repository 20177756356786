/* eslint-disable max-lines */
/* eslint-disable typescript-sort-keys/string-enum */
// abychom mohli zobrazovat na časové ose live i timeshift, musíme znát jejich délku
import { Dispatch, SetStateAction } from 'react';
import { Encoder } from '@czechtv/utils';
import { NielsenAdPayload } from '@czechtv/analytics';
import { AdsConfig } from './Providers/Client';

export const TIMESHIFT_IN_SECONDS = 60 * 60 * 3;
export const TIMESHIFT_GAP_DURATION = 80;
export const LIVE_STREAM_DURATION = 80;
export const NO_GAP_LIVE_TIMESHIFT_BUFFER = TIMESHIFT_GAP_DURATION + LIVE_STREAM_DURATION;

export const BROADCAST_LATENCY_IN_MS = 8200;
export const FAQ_URL = 'https://www.ceskatelevize.cz/vse-o-ct/caste-dotazy/ivysilani/';

export const LIVE_EDGE_ALLOWED_DELAY = 10;
export const TIMESHIFT_END_OFFSET = 20;
export const TIMESHIFT_CONTROLS_END_OFFSET = 15;
export const FULL_LIVE_STREAM_LENGTH = TIMESHIFT_IN_SECONDS + LIVE_STREAM_DURATION;
export const PREFERRED_LANGUAGE = 'cs';
export const INDEX_LOOKUP_THROTTLE = 100;
export const VOLUME_CHANGE_EVENT_THROTTLE = 2000;
export const DEFAULT_STARTUP_REFETCH_PLAYLIST_TIMEOUT = 300000; // 5 min
export const SEEKING_THROTTLE = 700;
export const END_CREDITS_MAX_IN_SECONDS = 120;
export const RETURN_TO_LIVE_AFTER_PAUSE_IN_MS = 30 * 60 * 1000; // 30 min
// webm (+mov conatiner) zpusobuji zamrznuti obrazu v native (webm v Safari podporovana od 02/2021)
// povolene typy reflektuji, ze hrajeme testovaci reklamu a Safari 14.1 a Ima SDK 3.461.0
export const ALLOWED_AD_MIME_TYPES = ['video/mp4', 'video/m4v', 'video/wmv'];

export const getTimeshiftFromTimeline = (percentage: number, fullLiveStreamLength?: number) => {
  return ((fullLiveStreamLength || FULL_LIVE_STREAM_LENGTH) * (100 - percentage)) / 100;
};

export const CT_CHROMECAST_RECEIVER = '802343E8';

// language code 'nar' = audio description (narration)
export const LANG_CODE_AD = 'nar';

export const LOCAL_STORAGE_GLOBAL_PREFIX = 'ct-global';
interface AudioMessage {
  defaultMessage: string;
  defaultMobileMessage?: string;
}

interface AudioMessages {
  [language: string]: AudioMessage | undefined;
  audioBlind: AudioMessage;
  audioOriginal: AudioMessage;
}

export const AUDIO_MESSAGES: AudioMessages = {
  cs: {
    defaultMessage: 'Česky',
    defaultMobileMessage: 'CS',
  },
  sk: {
    defaultMessage: 'Slovensky',
    defaultMobileMessage: 'SK',
  },
  en: {
    defaultMessage: 'English',
    defaultMobileMessage: 'EN',
  },
  pl: {
    defaultMessage: 'Polish',
    defaultMobileMessage: 'PL',
  },
  uk: {
    defaultMessage: 'Ukrainian',
    defaultMobileMessage: 'UK',
  },
  de: {
    defaultMessage: 'German',
    defaultMobileMessage: 'DE',
  },
  fr: {
    defaultMessage: 'French',
    defaultMobileMessage: 'FR',
  },
  fi: {
    defaultMessage: 'Finnish',
    defaultMobileMessage: 'FI',
  },
  lv: {
    defaultMessage: 'Latvian',
    defaultMobileMessage: 'LV',
  },
  lt: {
    defaultMessage: 'Lithuanian',
    defaultMobileMessage: 'LT',
  },
  es: {
    defaultMessage: 'Spanish',
    defaultMobileMessage: 'ES',
  },
  nl: {
    defaultMessage: 'Dutch',
    defaultMobileMessage: 'NL',
  },
  sv: {
    defaultMessage: 'Swedish',
    defaultMobileMessage: 'SV',
  },
  no: {
    defaultMessage: 'Norwegian',
    defaultMobileMessage: 'NO',
  },
  da: {
    defaultMessage: 'Danish',
    defaultMobileMessage: 'DA',
  },
  pt: {
    defaultMessage: 'Portuguese',
    defaultMobileMessage: 'PT',
  },
  el: {
    defaultMessage: 'Greek',
    defaultMobileMessage: 'EL',
  },
  hu: {
    defaultMessage: 'Hungarian',
    defaultMobileMessage: 'HU',
  },
  ro: {
    defaultMessage: 'Romanian',
    defaultMobileMessage: 'RO',
  },
  sl: {
    defaultMessage: 'Slovenian',
    defaultMobileMessage: 'SL',
  },
  et: {
    defaultMessage: 'Estonian',
    defaultMobileMessage: 'ET',
  },
  bg: {
    defaultMessage: 'Bulgarian',
    defaultMobileMessage: 'BG',
  },
  hr: {
    defaultMessage: 'Croatian',
    defaultMobileMessage: 'HR',
  },
  sr: {
    defaultMessage: 'Serbian',
    defaultMobileMessage: 'SR',
  },
  mt: {
    defaultMessage: 'Maltese',
    defaultMobileMessage: 'MT',
  },
  ga: {
    defaultMessage: 'Irish',
    defaultMobileMessage: 'GA',
  },
  cy: {
    defaultMessage: 'Welsh',
    defaultMobileMessage: 'CY',
  },
  is: {
    defaultMessage: 'Icelandic',
    defaultMobileMessage: 'IS',
  },
  be: {
    defaultMessage: 'Belarusian',
    defaultMobileMessage: 'BE',
  },
  sq: {
    defaultMessage: 'Albanian',
    defaultMobileMessage: 'SQ',
  },
  hy: {
    defaultMessage: 'Armenian',
    defaultMobileMessage: 'HY',
  },
  ka: {
    defaultMessage: 'Georgian',
    defaultMobileMessage: 'KA',
  },
  mk: {
    defaultMessage: 'Macedonian',
    defaultMobileMessage: 'MK',
  },
  bs: {
    defaultMessage: 'Bosnian',
    defaultMobileMessage: 'BS',
  },
  zh: {
    defaultMessage: 'Chinese',
    defaultMobileMessage: 'ZH',
  },
  ja: {
    defaultMessage: 'Japanese',
    defaultMobileMessage: 'JA',
  },
  ko: {
    defaultMessage: 'Korean',
    defaultMobileMessage: 'KO',
  },
  ar: {
    defaultMessage: 'Arabic',
    defaultMobileMessage: 'AR',
  },
  hi: {
    defaultMessage: 'Hindi',
    defaultMobileMessage: 'HI',
  },
  ur: {
    defaultMessage: 'Urdu',
    defaultMobileMessage: 'UR',
  },
  id: {
    defaultMessage: 'Indonesian',
    defaultMobileMessage: 'ID',
  },
  th: {
    defaultMessage: 'Thai',
    defaultMobileMessage: 'TH',
  },
  vi: {
    defaultMessage: 'Vietnamese',
    defaultMobileMessage: 'VI',
  },
  tr: {
    defaultMessage: 'Turkish',
    defaultMobileMessage: 'TR',
  },
  ru: {
    defaultMessage: 'Russian',
    defaultMobileMessage: 'RU',
  },
  audioOriginal: {
    defaultMessage: 'Původní znění',
    defaultMobileMessage: 'OV',
  },
  audioBlind: {
    defaultMessage: 'Pro nevidomé',
    defaultMobileMessage: 'AD',
  },
};

interface SettingMessages {
  [language: string]: {
    defaultMessage: string;
  };
}

export const SETTINGS_MESSAGES: SettingMessages = {
  settingsTitleAriaLabel: {
    defaultMessage: 'Nastavení přehrávače',
  },
  settingsClosedCaptionsTitle: {
    defaultMessage: 'Titulky',
  },
  settingsClosedCaptionsActionLabel: {
    defaultMessage: 'Vyberte titulky',
  },
  settingsSoundTrackTitle: {
    defaultMessage: 'Zvuková stopa',
  },
  settingsSoundTrackTitleShort: {
    defaultMessage: 'Zvuk',
  },
  settingsPlaybackRateTitle: {
    defaultMessage: 'Rychlost',
  },
  settingsSoundActionLabel: {
    defaultMessage: 'Vyberte zvukovou stopu',
  },
  settingsPlaybackRateActionLabel: {
    defaultMessage: 'Vyberte rychlost přehrávání',
  },
  settingsCloseButtonAriaLabel: {
    defaultMessage: 'Zavřít nastavení',
  },
  settingsGoBackButtonAriaLabel: {
    defaultMessage: 'Zpět',
  },
};

interface SettingOtherMessages {
  [key: string]: {
    defaultMessage: string;
    defaultMobileMessage?: string;
  };
}

export const SETTINGS_OTHER_MESSAGES: SettingOtherMessages = {
  reportError: {
    defaultMessage: 'Nahlásit chybu',
  },
  disabledTextTrackItem: {
    defaultMessage: 'Vypnuto',
    defaultMobileMessage: 'Vyp.',
  },
  disabledTextTrackItemLabel: {
    defaultMessage: 'Vypnuto',
    defaultMobileMessage: 'Vyp.',
  },
};

export enum PlayerMode {
  ads = 'ads',
  content = 'content',
}

export enum ScreenMode {
  FULLSCREEN = 'fullscreen',
  NORMAL = 'normal',
  PICTURE_IN_PICTURE = 'picture-in-picture',
}

export interface RefetchPlaylistController {
  setShouldRefetchPlaylist: (value: boolean) => void;
  shouldRefetchPlaylist: boolean;
}

export enum PlayerVariantEnum {
  LIVE = 'live',
  VOD = 'VOD',
}

export enum PlayerTypeEnum {
  DASH = 'DASH',
  HLS = 'HLS',
}

export interface VideoLoadOptions {
  dontResetPlaytime?: boolean;
  hasExternalSubtitles: boolean;
  onPlayNextStream: () => Promise<void>;
  resolution: number | null;
  selectStream?: 'audioDescription' | 'main';
  startTime?: number;
}

export const BUTTON_SEPARATOR = '%';

export type BufferingEvent = Event & { buffering: boolean };

// verze 0.0.0 se objeví např. v testech nebo ve storybook,
// tj. pokud balíček není builděný přes rollup
export const PLAYER_VERSION = process.env.VERSION || '0.0.0';

export type IdInputsVOD = {
  // Externi ID videa (IDEC - 12345, bonus - BO-12345)
  externalId?: string;
  // ID indexu
  indexId?: string;
  // Interni mmId videa
  mediaMetaId?: string;
  // ID verze
  versionId?: string;
};

export interface AdMetadata {
  // Platforma na ktere je player integrovan
  platform: 'web' | 'iosApp' | 'androidApp' | 'smartTV' | 'hbbtv';
  // Produkt na kterem je player integrovan
  product?: string;
  // ID sezony
  seasonId?: string;
  // ID poradu
  showId?: string;
  // Stitky pro cileni reklam
  tags?: string;
}

export enum Product {
  artzona = 'ct-artzona',
  ct24 = 'ct-zpravodajstvi',
  ct4 = 'ct-sport',
  ctdefault = 'ct-default',
  decko = 'ct-decko',
  edu = 'ct-edu',
  vecko = 'ct-vecko',
  vecko_admin = 'ct-vecko-admin',
}

export enum Origin {
  artzona = 'artzona',
  ct24 = 'ct24',
  ct4 = 'ct4',
  ctedu = 'ctedu',
  decko = 'decko',
  ivysilani = 'ivysilani',
}

export enum StreamQuality {
  '1080p' = '1080p',
  '144p1' = '144p1',
  '144p2' = '144p2',
  '288p' = '288p',
  '404p' = '404p',
  '576p' = '576p',
  '720p' = '720p',
  ad = 'ad',
  audio = 'audio',
}

export const createUniqueVideoId = (ids: IdInputsVOD): string => {
  return `${ids.mediaMetaId || ids.externalId}${ids.versionId || ''}${ids.indexId || ''}`;
};

export type GetVideoUrlResponse = {
  adsConfig?: AdsConfig;
  fairplayLicenseCertificateUrl?: string | null;
  fairplayLicenseServerUrl?: string | null;
  videoId?: string;
  videoUrl: StreamUrl[];
  widevineLicenseServerUrl?: string | null;
};

export enum PlayerStreamType {
  // reklama prehratelna jako VOD polozka
  ad = 'ad',
  // stara reklama z jednotlivych vastu - po poptani se daji jeji data transformovat v
  // PlayerStreamType.ad
  adSource = 'adSource',
  // useNewAds === true: placeholder pro postrolly, ktere se poptavaji az po dohrani hlavniho obsahu
  // tento typ potrebujeme, abychom mohli ve fronte nechat misto pro postroll/y, ktere poptame
  // z playeru, az kdyz se k takovemu obsahu dostaneme - pokud se nepodari placeholder promenit
  // v reklamu a do playeru se zase vrati adPlaceholder, tak prehravani skoncilo
  adPlaceholder = 'adPlaceholder',
  main = 'main',
}

export enum PlayerAdType {
  postRoll = 'postRoll',
  preRoll = 'preRoll',
}

export enum TrackingType {
  complete = 'complete',
  firstQuartile = 'firstQuartile',
  firstSecond = 'firstSecond',
  midpoint = 'midpoint',
  start = 'start',
  thirdQuartile = 'thirdQuartile',
}
export interface AdTracking {
  type: TrackingType;
  url: string;
}

export interface AdImpression {
  url: string | null;
}

export type AdTypeDetail = 'ad' | 'self-promo' | 'labeling';
export interface PlayerStreamAdMeta {
  asmea: string | null;
  clickUrl: string | null;
  duration: number | null;
  impressions: AdImpression[];
  nielsen: NielsenAdPayload;
  nielsenType: string;
  position: number;
  skipOffset: number | null;
  title: string | null;
  tracking: AdTracking[];
  type: PlayerAdType;
  typeDetail: AdTypeDetail;
}
export interface ParsedAd extends PlayerStreamAdMeta {
  dashUrl: string;
  hlsUrl: string;
}

export type PlayerDRMSettings =
  | {
      fairplayLicenseCertificateUrl: string;
      fairplayLicenseServerUrl: string;
    }
  | { widevineLicenseServerUrl: string };

export type PlayerStream = {
  audioDescription?: string | null;
  drm?: PlayerDRMSettings;
  id: string;
  // pro vicenasobny playlist urcuje index pole se streamy
  index: number;
  promoReport?: PromoReport;
  timeshift?: string;
  url: string;
} & (
  | {
      category: PlayerAdType;
      meta: PlayerStreamAdMeta;
      type: PlayerStreamType.ad;
    }
  | {
      category: PlayerAdType;
      type: PlayerStreamType.adSource;
    }
  | {
      category: PlayerAdType;
      type: PlayerStreamType.adPlaceholder;
    }
  | {
      category: 'full' | 'index' | 'live';
      endOffset?: number;
      startOffset?: number;
      type: PlayerStreamType.main;
    }
);

export interface CachedAds {
  parsed: PlayerStream[];
  source?: string;
  wasParsed: boolean;
}

export type Queue = {
  current: PlayerStream;
  items: PlayerStream[];
  length: number;
  position: number;
};

export interface GetVideoUrlOptions {
  refetch?: boolean;
  type?: 'live' | 'timeshift';
}

export type GetVideoUrl = (options?: GetVideoUrlOptions) => Promise<GetVideoUrlResponse>;

export interface StreamUrl {
  audioDescription?: string | null;
  isLabeling?: boolean;
  main: string;
  timeshift?: string;
}

export type VODStreamSource = {
  adsConfig?: AdsConfig;
  // Podepsane URL VOD streamu
  streamUrls: StreamUrl[];
} & (
  | {
      // Url na Widevine licencni server
      widevineLicenseServerUrl?: string | null;
    }
  // Url na Fairplay licencni server a certifikat
  | { fairplayLicenseCertificateUrl?: string | null; fairplayLicenseServerUrl?: string | null }
);
export type LiveStreamSource = {
  // Enkoder, na kterem probiha zive vysilani (pro ucely analytiky)
  liveStreamEncoder: Encoder;
  // Podepsane URL live streamu
  liveStreamUrl: string;
  // Podepsane URL timeshift streamu
  timeshiftStreamUrl: string;
} & (
  | {
      // Url na Widevine licencni server
      widevineLicenseServerUrl?: string | null;
    }
  // Url na Fairplay licencni server a certifikat
  | { fairplayLicenseCertificateUrl?: string | null; fairplayLicenseServerUrl?: string | null }
);

export interface PromoReport {
  timeout: number;
  url: string;
}

export interface VideoSubtitles {
  code: string;
  title: string | null;
  url: string;
}

export interface VideoIndex {
  imageUrl: string | null;
  indexId: string;
  startTime: number;
  stopTime: number;
  title: string | null;
}

export interface ProgressTracker {
  category: PlayerAdType | 'full' | 'index' | 'live';
  clickedThrough?: boolean;
  currentTime: number;
  duration: number;
  impressions: AdImpression[] | null;
  isAd: boolean;
  playTime: number;
  promoReport?: PromoReport;
  skipOffset: number | null;
  skipped?: boolean;
  tracking: AdTracking[] | null;
  trackingEventsFired: string[];
  type: PlayerStreamType;
}

// Veskere mozne kombinace identifikatoru videa
export type LoadProps = IdInputsVOD | { encoder: Encoder } | VODStreamSource | LiveStreamSource;

export enum Keys {
  SPACEBAR = 'Space',
  ENTER = 'Enter',
  F = 'KeyF', // Fullscreen
  C = 'KeyC', // Titulky
  ESC = 'Escape', // Escape Fullscreen
  M = 'KeyM', // Mute/unmute
  K = 'KeyK', // play/pause
  J = 'KeyJ', // seek back
  L = 'KeyL', // seek forward
  H = 'KeyH', // hide controls
  ARROW_LEFT = 'ArrowLeft',
  ARROW_RIGHT = 'ArrowRight',
  ARROW_UP = 'ArrowUp',
  ARROW_DOWN = 'ArrowDown',
  LESS = 'Comma',
  GREATER = 'Period',
  NUM_1 = 'Digit1',
  NUM_2 = 'Digit2',
  NUM_3 = 'Digit3',
  NUM_4 = 'Digit4',
  NUM_5 = 'Digit5',
  NUM_6 = 'Digit6',
  NUM_7 = 'Digit7',
  NUM_8 = 'Digit8',
  NUM_9 = 'Digit9',
  NUM_0 = 'Digit10',
  A = 'KeyA', // zapinani audio description
  PLAY_PAUSE = 'Pause', // play/pause media klavesa
  I = 'KeyI', // debug mode pro indexatory, zatim jen ukazuje cas
  TAB = 'Tab',
  SHIFT = 'shiftKey',
}

export enum AutoplayCapability {
  UNKNOWN = 'UNKNOWN',
  TESTING = 'TESTING',
  MUTED = 'MUTED',
  NONE = 'NONE',
  FULL = 'FULL',
}

export interface Debugging {
  playerVersion?: string;
  streamPausedTimeout?: number;
  streamUrlExpiredTimeout?: number;
}

export interface HbbtvDevice {
  id: string;
  name: string;
  state: string;
  type?: string;
}

export interface ChromecastDevice {
  capabilities?: string[];
  displayStatus: null;
  friendlyName: string;
  isActiveInput?: boolean;
  label: string;
  receiverType: string;
  volume?: {
    controlType: string;
    level?: number;
    muted?: boolean;
    stepInterval: number;
  };
}

export enum MenuPopupType {
  CAST = 'cast',
  SETTINGS = 'settings',
}

export enum HbbtvState {
  newConnection = 'newConnection',
  noConnection = 'noConnection',
  removeConnection = 'removeConnection',
  selectConnection = 'selectConnection',
}

export interface HbbtvConnect {
  castError: boolean;
  connectionError: boolean;
  deviceToRemove: HbbtvDevice | undefined;
  hbbtvState: HbbtvState | undefined;
  onAddTvButtonClick: () => void;
  onBackClick: () => void;
  onCloseButtonClick: () => void;
  onConfirmDeleteItemButtonClick: () => void;
  onCreateTvButtonClick: () => void;
  onDeleteItem: (device: HbbtvDevice) => void;
  onKeepTvButtonClick: () => void;
  onSelectItem: (device: HbbtvDevice, onHbbtvDeviceSelect: () => void) => Promise<void>;
  pairedDevices: HbbtvDevice[];
  setPairingId: Dispatch<SetStateAction<string>>;
}

export const AD_OCEAN_URL = 'https://cz.adocean.pl/ad.xml';

export enum LiveMode {
  live = 0,
  liveAsVod = 1,
  liveWithStartDefined = 2,
  liveWithStartAndEndDefined = 3,
}

export enum LiveStreamEndReason {
  streamEndedDuringPlayback = 'streamEndedDuringPlayback',
  streamEndedBeforeLoad = 'streamEndedBeforeLoad',
}

export type UserVideoProgressMeta = {
  idec: string;
  sidp: string;
  userVideoProgressReportingUrl: string;
};

export interface UserVideoProgressPayload {
  deviceId: string;
  finished: boolean;
  idec: string;
  progress: number;
  sidp: string;
  userId: string;
}

export const MEDIA_ERR_SRC_NOT_SUPPORTED = 4;

export const MANIFEST_DEFAULT_SUBTILES_TAG = 'MANIFEST_DEFAULT_SUBTILES_TAG';

export enum SupportedPlayerMethodMessage {
  destroy = 'destroy',
  getIsMuted = 'getIsMuted',
  getVolume = 'getVolume',
  pause = 'pause',
  play = 'play',
  seek = 'seek',
  setVolume = 'setVolume',
  stop = 'stop',
  toggleFullscreen = 'toggleFullscreen',
  toggleMute = 'toggleMute',
}

interface LiveStreamUrls {
  main: string;
  timeshift: string;
}

export interface ExternalLiveStreams {
  dash: {
    drm: LiveStreamUrls;
    noDrm: LiveStreamUrls;
  };
  hls: {
    drm: LiveStreamUrls;
    noDrm: LiveStreamUrls;
  };
  previewImageUrl?: string;
  thumbnailsUrl?: string;
  title?: string;
}

export interface ExternalVastConfig {
  adOceanPostrollId1: string;
  adOceanPrerollId1: string;
  adOceanPrerollId2: string;
}
