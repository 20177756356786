import React, { memo, useState, useEffect, useCallback, useRef } from 'react';
import classNames from 'classnames';
import {
  getClosedCaptionFontSize,
  CaptionFontSize,
  getClosedCaptionColorVariant,
} from '../../utils/closedCaptions';
import { hasWebkitFullscreen } from '../../utils/screenMode';
import { usePlayerContext } from '../../Player/PlayerContext';
import { ScreenMode } from '../../constants';
import { closedCaptionsClassnames } from './ClosedCaptions.css';

interface ShouldUpdateTextState {
  currentActiveCueText: string;
  isLivePlayer: boolean;
  previousCue?: VTTCue;
  textTrack: TextTrack | undefined;
}

// hack kvuli duplicitam v live titulcich, ktere zpusobuji problikavani titulku
const shouldUpdateTextState = ({
  textTrack,
  currentActiveCueText,
  previousCue,
  isLivePlayer,
}: ShouldUpdateTextState) => {
  if (!isLivePlayer) {
    return true;
  }
  // pokud aktivni cue je prazdna == skoncil titulek
  if (currentActiveCueText === '') {
    const cues = Array.from(textTrack?.cues || []) as VTTCue[];
    // najdeme ve fronte predchozi cue
    const indexOfCueWithPreviousStartTime = cues.findIndex(
      (cue) => cue.startTime === previousCue?.startTime
    );
    const nextCueText = cues[indexOfCueWithPreviousStartTime + 1]?.text;
    // porovname text nasledujici cue
    if (nextCueText === previousCue?.text) {
      return false;
    }
  }
  return true;
};

interface ClosedCaptionsProps {
  controlsVisible: boolean;
  isLivePlayer?: boolean;
  playerContainer: HTMLDivElement;
  screenMode: ScreenMode;
  textTrack?: TextTrack;
  videoElement: HTMLVideoElement;
  visible?: boolean;
}

export const ClosedCaptions = memo(
  ({
    controlsVisible,
    visible = true,
    textTrack: playerTextTrack,
    playerContainer,
    videoElement,
    screenMode,
    isLivePlayer,
  }: ClosedCaptionsProps) => {
    const { captionColorVariant, captionFontSize } = usePlayerContext();
    const [fontSize, setFontSize] = useState(
      getClosedCaptionFontSize(videoElement, CaptionFontSize.default)
    );
    const { text, background, textShadow } = getClosedCaptionColorVariant(captionColorVariant);
    const playerHeight = document.getElementById('player-provider')?.clientHeight || 0;
    const playerWidth = document.getElementById('player-provider')?.clientWidth || 0;
    const captionsPosition = playerHeight ? playerHeight / 2 : 0;
    const [cueText, setCueText] = useState('');
    const textTrack = playerTextTrack;
    const previousCueRef = useRef<VTTCue | undefined>();

    const [orientation, setOrientation] = useState(window.screen.orientation.type);

    useEffect(() => {
      const handleOrientationChange = () => {
        setOrientation(window.screen.orientation.type);
      };

      window.screen.orientation.addEventListener('change', handleOrientationChange);

      return () => {
        window.screen.orientation.removeEventListener('change', handleOrientationChange);
      };
    }, []);

    const setTextFromActiveCue = useCallback(() => {
      const cue = textTrack?.activeCues?.[0] as VTTCue | undefined;
      const currentActiveCueText = cue?.text || '';
      const shouldUpdate = shouldUpdateTextState({
        isLivePlayer: !!isLivePlayer,
        textTrack,
        currentActiveCueText,
        previousCue: previousCueRef.current,
      });

      if (shouldUpdate) {
        setCueText(currentActiveCueText);
      }
      // do promenne si musime ukladat pouze aktivni cue, ktere nejsou "pauzy" - tzn. maji nejaky text
      if (currentActiveCueText) {
        previousCueRef.current = cue;
      }
    }, [textTrack, isLivePlayer]);

    const onCueChange = useCallback(() => {
      setTextFromActiveCue();
    }, [setTextFromActiveCue]);

    useEffect(() => {
      setTextFromActiveCue();
    }, [setTextFromActiveCue]);

    useEffect(() => {
      const fontSize = getClosedCaptionFontSize(videoElement, captionFontSize);
      setFontSize(fontSize);
    }, [captionFontSize, videoElement]);

    useEffect(() => {
      if (!textTrack) {
        return () => {};
      }

      textTrack.mode = 'hidden';
      textTrack.addEventListener('cuechange', onCueChange);

      return () => {
        textTrack.removeEventListener('cuechange', onCueChange);
      };
    }, [onCueChange, textTrack]);

    useEffect(() => {
      let timeout: ReturnType<typeof setTimeout> | null = null;

      const onResize = () => {
        const fontSize = getClosedCaptionFontSize(videoElement, captionFontSize);
        setFontSize(fontSize);
      };

      // pouzivame po prechodu do fullscreenu - resize se obcas odpali v jinou chvili
      const onDelayedResize = () => {
        if (timeout) {
          clearTimeout(timeout);
        }
        timeout = setTimeout(() => {
          const fontSize = getClosedCaptionFontSize(videoElement, captionFontSize);
          setFontSize(fontSize);
        }, 200);
      };

      window.addEventListener('resize', onResize);
      if (playerContainer) {
        /* Kvuli prepinani do fullscreenu je dulezite rozlisovat pouziti spravneho listeneru,
           jinak se stane to, ze pri zapnutych titulcich se nejde z fullscreenu vratit.
           Zda se, ze subscribe na 'fullscreenchange' zde v titulcich rozbije odpalovani event pomoci
           'webkitfullscreenchange' na miste, kde se pracuje s prepinanim do/z fullscreenu */
        playerContainer.addEventListener(
          hasWebkitFullscreen(playerContainer) ? 'webkitfullscreenchange' : 'fullscreenchange',
          onDelayedResize
        );
      }
      return () => {
        window.removeEventListener('resize', onResize);
        if (playerContainer) {
          playerContainer.removeEventListener(
            hasWebkitFullscreen(playerContainer) ? 'webkitfullscreenchange' : 'fullscreenchange',
            onDelayedResize
          );
        }
        if (timeout) {
          clearTimeout(timeout);
        }
      };
    }, [captionFontSize, playerContainer, videoElement]);

    if (!cueText || !visible) {
      return null;
    }

    const closedCaptionsContainerStyle = (orientation: string) => {
      if (orientation.includes('portrait') && screenMode === ScreenMode.FULLSCREEN) {
        return { bottom: `calc(50% - ${captionsPosition}px)` };
      }
      if (screenMode === ScreenMode.FULLSCREEN) {
        return { transition: 'none' };
      }
    };

    const bottomCaptionsPosition = () => {
      if (playerWidth < 479) {
        return { bottom: controlsVisible ? '48px' : '12px' };
      }
      return { bottom: controlsVisible ? '72px' : '24px' };
    };

    return (
      <div
        className={classNames(
          closedCaptionsClassnames.closedCaptionsContainer,
          screenMode === ScreenMode.FULLSCREEN && 'fullscreen'
        )}
        data-testid="playerClosedCaptionsContainer"
        style={{
          ...bottomCaptionsPosition(),
          ...closedCaptionsContainerStyle(orientation),
        }}
      >
        <div
          className={closedCaptionsClassnames.text}
          style={{
            background: background,
            color: text,
            textShadow: textShadow,
            fontSize: fontSize,
          }}
        >
          {cueText}
        </div>
      </div>
    );
  }
);
