import React, {
  KeyboardEvent,
  MouseEvent,
  ReactNode,
  RefObject,
  SyntheticEvent,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import classNames from 'classnames';
import throttle from 'lodash/throttle';
import { useMobileDevice, BlackOpacity50 } from '@czechtv/styles';
import { IconPause } from '@czechtv/icons';
import { usePlayerSetup } from '../../../Providers/Setup/usePlayerSetup';
import { PlayerMode, PlayerVariantEnum, ScreenMode } from '../../../constants';
import PlayerClientState from '../../../Providers/Client/state';
import { usePlayerContext } from '../../PlayerContext';
import VideoIconButton from '../../../components/VideoIconButton/VideoIconButton';
import { useMediaBreakpoints } from '../../../utils/playerResponsive';
import { usePlayerPopupContext } from '../../../components/PlayerPopup/PlayerPopup';
import { OverlayWrapper } from '../OverlayWrapper/OverlayWrapper';
import { AdsOverlay } from '../AdsOverlay/AdsOverlay';
import { formatMessage } from '../../../utils/formatMessage';
import { ChromecastState } from '../../../utils/chromecast/useChromecast';
import { VODLoadingOverlay } from './LoadingOverlay/LoadingOverlay';
import VODFinishedOverlay from './FinishedOverlay';
import VODBufferingOverlay from './BufferingOverlay/BufferingOverlay';
import OverlayIcon from './Icon/Icon';
import Scrim from './Scrim/Scrim';
import { ReadyOverlay } from './ReadyOverlay/ReadyOverlay';
import { AudioOnlyOverlay } from './AudioOnlyOverlay/AudioOnlyOverlay';
import { vodClassnames } from './VOD.css';

export type OverlayHalf = 'left' | 'right';

interface OverlayProps {
  adClicked?: boolean;
  children: ReactNode | ReactNode[];
  controlsVisible?: boolean;
  currentProgress?: number;
  duration?: number;
  onAdButtonClick?: () => void;
  onClick?: (e: MouseEvent<HTMLDivElement>) => void;
  onDoubleClick?: (
    e: MouseEvent<HTMLDivElement>,
    overlayHalf: OverlayHalf,
    isMobileDevice: boolean
  ) => void;
  onKeyPressed?: (event: KeyboardEvent<HTMLDivElement>) => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  onMouseMove?: () => void;
  onPlayButtonPressed?: () => void;
  onReplayButtonPressed: () => void;
  onSkipAd?: () => void;
  // kvůli detekci doublekliku se onTap handluje z click eventu
  onTap?: () => void;
  overlayRef?: RefObject<HTMLDivElement>;
  playerMode?: PlayerMode;
  playerState: PlayerClientState;
  previewImage?: string;
  screenMode: ScreenMode;
  showAdButton?: boolean;
  showSimpleVideoHeader?: boolean;
  skipAdInSeconds?: number;
}

export const OVERLAY_DOUBLE_CLICK_TIMEOUT = 200;

const Overlay = ({
  skipAdInSeconds,
  showSimpleVideoHeader,
  children,
  controlsVisible = false,
  onClick = () => {},
  onDoubleClick = () => {},
  onKeyPressed: onKeyPressedCallback,
  onReplayButtonPressed,
  // duration,
  overlayRef,
  playerMode,
  playerState,
  previewImage,
  screenMode,
  onPlayButtonPressed,
  currentProgress,
  onMouseMove = () => {},
  onMouseLeave = () => {},
  onTap = () => {},
  onMouseEnter = () => {},
  onSkipAd = () => {},
  onAdButtonClick = () => {},
  showAdButton,
}: OverlayProps) => {
  const {
    ageRestriction,
    showTitle,
    videoTitle,
    indexes,
    isAudioOnly: isAudioOnlyProp,
    overlay,
    licenseInfo,
    setIndexListVisible,
    indexListVisible,
    menuPopupVisible,
    hbbtvStreamingActiveDevice,
    activeChromecastDevice,
    chromecastState,
    forcedAudioOnly,
  } = usePlayerContext();
  const isVideoStart = playerState === PlayerClientState.READY;
  const popupContext = usePlayerPopupContext();
  const hasOpenPopup = popupContext?.hasOpenPopup;
  const { playerVariant } = usePlayerSetup();
  const { isMaxTouchMediumMobile } = useMediaBreakpoints();

  const isAudioOnly = isAudioOnlyProp || forcedAudioOnly;

  const finishedOverlayRef: RefObject<HTMLDivElement> = React.createRef();

  const isOverlayTarget = (e: SyntheticEvent<any>): boolean =>
    overlayRef?.current === e.target ||
    (e.target as any).closest(`.${vodClassnames.overlay}`) === overlayRef?.current;

  const getEventPosition = (xPos: number): OverlayHalf | null => {
    if (!(overlayRef && overlayRef.current)) {
      return null;
    }
    const { x, width } = overlayRef.current.getBoundingClientRect();
    let middle = x + width / 2;
    if (Number.isNaN(middle)) {
      middle = 0;
    }
    return xPos < middle ? 'left' : 'right';
  };

  const isTouch = useRef(false);

  const isPaused = !activeChromecastDevice
    ? playerState === PlayerClientState.PAUSED
    : chromecastState === ChromecastState.PAUSED;
  const isPlaying = !activeChromecastDevice
    ? playerState === PlayerClientState.PLAYING
    : chromecastState === ChromecastState.PLAYING;

  const onTouchEnd = () => {
    // nastavíme si origin eventu
    isTouch.current = true;
  };

  const clickTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  const clickCounter = useRef<number>(0);
  const isMobileDevice = useMobileDevice();

  const onOverlayClick = (e: MouseEvent<HTMLDivElement>) => {
    // pokud je settings otevřené, při kliku na overlay nedělat nic
    if (hasOpenPopup || menuPopupVisible !== null) {
      return;
    }
    e.persist();

    // vznikl event z touch eventu
    const hasTouchOrigin = isTouch.current;

    // click je poslední ze série eventů co vyvolá klikl, takže resetujeme
    isTouch.current = false;

    // Podminka na `overlayRef.current` je nutna kvuli TS
    if (!(overlayRef && overlayRef.current && isOverlayTarget(e))) {
      return;
    }

    clickCounter.current += 1;

    // Double click, provedeme seek dopredu/dozadu podle pozice kliknuti
    if (clickCounter.current >= 2) {
      clickCounter.current = 0;
      if (clickTimeout.current !== null) {
        clearTimeout(clickTimeout.current);
        clickTimeout.current = null;
      }

      const overlayHalf = getEventPosition(e.pageX);
      if (overlayHalf) {
        onDoubleClick(e, overlayHalf, isMobileDevice);
      }
    } else if (clickTimeout.current === null) {
      // Nastavime timeout, po kterem provedeme operaci, pokud nedoslo k double clicku
      clickTimeout.current = setTimeout(() => {
        clickCounter.current = 0;
        clickTimeout.current = null;

        // pokud jde o původem touch event, zavoláme onTap
        if (hasTouchOrigin) {
          // v případě že je event prevented, nebudeme handlovat klik
          if (e.isDefaultPrevented()) {
            return;
          }
          onTap();
          return;
        }
        onClick(e);
      }, OVERLAY_DOUBLE_CLICK_TIMEOUT);
    }
  };

  const onKeyPressed = (event: KeyboardEvent<HTMLDivElement>) => {
    const ignoreKeyPress =
      playerMode === PlayerMode.ads && ['ArrowLeft', 'ArrowRight'].includes(event.key);
    if (onKeyPressedCallback && !ignoreKeyPress) {
      onKeyPressedCallback(event);
    }
  };

  useEffect(() => {
    return () => {
      if (clickTimeout.current !== null) {
        clearTimeout(clickTimeout.current);
      }
    };
  }, []);

  const finishedOverlayVisible = playerState === PlayerClientState.FINISHED;
  const loadingOverlayVisible = playerState === PlayerClientState.LOADING;
  const bufferingOverlayVisible = playerState === PlayerClientState.BUFFERING;
  const readyOverlayVisible = playerState === PlayerClientState.READY;
  const adOverlayVisible =
    playerMode === PlayerMode.ads &&
    !finishedOverlayVisible &&
    !loadingOverlayVisible &&
    !readyOverlayVisible;
  const mobileControls = isMaxTouchMediumMobile;
  // Horni Scrim bychom meli renderovat pouze pokud na nem je nejaky obsah
  const headerHasContent = !!ageRestriction || !!showTitle || !!videoTitle || false;
  const shouldRenderTopScrim =
    (!showSimpleVideoHeader || (indexes && indexes.length > 0)) &&
    headerHasContent &&
    playerMode !== PlayerMode.ads;
  // po loadingu nastavime focus na player overlay, aby fungovaly klavesove zkratky

  useEffect(() => {
    if (overlayRef?.current) {
      overlayRef.current.focus();
    }
  }, [overlayRef]);
  // Nastavi focus na Replay button na konci videa a pak zpet na hlavni overlay
  useEffect(() => {
    if (finishedOverlayVisible) {
      if (finishedOverlayRef.current && document.activeElement !== finishedOverlayRef.current) {
        // Focus musime nastavit rucne kvuli `blur` eventu`
        finishedOverlayRef.current.focus();
      }
    } else if (
      overlayRef &&
      overlayRef.current &&
      document.activeElement !== overlayRef.current &&
      isVideoStart &&
      !readyOverlayVisible
    ) {
      // Focus musime nastavit zpet na overlay, jinak prestanou fungovat klavesove zkratky
      overlayRef.current.focus();
    }
  }, [
    finishedOverlayRef,
    finishedOverlayVisible,
    isVideoStart,
    overlayRef,
    playerState,
    readyOverlayVisible,
  ]);

  // TODO: toto se mozna vrati, ale zatim neni jasne, jak a kdy to budou produkty pouzivat
  // kdyz zklame autoplay, pusobi to rusive
  // const previewMeta = useMemo(
  //   () => ({
  //     duration: duration || undefined,
  //     indexes: indexes || [],
  //     ageRestriction: ageRestriction || undefined,
  //     showTitle: showTitle || undefined,
  //     title: videoTitle || undefined,
  //   }),
  //   [ageRestriction, indexes, showTitle, videoTitle, duration]
  // );

  const onOverylayMouseMove = useMemo(
    () =>
      throttle(() => {
        if (!isTouch.current) {
          onMouseMove();
        }
      }, 100),
    [onMouseMove]
  );

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <div
      aria-label={`${formatMessage({
        id: 'Overlay.playerTitle',
        defaultMessage: 'Přehrávač ČT',
        description: 'Popis čtečky po přechodu přehrávače do focusu',
      })} - ${videoTitle || showTitle} `}
      className={classNames(vodClassnames.overlay, {
        [vodClassnames.overlayFullscreen]: screenMode === ScreenMode.FULLSCREEN,
        [vodClassnames.hideCursor]:
          playerState === PlayerClientState.PLAYING &&
          !controlsVisible &&
          !indexListVisible &&
          screenMode === ScreenMode.FULLSCREEN,
      })}
      data-testid="PlayerOverlay"
      ref={overlayRef}
      role="region"
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      tabIndex={0}
      onClick={onOverlayClick}
      onKeyDown={onKeyPressed}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseMove={onOverylayMouseMove}
      onTouchEnd={onTouchEnd}
    >
      {isAudioOnly && (
        <div style={{ color: 'white' }}>
          <AudioOnlyOverlay previewImage={previewImage} />
        </div>
      )}
      {!hbbtvStreamingActiveDevice && <OverlayIcon />}
      {!overlay.icon && isPaused && !hbbtvStreamingActiveDevice && (
        <VideoIconButton
          suppressHoverEffect
          className={vodClassnames.middleIcon}
          {...(activeChromecastDevice ? { buttonColor: BlackOpacity50 } : {})}
        />
      )}
      {!overlay.icon && isPlaying && controlsVisible && (
        <VideoIconButton
          suppressHoverEffect
          Icon={IconPause}
          buttonColor={BlackOpacity50}
          className={vodClassnames.middleIcon}
        />
      )}
      {bufferingOverlayVisible && <VODBufferingOverlay />}
      {adOverlayVisible && (
        <AdsOverlay
          pauseCountdown={[PlayerClientState.PAUSED, PlayerClientState.BUFFERING].includes(
            playerState
          )}
          showAdButton={showAdButton}
          skipAdInSeconds={skipAdInSeconds}
          onAdButtonClick={onAdButtonClick}
          onSkipAd={onSkipAd}
        />
      )}
      {loadingOverlayVisible && <VODLoadingOverlay screenMode={screenMode} />}
      {finishedOverlayVisible && (
        <VODFinishedOverlay
          overlayRef={finishedOverlayRef}
          previewImage={previewImage}
          screenMode={screenMode}
          onClick={onReplayButtonPressed}
        />
      )}
      {readyOverlayVisible && (
        <ReadyOverlay
          canPlayOnlySound={false}
          licenseInfo={licenseInfo}
          // meta={previewMeta}
          previewImage={previewImage}
          progress={playerVariant === PlayerVariantEnum.VOD ? currentProgress : undefined}
          screenMode={ScreenMode.NORMAL}
          showSimpleVideoHeader={false}
          onIndexListVisible={() => setIndexListVisible(true)}
          onPlayButtonClick={onPlayButtonPressed}
        />
      )}
      {!loadingOverlayVisible && !readyOverlayVisible && (
        <Scrim
          visibleBottom={controlsVisible}
          visibleRight={mobileControls}
          visibleTop={shouldRenderTopScrim && controlsVisible}
        />
      )}
      {hbbtvStreamingActiveDevice && (
        <OverlayWrapper screenMode={screenMode}>
          <div className={vodClassnames.castWrapper}>
            <span>Přehráváte na zařízení označeném </span>
            {hbbtvStreamingActiveDevice.name || hbbtvStreamingActiveDevice.type}
          </div>
        </OverlayWrapper>
      )}
      {activeChromecastDevice && (
        <OverlayWrapper screenMode={screenMode}>
          <div className={vodClassnames.castWrapper}>
            <span>Přehráváte na zařízení označeném </span>
            {activeChromecastDevice.friendlyName || 'Chromecast'}
          </div>
        </OverlayWrapper>
      )}
      {children}
    </div>
  );
};

export default Overlay;
