import React, { memo, ReactNode, RefObject, useCallback, useRef, useState } from 'react';
import classNames from 'classnames';
import { Button, useFocusVisibleClassName } from '@czechtv/components';
import {
  IconTrash,
  IconPlay,
  IconPause,
  IconAirplay,
  IconArrowLeft,
  IconRedButton,
  IconErrorCircle,
} from '@czechtv/icons';
import useAirplay from '../../../../utils/useAirplay';
import { usePlayerContext } from '../../../PlayerContext';
import { PlayerNativeButton } from '../../../../components/PlayerNativeButton/PlayerNativeButton';
import MenuPopup from '../MenuPopup';
import { HbbtvConnect, MenuPopupType } from '../../../../constants';
import { formatMessage } from '../../../../utils/formatMessage';
import { castMessages as messages } from './messages';
import { CastTvGraphics } from './CastTvGraphics';
import { CastMessage } from './CastMessage';
import { castClassnames } from './Cast.css';

interface CastProps {
  castMenuRef?: RefObject<HTMLDivElement>;
  hbbtv: any;
  isExternalAudioDescription?: boolean;
  isTimeshift: boolean;
  onClose?: () => void;
  onHbbtvDeviceSelect: () => void;
  playerRef?: RefObject<HTMLElement>;
  streamUrl: string | null;
  videoRef?: RefObject<HTMLVideoElement>;
}

enum HbbtvState {
  newConnection = 'newConnection',
  noConnection = 'noConnection',
  removeConnection = 'removeConnection',
  selectConnection = 'selectConnection',
}

export const Cast = memo(
  ({
    castMenuRef,
    onHbbtvDeviceSelect,
    playerRef,
    videoRef,
    hbbtv,
    isExternalAudioDescription,
    isTimeshift,
    onClose = () => {},
  }: CastProps) => {
    const {
      hbbtvStreamingActiveDevice,
      disableAirplay,
      disableChromecast,
      isChromecastApiAvailable,
      toggleChromecast,
      activeChromecastDevice,
    } = usePlayerContext();

    const { triggerAirplay } = useAirplay({ video: videoRef?.current });
    const [isChromecastDeviceAvailable, setIsChromecastDeviceAvailable] = useState(false);
    const focusVisibleClassName = useFocusVisibleClassName({ inset: true });
    const chromecastButtonRef = useRef<HTMLButtonElement>(null);

    const isChromecastEnabled = !disableChromecast;
    const isAirplayEnabled = !disableAirplay;

    const canUseChromecast = !!(
      isChromecastApiAvailable &&
      isChromecastEnabled &&
      !isExternalAudioDescription &&
      !isTimeshift
    );
    const canUseAirplay = isAirplayEnabled && !isExternalAudioDescription && isTimeshift;

    // zatim takto, pripadne logiku rozsirime, Airplay bude zatim disabled
    const isAirplaySupported = !!(window as { WebKitPlaybackTargetAvailabilityEvent?: Event })
      .WebKitPlaybackTargetAvailabilityEvent;
    const isChromecastSupported = isChromecastApiAvailable;

    const isCastingAvailable = canUseChromecast || canUseAirplay;

    const hbbtvDataFactory = (): HbbtvConnect => {
      return {
        ...hbbtv,
      };
    };

    const {
      onAddTvButtonClick,
      onCreateTvButtonClick,
      onCloseButtonClick,
      onBackClick,
      onSelectItem,
      onDeleteItem,
      onConfirmDeleteItemButtonClick,
      onKeepTvButtonClick,
      hbbtvState,
      setPairingId,
      pairedDevices,
      deviceToRemove,
      connectionError,
    } = hbbtvDataFactory();

    const chromecastDeviceName = activeChromecastDevice?.friendlyName;

    const getChromecastDeviceName = useCallback(() => {
      // tohle je hack, jak nastavit, ze v siti bylo zjisteno zarizeni
      // na webu kvuli privacy/security to nejde jen tak zjistit pomoci API
      // stara se o to web component Chromecast button - viz. is="google-cast-button"
      // pokud se zobrazi, zavolame funkci pro zobrazeni textu a zaroven zmenime flag
      const buttonDisplayed = chromecastButtonRef.current?.style.display !== 'none';
      if (chromecastButtonRef.current && isChromecastDeviceAvailable !== buttonDisplayed) {
        setIsChromecastDeviceAvailable(buttonDisplayed);
      }

      return chromecastDeviceName || `Chromecast`;
    }, [chromecastDeviceName, isChromecastDeviceAvailable]);

    const getTitleBarContent = useCallback(
      (hbbtvState: any): ReactNode | null | string => {
        if (hbbtvState === HbbtvState.newConnection) {
          return (
            <p className={castClassnames.title}>
              <PlayerNativeButton
                className={castClassnames.backButton}
                type="button"
                onClick={onBackClick}
              >
                <IconArrowLeft />
                zpět
              </PlayerNativeButton>
            </p>
          );
        }
        if (
          hbbtvState === HbbtvState.selectConnection ||
          (hbbtvState === HbbtvState.noConnection && isCastingAvailable)
        ) {
          return <p className={castClassnames.title}>{formatMessage(messages.playOnTv)}</p>;
        }
        return null;
      },
      [castClassnames.backButton, castClassnames.title, isCastingAvailable, onBackClick]
    );

    return (
      <MenuPopup
        hasLeftSectionContent
        scrollableContent
        closeButtonAriaLabel={formatMessage(messages.castMenuclose)}
        menuPopupRef={castMenuRef}
        menuPopupTitleAriaLabel={formatMessage(messages.castMenu)}
        name={MenuPopupType.CAST}
        playerRef={playerRef}
        setMenuPopupVisible={onCloseButtonClick}
        titleBarContent={getTitleBarContent(hbbtvState)}
        onClose={onClose}
      >
        <div className={castClassnames.content}>
          {(hbbtvState === HbbtvState.noConnection ||
            hbbtvState === HbbtvState.selectConnection) && (
            <>
              <>
                {/* list chromecast/airplay */}
                <>
                  {canUseChromecast ? (
                    <p className={castClassnames.tvListTitle}>
                      {formatMessage(messages.connectedDevicesChromecast)}
                    </p>
                  ) : null}
                  {canUseAirplay ? (
                    <p className={castClassnames.tvListTitle}>
                      {formatMessage(messages.connectedDevicesAirplay)}
                    </p>
                  ) : null}
                  {isCastingAvailable ? (
                    <ul className={castClassnames.tvList}>
                      {canUseChromecast && (
                        // toto je hodne specificky case, v zasade tento button sam zjistuje
                        // informace o tom, jestli je nejake zarizeni dostupne (viz. komentar vyse)
                        <li
                          className={castClassnames.tvListItem}
                          {...(!isChromecastDeviceAvailable
                            ? { style: { borderStyle: 'hidden' } }
                            : {})}
                        >
                          <button
                            aria-label={formatMessage(messages.connectedDevicesChromecast)}
                            // Jde o custom web component, proto tady className nejde
                            // @ts-ignore
                            class={classNames(castClassnames.selectButtonStreaming)}
                            // Timhle rikame, ze se ma pouzit odpovidajici web component
                            // (injectnuta Chromecast scriptem)
                            is="google-cast-button"
                            ref={chromecastButtonRef}
                            style={{ display: 'flex' }}
                            title={formatMessage(messages.connectedDevicesChromecast)}
                            type="button"
                            onClick={toggleChromecast}
                          >
                            <p className={castClassnames.streamDeviceListItemTitle}>
                              {getChromecastDeviceName()}
                            </p>
                          </button>
                        </li>
                      )}
                      {canUseChromecast && !isChromecastDeviceAvailable && (
                        <p className={castClassnames.text}>Připojte zařízení</p>
                      )}
                      {canUseAirplay ? (
                        <li className={castClassnames.tvListItem} key="airplay">
                          <PlayerNativeButton
                            aria-label={formatMessage(messages.connectedDevicesAirplay)}
                            className={classNames(castClassnames.selectButtonStreaming)}
                            title={formatMessage(messages.connectedDevicesAirplay)}
                            type="button"
                            onClick={triggerAirplay}
                          >
                            <IconAirplay />
                            <p className={castClassnames.streamDeviceListItemTitle}>AirPlay</p>
                          </PlayerNativeButton>
                        </li>
                      ) : null}
                    </ul>
                  ) : null}
                </>
                <div className={castClassnames.tvListContainer}>
                  {hbbtvState !== HbbtvState.noConnection && (
                    <p className={castClassnames.tvListTitle}>
                      {formatMessage(messages.connectedTVs)}
                    </p>
                  )}

                  {hbbtvState === HbbtvState.noConnection && (
                    <>
                      {!isCastingAvailable && (
                        <div className={castClassnames.image}>
                          <CastTvGraphics />
                        </div>
                      )}
                      <p className={classNames(castClassnames.tvListTitle)}>
                        {formatMessage(messages.connectedTVs)}
                      </p>
                      <p className={castClassnames.text}>
                        {formatMessage(messages.mustConnectTv)}
                        <br />
                        <a
                          href="https://www.ceskatelevize.cz/hbbtv/"
                          rel="noreferrer"
                          target="_blank"
                        >
                          {formatMessage(messages.moreInfo)}
                        </a>
                      </p>
                    </>
                  )}
                  {pairedDevices.length > 0 && (
                    <ul className={castClassnames.tvList}>
                      {pairedDevices.map((pairedDevice) => {
                        return (
                          <li className={castClassnames.tvListItem} key={pairedDevice.id}>
                            <PlayerNativeButton
                              aria-label={
                                hbbtvStreamingActiveDevice?.id !== pairedDevice.id
                                  ? formatMessage(messages.playOnTvName)
                                  : formatMessage(messages.pauseOnTvName)
                              }
                              className={castClassnames.selectButton}
                              value={pairedDevice.id}
                              onClick={() => onSelectItem(pairedDevice, onHbbtvDeviceSelect)}
                            >
                              {hbbtvStreamingActiveDevice?.id !== pairedDevice.id ? (
                                <IconPlay className={castClassnames.iconPlay} />
                              ) : (
                                <IconPause className={castClassnames.iconPause} />
                              )}
                              <p
                                className={classNames(castClassnames.tvListItemTitle, {
                                  active: hbbtvStreamingActiveDevice?.id === pairedDevice.id,
                                })}
                              >
                                {pairedDevice.type || pairedDevice.name}
                              </p>
                            </PlayerNativeButton>
                            <PlayerNativeButton
                              aria-label={formatMessage(messages.deleteTv)}
                              className={castClassnames.deleteButton}
                              value={pairedDevice.id}
                              onClick={() => onDeleteItem(pairedDevice)}
                            >
                              <IconTrash />
                            </PlayerNativeButton>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </div>
              </>
              <Button
                className={castClassnames.addButton}
                size="medium"
                styleType="inverted"
                onClick={onAddTvButtonClick}
              >
                {formatMessage(messages.addTv)}
              </Button>
              <CastMessage
                contentNotAllowed={isExternalAudioDescription || isTimeshift}
                isAirplayEnabled={!disableAirplay}
                isAirplaySupported={isAirplaySupported}
                isChromecastEnabled={!disableChromecast}
                isChromecastSupported={isChromecastSupported}
              />
            </>
          )}
          {hbbtvState === HbbtvState.newConnection && (
            <div className={castClassnames.instructions}>
              <p
                className={classNames(castClassnames.tvListTitle, {
                  large: true,
                  hideOnMobile: true,
                })}
              >
                {formatMessage(messages.connectedTVs)}
              </p>
              <ol className={castClassnames.list}>
                <li className={castClassnames.listItem}>
                  {formatMessage(messages.instructions1a)}
                  <IconRedButton />
                  {formatMessage(messages.instructions1b)}
                </li>

                <li className={castClassnames.listItem}>
                  {formatMessage(messages.instructions2a)}{' '}
                  {/* <IconSettings fill="#a1abb8" height={24} width={24} /> */}
                  {formatMessage(messages.instructions2b)}
                </li>
                <li className={castClassnames.listItem}>{formatMessage(messages.instructions3)}</li>
                {connectionError ? (
                  <div className={castClassnames.errorMessage}>
                    <p className={castClassnames.errorMessageTitle}>
                      <IconErrorCircle height={24} width={24} />K televizi se nelze připojit
                    </p>
                    <p className={castClassnames.errorMessageSubtitle}>
                      Zkontrolujte jestli jsou všechna zařízení připojena k internetu a zadejte kód
                      znovu.{' '}
                    </p>
                  </div>
                ) : null}
                <div className={castClassnames.form}>
                  <input
                    aria-label={formatMessage(messages.addTv)}
                    className={classNames(castClassnames.codeInput, focusVisibleClassName)}
                    placeholder="___ ___ ___ ___"
                    type="number"
                    onBlur={(e) => {
                      setPairingId(e.target.value);
                    }}
                    onChange={(e) => {
                      setPairingId(e.target.value);
                    }}
                    onKeyDown={(e) => e.stopPropagation()}
                  />

                  <Button
                    className={castClassnames.addButton}
                    size="medium"
                    onClick={onCreateTvButtonClick}
                  >
                    Přidat
                  </Button>
                </div>
              </ol>
            </div>
          )}
          {hbbtvState === HbbtvState.removeConnection && (
            <>
              <div>
                <p className={castClassnames.disconnectTitle}>
                  {formatMessage(messages.confirmRemoval1, {
                    message: `${deviceToRemove?.name || deviceToRemove?.type}`,
                  })}
                </p>
                <p className={castClassnames.disconnectSubTitle}>
                  {formatMessage(messages.confirmRemoval2)}
                </p>
              </div>
              <div className={castClassnames.btnsContainer}>
                <Button
                  className={castClassnames.disconnectButton}
                  size="medium"
                  onClick={onConfirmDeleteItemButtonClick}
                >
                  {formatMessage(messages.disconnect)}
                </Button>
                <Button
                  className={castClassnames.keepButton}
                  size="medium"
                  styleType="inverted"
                  onClick={onKeepTvButtonClick}
                >
                  {formatMessage(messages.keepConnection)}
                </Button>
              </div>
            </>
          )}
        </div>
      </MenuPopup>
    );
  }
);
