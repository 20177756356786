/* eslint-disable canonical/filename-no-index */
import React from 'react';
import { log } from '../../../utils/logger';
import { PlayerErrorOverlay } from '../Overlay/Overlay';

interface State {
  error: boolean;
}

interface Props {
  children: React.ReactNode;
}

class PlayerErrorBoundary extends React.Component<Props, State> {
  // eslint-disable-next-line react/state-in-constructor
  state: State = { error: false };

  // tady měníme stav komponenty, přiteče chyba a podle ní nastavíme error stav
  // jelikož počítáme, že budeme jen ukazovat „něco se pokazilo“, není tu custom text
  public static getDerivedStateFromError(): State {
    return { error: true };
  }

  // errory co přitečou sem, pouze logujeme a posíláme do nějaké služby
  public componentDidCatch(
    error: Error
    // errorInfo: ErrorInfo
  ) {
    log.error({
      message: 'Player ErrorBoundary caught error',
      error,
      // logData: {
      //   customData: { errorInfo }
      // }
    });
  }

  public render() {
    if (this.state.error) {
      return <PlayerErrorOverlay onReloadButtonClick={() => this.setState({ error: false })} />;
    }

    return this.props.children;
  }
}

export default PlayerErrorBoundary;
