import React, { memo, SyntheticEvent } from 'react';
import classNames from 'classnames';
import { IconPlay, IconRefresh, SvgComponent } from '@czechtv/icons';
import { PlayerNativeButton } from '../PlayerNativeButton/PlayerNativeButton';
import { videoIconButtonClassnames } from './VideoIconButton.css';

export interface VideoIconButtonProps {
  Icon?: SvgComponent;
  ariaLabel?: string;
  buttonColor?: string;
  className?: string;
  dataTestid?: string;
  disabled?: boolean;
  hover?: boolean;
  onClick?: (event: SyntheticEvent<HTMLElement>) => void;
  replay?: boolean;
  startsAt?: number;
  style?: any;
  suppressHoverEffect?: boolean;
  text?: string;
  textClassName?: string;
}

const VideoIconButton = ({
  onClick,
  disabled = false,
  hover,
  suppressHoverEffect,
  replay = false,
  text,
  className,
  textClassName,
  ariaLabel,
  Icon = replay ? IconRefresh : IconPlay,
  dataTestid = 'PlayIcon',
  startsAt,
  buttonColor,
  style,
}: VideoIconButtonProps) => {
  return (
    <PlayerNativeButton
      aria-label={ariaLabel}
      className={classNames(className, videoIconButtonClassnames.playButtonContainer, {
        infoOnly: !!startsAt,
        hover,
        suppressHoverEffect,
      })}
      data-testid={dataTestid}
      disabled={disabled}
      style={buttonColor ? { background: buttonColor, ...style } : style}
      tabIndex={suppressHoverEffect ? -1 : 0}
      onClick={onClick}
    >
      {!startsAt && <Icon className={videoIconButtonClassnames.playIcon} />}
      {text && !startsAt ? (
        <span className={classNames(videoIconButtonClassnames.textWithDivider, textClassName)}>
          {text}
        </span>
      ) : null}
      {text && startsAt ? (
        <span className={classNames(videoIconButtonClassnames.text, textClassName)}>{text}</span>
      ) : null}
    </PlayerNativeButton>
  );
};

export default memo(VideoIconButton);
