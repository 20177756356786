export const getTestVodStreamUrls = ({
  streamType,
  origin,
  quality,
}: {
  origin?: string;
  quality?: string;
  streamType: 'dash' | 'hls';
}) => {
  if (!origin) {
    return null;
  }
  if (origin === 'lab_video_only') {
    if (streamType === 'dash') {
      return {
        main: 'https://ivys-nw-cdn.o2-tv.cz/cdn/uri/get/?token=26c86368d5df0f435a50e2216117f72e8cc03756&userId=52c48ca7-ca50-45d8-a187-f1deee1130e6&contentId=61080069946263872&streamType=dash&quality=1080p&contentType=vod&region=1&expiry=1730315214&drmOnly=false&skipIpAddressCheck=true&qualityCount=5',
      };
    }
    return {
      main: 'https://ivys-nw-cdn.o2-tv.cz/cdn/uri/get/?token=9d2d532297d1cb416a1cfa73e56958f14a0be925&userId=52c48ca7-ca50-45d8-a187-f1deee1130e6&contentId=61080069946263872&streamType=hls&quality=1080p&contentType=vod&region=1&expiry=1730315214&drmOnly=false&skipIpAddressCheck=true&qualityCount=5',
    };
  }
  if (origin === 'lab_vod_112_english') {
    if (streamType === 'dash') {
      if (quality === 'audio') {
        console.log('audio_only_lab_vod_112_english');
        return {
          main: 'https://ivys-nw-cdn.o2-tv.cz/cdn/uri/get/?token=e1bb653615947ba6e47e495d547fe193b74776ef&userId=52c48ca7-ca50-45d8-a187-f1deee1130e6&contentId=61080069946263876&streamType=dash&quality=audio&contentType=vod&region=1&expiry=1730721015&drmOnly=false&skipIpAddressCheck=true&qualityCount=5',
        };
      }
      return {
        main: 'https://ivys-nw-cdn.o2-tv.cz/cdn/uri/get/?token=2a3655c7ac68a329deac624d538d504b80084082&userId=52c48ca7-ca50-45d8-a187-f1deee1130e6&contentId=61080069946263876&streamType=dash&quality=1080p&contentType=vod&region=1&expiry=1730721015&drmOnly=false&skipIpAddressCheck=true&qualityCount=5',
      };
    }
    if (quality === 'audio') {
      console.log('audio_only_lab_vod_112_english');
      return {
        main: 'https://ivys-nw-cdn.o2-tv.cz/cdn/uri/get/?token=0027a0485c5256af891ddc830f2ac27c5f6c8cbd&userId=52c48ca7-ca50-45d8-a187-f1deee1130e6&contentId=61080069946263876&streamType=hls&quality=audio&contentType=vod&region=1&expiry=1730721015&drmOnly=false&skipIpAddressCheck=true&qualityCount=5',
      };
    }
    return {
      main: 'https://ivys-nw-cdn.o2-tv.cz/cdn/uri/get/?token=8c4b5a1ce5d84b229a38ee63de2b52bd519132ad&userId=52c48ca7-ca50-45d8-a187-f1deee1130e6&contentId=61080069946263876&streamType=hls&quality=1080p&contentType=vod&region=1&expiry=1730721015&drmOnly=false&skipIpAddressCheck=true&qualityCount=5',
    };
  }
  return null;
};

export const getTestLiveStreamUrls = (streamType: 'dash' | 'hls', origin?: string) => {
  if (!origin) {
    return null;
  }
  if (origin === 'lab_122') {
    if (streamType === 'dash') {
      return {
        main: 'https://ivys-nw-cdn.o2-tv.cz/cdn/uri/get/?token=dbf2ee501f6d818064811b66a361a7ad3f1b8dd6&userId=52c48ca7-ca50-45d8-a187-f1deee1130e6&contentId=122&streamType=dash&quality=1080p&contentType=live&region=1&expiry=1740735720&drmOnly=true&skipIpAddressCheck=true&qualityCount=5&encryption=wv',
        timeshift:
          'https://ivys-nw-cdn.o2-tv.cz/cdn/uri/get/?token=bf527d59fef39674b59fb164c1963b24ebbb9f9d&userId=52c48ca7-ca50-45d8-a187-f1deee1130e6&contentId=122&streamType=dash&quality=1080p&contentType=timeshift&region=1&expiry=1740735720&drmOnly=true&skipIpAddressCheck=true&qualityCount=5&encryption=wv',
        isBlocked: false,
      };
    }
    return {
      main: 'https://ivys-nw-cdn.o2-tv.cz/cdn/uri/get/?token=5f28d9d8a488baee2e04fc767001c20b8d844cbe&userId=52c48ca7-ca50-45d8-a187-f1deee1130e6&contentId=122&streamType=hls&quality=1080p&contentType=live&region=1&expiry=1740735720&drmOnly=true&skipIpAddressCheck=true&qualityCount=5&encryption=fp',
      timeshift:
        'https://ivys-nw-cdn.o2-tv.cz/cdn/uri/get/?token=7fd8c0ba1956b776b32c3c5882d0fa3bf2702b11&userId=52c48ca7-ca50-45d8-a187-f1deee1130e6&contentId=122&streamType=hls&quality=1080p&contentType=timeshift&region=1&expiry=1740735720&drmOnly=true&skipIpAddressCheck=true&qualityCount=5&encryption=fp',
      isBlocked: false,
    };
  }
  if (origin === 'lab_102') {
    if (streamType === 'dash') {
      return {
        main: 'https://ivys-nw-cdn.o2-tv.cz/cdn/uri/get/?token=5b3de8ccae5f38f7aa10c305769ebf5dd746a93f&userId=52c48ca7-ca50-45d8-a187-f1deee1130e6&contentId=102&streamType=dash&quality=1080p&contentType=live&region=1&expiry=1740735720&drmOnly=false&skipIpAddressCheck=true&qualityCount=5',
        timeshift:
          'https://ivys-nw-cdn.o2-tv.cz/cdn/uri/get/?token=817716569b54f9c3282fbb2df13b593deb027ff6&userId=52c48ca7-ca50-45d8-a187-f1deee1130e6&contentId=102&streamType=dash&quality=1080p&contentType=timeshift&region=1&expiry=1740735720&drmOnly=false&skipIpAddressCheck=true&qualityCount=5',
        isBlocked: false,
      };
    }
    return {
      main: 'https://ivys-nw-cdn.o2-tv.cz/cdn/uri/get/?token=2e2bd46f2c918547f4b2a0934bf1c1bb318c534b&userId=52c48ca7-ca50-45d8-a187-f1deee1130e6&contentId=102&streamType=hls&quality=1080p&contentType=live&region=1&expiry=1740735720&drmOnly=false&skipIpAddressCheck=true&qualityCount=5',
      timeshift:
        'https://ivys-nw-cdn.o2-tv.cz/cdn/uri/get/?token=79794881bf7b707f3af993d1c45f23ef0013d81e&userId=52c48ca7-ca50-45d8-a187-f1deee1130e6&contentId=102&streamType=hls&quality=1080p&contentType=timeshift&region=1&expiry=1740735720&drmOnly=false&skipIpAddressCheck=true&qualityCount=5',
      isBlocked: false,
    };
  }
  if (origin === 'lab_2722') {
    if (streamType === 'dash') {
      return {
        main: 'https://ivys-nw-cdn.o2-tv.cz/cdn/uri/get/?token=2825ec233c52002f66e609b4910a09949f045068&userId=52c48ca7-ca50-45d8-a187-f1deee1130e6&contentId=2722&streamType=dash&quality=1080p&contentType=live&region=1&expiry=1740735720&drmOnly=true&skipIpAddressCheck=true&qualityCount=5&encryption=wv',
        timeshift:
          'https://ivys-nw-cdn.o2-tv.cz/cdn/uri/get/?token=5474b84fc1e19f2aef42cc8ee9b833fe4e26e675&userId=52c48ca7-ca50-45d8-a187-f1deee1130e6&contentId=2722&streamType=dash&quality=1080p&contentType=timeshift&region=1&expiry=1740735720&drmOnly=true&skipIpAddressCheck=true&qualityCount=5&encryption=wv',
        isBlocked: false,
      };
    }
    return {
      main: 'https://ivys-nw-cdn.o2-tv.cz/cdn/uri/get/?token=6ba8f7c992a3f3bd48273b4efd57f83beaf0b067&userId=52c48ca7-ca50-45d8-a187-f1deee1130e6&contentId=2722&streamType=hls&quality=1080p&contentType=live&region=1&expiry=1740735720&drmOnly=true&skipIpAddressCheck=true&qualityCount=5&encryption=fp',
      timeshift:
        'https://ivys-nw-cdn.o2-tv.cz/cdn/uri/get/?token=bae80642b9d38418c5cf1ce0536862a07445de47&userId=52c48ca7-ca50-45d8-a187-f1deee1130e6&contentId=2722&streamType=hls&quality=1080p&contentType=timeshift&region=1&expiry=1740735720&drmOnly=true&skipIpAddressCheck=true&qualityCount=5&encryption=fp',
      isBlocked: false,
    };
  }
  if (origin === 'lab_2702') {
    if (streamType === 'dash') {
      return {
        main: 'https://ivys-nw-cdn.o2-tv.cz/cdn/uri/get/?token=bcb160f2c3991b90806ebfbccf808020aaa617ee&userId=52c48ca7-ca50-45d8-a187-f1deee1130e6&contentId=2702&streamType=dash&quality=1080p&contentType=live&region=1&expiry=1740735720&drmOnly=false&skipIpAddressCheck=true&qualityCount=5',
        timeshift:
          'https://ivys-nw-cdn.o2-tv.cz/cdn/uri/get/?token=95fc80ebe6e9d14326fffaa18cd839e68d2749e4&userId=52c48ca7-ca50-45d8-a187-f1deee1130e6&contentId=2702&streamType=dash&quality=1080p&contentType=timeshift&region=1&expiry=1740735720&drmOnly=false&skipIpAddressCheck=true&qualityCount=5',
        isBlocked: false,
      };
    }
    return {
      main: 'https://ivys-nw-cdn.o2-tv.cz/cdn/uri/get/?token=a77f7e26ff1beaea5eb1a186c8a823afbb4e1d29&userId=52c48ca7-ca50-45d8-a187-f1deee1130e6&contentId=2702&streamType=hls&quality=1080p&contentType=live&region=1&expiry=1740735720&drmOnly=false&skipIpAddressCheck=true&qualityCount=5',
      timeshift:
        'https://ivys-nw-cdn.o2-tv.cz/cdn/uri/get/?token=068f20be862e1096ead2395d878de84a233def05&userId=52c48ca7-ca50-45d8-a187-f1deee1130e6&contentId=2702&streamType=hls&quality=1080p&contentType=timeshift&region=1&expiry=1740735720&drmOnly=false&skipIpAddressCheck=true&qualityCount=5',
      isBlocked: false,
    };
  }
  if (origin === 'lab_2402') {
    if (streamType === 'dash') {
      return {
        main: 'https://ivys-nw-cdn.o2-tv.cz/cdn/uri/get/?token=5d58a89fc1bd90a5c3508b68a00ecf8b9b0c4661&userId=52c48ca7-ca50-45d8-a187-f1deee1130e6&contentId=2402&streamType=dash&quality=1080p&contentType=live&region=1&expiry=1740735720&drmOnly=false&skipIpAddressCheck=true&qualityCount=5',
        timeshift:
          'https://ivys-nw-cdn.o2-tv.cz/cdn/uri/get/?token=3c7894ae85addcd2d50bd16fc23881a104595476&userId=52c48ca7-ca50-45d8-a187-f1deee1130e6&contentId=2402&streamType=dash&quality=1080p&contentType=timeshift&region=1&expiry=1740735720&drmOnly=false&skipIpAddressCheck=true&qualityCount=5',
        isBlocked: false,
      };
    }
    return {
      main: 'https://ivys-nw-cdn.o2-tv.cz/cdn/uri/get/?token=ba1399627fe72f87f7843345153f933b6fb46399&userId=52c48ca7-ca50-45d8-a187-f1deee1130e6&contentId=2402&streamType=hls&quality=1080p&contentType=live&region=1&expiry=1740735720&drmOnly=false&skipIpAddressCheck=true&qualityCount=5',
      timeshift:
        'https://ivys-nw-cdn.o2-tv.cz/cdn/uri/get/?token=547c8a28f9b51c7a3bd87eb442a9f190cbacd9af&userId=52c48ca7-ca50-45d8-a187-f1deee1130e6&contentId=2402&streamType=hls&quality=1080p&contentType=timeshift&region=1&expiry=1740735720&drmOnly=false&skipIpAddressCheck=true&qualityCount=5',
      isBlocked: false,
    };
  }
  if (origin === 'lab_2422') {
    if (streamType === 'dash') {
      return {
        main: 'https://ivys-nw-cdn.o2-tv.cz/cdn/uri/get/?token=eeb7f5d0dd2207813c26e68d13a7cb9c10424be0&userId=52c48ca7-ca50-45d8-a187-f1deee1130e6&contentId=2422&streamType=dash&quality=1080p&contentType=live&region=1&expiry=1740735720&drmOnly=true&skipIpAddressCheck=true&qualityCount=5&encryption=wv',
        timeshift:
          'https://ivys-nw-cdn.o2-tv.cz/cdn/uri/get/?token=eb58f3db4704f0cf727960eb85a214707989c0bd&userId=52c48ca7-ca50-45d8-a187-f1deee1130e6&contentId=2422&streamType=dash&quality=1080p&contentType=timeshift&region=1&expiry=1740735720&drmOnly=true&skipIpAddressCheck=true&qualityCount=5&encryption=wv',
        isBlocked: false,
      };
    }
    return {
      main: 'https://ivys-nw-cdn.o2-tv.cz/cdn/uri/get/?token=e6b8b18b5bde6bcbb3b8887ac0045e5fab1149c0&userId=52c48ca7-ca50-45d8-a187-f1deee1130e6&contentId=2422&streamType=hls&quality=1080p&contentType=live&region=1&expiry=1740735720&drmOnly=true&skipIpAddressCheck=true&qualityCount=5&encryption=fp',
      timeshift:
        'https://ivys-nw-cdn.o2-tv.cz/cdn/uri/get/?token=eecfc8c7a6514e05354ca34be9b4a8f64fa6a79a&userId=52c48ca7-ca50-45d8-a187-f1deee1130e6&contentId=2422&streamType=hls&quality=1080p&contentType=timeshift&region=1&expiry=1740735720&drmOnly=true&skipIpAddressCheck=true&qualityCount=5&encryption=fp',
      isBlocked: false,
    };
  }
  return null;
};
