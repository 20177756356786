import { Encoder } from '@czechtv/utils';

export const getNielsenC1forLive = (channelName: string) => {
  switch (channelName) {
    case 'CT1':
      return '1';
    case 'CT2':
      return '2';
    case 'CT3':
      return '7';
    case 'CT24':
      return '7';
    case 'CT4':
      return '8';
    case 'CT5':
      return '15';
    case 'CT6':
      return '19';
    default:
      return '9999';
  }
};

// mapovani legacy nazvu na nove nazvoslovi
export const encoders: Record<string, Encoder> = {
  ct1: Encoder.CH_1,
  ct1jm: Encoder.CH_1_JM,
  ct1sm: Encoder.CH_1_SM,
  ct1svc: Encoder.CH_1_SVC,
  ct1jzc: Encoder.CH_1_JZC,
  ct2: Encoder.CH_2,
  // pro jistotu nechavame zruseny kanal pro tabulky v playeru, kdybu se trojka nekde poptavala
  // nejaky cas CH_3 fungoval jako CT24, ale s DRM
  // pro ostatni aplikace - hlavne MUF - je CH_3 z enkoderu odstranen
  ct3: 'CH_3' as Encoder,
  ct24: Encoder.CH_24,
  ct4: Encoder.CH_4,
  ct5: Encoder.CH_5,
  ct6: Encoder.CH_6,
  ct25: Encoder.CH_25,
  ct26: Encoder.CH_26,
  ct27: Encoder.CH_27,
  ct28: Encoder.CH_28,
  ct29: Encoder.CH_29,
  ct30: Encoder.CH_30,
  ct31: Encoder.CH_31,
  ct32: Encoder.CH_32,
  // extra nazvoslovi pro ct sport
  ct50: Encoder.CH_MP_01,
  ct62: Encoder.CH_MP_02,
  ct63: Encoder.CH_MP_03,
  ct64: Encoder.CH_MP_04,
  ct65: Encoder.CH_MP_05,
  ctmobile: Encoder.CH_MOB_01,
  ctmountpoint1: Encoder.CH_MP_01,
  ctmountpoint2: Encoder.CH_MP_02,
  ctmountpoint3: Encoder.CH_MP_03,
  ctmountpoint4: Encoder.CH_MP_04,
  ctmountpoint5: Encoder.CH_MP_05,
  ctmountpoint6: Encoder.CH_MP_06,
  ctmountpoint7: Encoder.CH_MP_07,
  ctmountpoint8: Encoder.CH_MP_08,
};

// mapovani legacy extra kanalu na nove nazvoslovi
const legacyExtra: Record<string, Encoder> = {
  ctmobile: Encoder.CH_MOB_01,
  mobile: Encoder.CH_MOB_01,
  ctmobile2: Encoder.CH_MP_02,
  mobile2: Encoder.CH_MP_02,
  ctmobile03: Encoder.CH_MP_03,
  mobile03: Encoder.CH_MP_03,
  ctmobile04: Encoder.CH_MP_04,
  mobile04: Encoder.CH_MP_04,
  ctmobile05: Encoder.CH_MP_05,
  mobile05: Encoder.CH_MP_05,
  ctmobile06: Encoder.CH_MP_06,
  mobile06: Encoder.CH_MP_06,
  ctmobile07: Encoder.CH_MP_07,
  mobile07: Encoder.CH_MP_07,
  ctmobile08: Encoder.CH_MP_08,
  mobile08: Encoder.CH_MP_08,
};

function getSanitizedIdec(idec?: string) {
  if (!idec) {
    return idec;
  }
  const sanitized = idec.replace(/[ /]/g, '').trim();
  return sanitized;
}

// pole znamych kanalu v novem nazvoslovi - lowercase
// pridame legacy ch_3
const newEncoders = ([...Object.values(Encoder), 'CH_3'] as string[]).map((value) =>
  value.toLowerCase()
);

// slouzi k zjisteni, zda videoId je zivak a vraci id pouzitelne pri poptavani playlistu
export const getNormalizedEncoder = (name?: string, newPlaylist?: boolean) => {
  if (!name) {
    return undefined;
  }
  // vse normalizujeme na lowercase pro dalsi pouziti
  const lowerCaseName = name.toLowerCase();

  // nove playlisty vs. nove nazvoslovi
  if (newPlaylist && newEncoders.includes(lowerCaseName)) {
    return lowerCaseName.toUpperCase();
  }
  // nove playlisty vs. stare nazvoslovi pro extra kanaly
  if (newPlaylist && lowerCaseName.includes('mobile')) {
    return legacyExtra[lowerCaseName];
  }

  // pro dalsi overovani odstranime _
  const noUnderscores = lowerCaseName.replace(/_/g, '');

  // stare playlisty, stare nazvoslovi pro extra kanaly
  if (!newPlaylist && noUnderscores.includes('mobile')) {
    // staci odstranit pouze prefix ct. pouzivame napr. mobile, mobile03, mobile2 (be)
    return noUnderscores.replace('ct', '');
  }

  // posledni sito: pokud je treba, prevedeme ch na ct
  const normalizedLegacyName = noUnderscores.replace('ch', 'ct');
  // zkusime dohledat v zaznamech
  if (encoders[normalizedLegacyName]) {
    return encoders[normalizedLegacyName];
  }

  // v tomto pripade se nejedna o zivak
  return undefined;
};

export interface SupportedIdentificators {
  bonusId?: string;
  encoder?: string;
  idec?: string;
  indexId?: string;
  live?: string;
  mediaId?: string;
  multimediaId?: string;
  versionId?: string;
  videoId?: string;
}

interface GetMediaId extends SupportedIdentificators {
  useNewPlaylist: boolean;
}

export const getMediaId = ({
  live: rawLive,
  encoder: encoderProp,
  idec: rawIdec,
  bonusId: rawBonusId,
  multimediaId: rawMultimediaId,
  indexId,
  videoId,
  versionId,
  mediaId,
  useNewPlaylist,
}: GetMediaId) => {
  const live = rawLive ? `ct${rawLive}` : undefined;
  const rawEncoder = live || videoId;

  const encoder = encoderProp || getNormalizedEncoder(rawEncoder, useNewPlaylist);
  const vodVideoId = !encoder ? videoId : undefined;

  const idec = getSanitizedIdec(rawIdec);

  // doplníme prefixy
  const bonusId =
    rawBonusId && !rawBonusId.includes('bo-') ? `BO-${rawBonusId}` : rawBonusId?.toUpperCase();
  const multimediaId =
    rawMultimediaId && !rawMultimediaId.includes('ct24-')
      ? `CT24-${rawMultimediaId}`
      : rawMultimediaId?.toUpperCase();

  // sestavíme potenciální external id
  // edu: nekdy pouziti bonusu (nespravne) uvadi i idec, tj dáváme na konec
  // art: obdobně u přehravání indexu
  const externalId = indexId || bonusId || multimediaId || idec;

  // detekce mediaMetaId
  const isMediaMetaId = vodVideoId && vodVideoId.length === 24;

  const ids = {
    ...(encoder ? { encoder } : {}),
    ...(isMediaMetaId && vodVideoId ? { mediaMetaId: vodVideoId } : {}),
    ...(!isMediaMetaId && vodVideoId ? { externalId: getSanitizedIdec(vodVideoId) } : {}),
    ...(externalId ? { externalId } : {}),
    ...(versionId ? { versionId } : {}),
    ...(mediaId ? { mediaId } : {}),
  };

  return ids;
};
