import React from 'react';
import { SettingsList } from '../SettingsMenu/SettingsList/SettingsList';
import { SettingsListItemProps } from './../SettingsMenu/SettingsList/SettingsListItem/SettingsListItem';
import { captionsStyleListClassnames } from './CaptionsStyleList.css';

interface CaptionsStyleListProps {
  listContent: {
    captionColor: SettingsListItemProps;
    captionSize: SettingsListItemProps;
  };
  setActiveItem: (label: string) => void;
  setListContent: (listContent: SettingsListItemProps) => void;
}

export const CaptionsStyleList = ({
  listContent,
  setListContent,
  setActiveItem,
}: CaptionsStyleListProps) => {
  return (
    <div className={captionsStyleListClassnames.captionsStyleWrapper}>
      <SettingsList
        isCaptionSize
        listContent={listContent.captionSize}
        setActiveItem={setActiveItem}
        setListContent={setListContent}
        testId={listContent.captionSize.testId}
      />
      <SettingsList
        isCaptionColor
        listContent={listContent.captionColor}
        setActiveItem={setActiveItem}
        setListContent={setListContent}
        testId={listContent.captionColor.testId}
      />
    </div>
  );
};
