/* eslint-disable max-lines */

/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
  memo,
  ReactNode,
  RefObject,
  SyntheticEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import clamp from 'lodash/clamp';
import classNames from 'classnames';
import { useFocusVisibleClassName } from '@czechtv/components';
import { IconSettings, IconChromecast, IconChromecastActive } from '@czechtv/icons';
import { AnalyticsContext } from '@czechtv/analytics-react';
import {
  StreamingPlayerControlsItems,
  AdsPlayerControlsItems,
} from '../../components/Loader/controls';
import { usePlayerSetup } from '../../Providers/Setup/usePlayerSetup';
import { PlayerSubtitles } from '../../Providers/Client';
import PlayerClientState from '../../Providers/Client/state';
import {
  MenuPopupType,
  PlayerMode,
  PlayerVariantEnum,
  ScreenMode,
  TIMESHIFT_CONTROLS_END_OFFSET,
} from '../../constants';
import { useMediaBreakpoints } from '../../utils/playerResponsive';
import { AudioTrackSelectOptions, PlayerAudioTrack } from '../../utils/types';
import { usePlayerContext } from '../PlayerContext';
import { TimeDisplaySwitch } from '../playerSettings';
import { PlayPauseReplay } from './VOD/PlayPauseReplay/PlayPauseReplay';
import { PlayerControlsItem, VODPlayerControlsItemsDefinition } from './items';
import { VODProgressBar } from './VOD/ProgressBar/VODProgressBar';
import { LiveProgressBar } from './Live/ProgressBar/LiveProgressBar';
import { VolumeControl } from './Common/VolumeControl/VolumeControl';
import SettingsMenu from './Common/SettingsMenu/SettingsMenu';
import { TimeshiftStartButton } from './Live/TimeshiftStartButton/TimeshiftStartButton';
import SeekBackButton from './Icons/SeekBackButton';
import SeekForwardButton from './Icons/SeekForwardButton';
import NoVideoButton from './Icons/NoVideoButton';
import InfoButton from './Icons/InfoButton';
import FullscreenExitButton from './Icons/FullscreenExitButton';
import FullscreenEnterButton from './Icons/FullscreenEnterButton';
import { Cast } from './Common/Cast/Cast';
import MenuPopupButton from './Icons/MenuPopupButton';
import { controlsClassnames } from './Controls.css';

const transformVolume = (volume: number) => clamp(volume, 0, 1);

export interface PlayerControlsProps {
  adaptiveResolution: number | null;
  animationDisabled?: boolean;
  audioTracks: PlayerAudioTrack[] | null;
  availableResolutions: number[];
  buffered?: number;
  children?: ReactNode;
  currentTime: number;
  duration: number;
  getFullLiveStreamLength: () => number;
  isExternalAudioDescription?: boolean;
  isMuted: boolean;
  isOnTimeshift: boolean;
  isVideoOnly?: boolean;
  items: PlayerControlsItem[];
  onAudioTrackSelected: (options: AudioTrackSelectOptions) => void;
  onControlsMouseEnter: () => void;
  onControlsMouseLeave: () => void;
  onForceAudioOnlySwitch: () => void;
  onFullscreenButtonPressed: () => void;
  onHbbtvDeviceSelect: () => void;
  onInteraction?: () => void;
  onPauseButtonPressed: () => void;
  onPlayButtonPressed: () => void;
  onProgressBarClick: (progress: number) => void;
  onReplayButtonPressed: () => void;
  onResolutionSelected: (resolution: number | null) => void;
  onSeekBackButtonPressed: () => void;
  onSeekForwardButtonPressed: () => void;
  onSoundOffButtonPressed: () => void;
  onSoundOnButtonPressed: () => void;
  onTextTrackSelected: (textTrack: PlayerSubtitles | null) => void;
  onTimeDisplayClick?: () => void;
  onTimeShiftStartButtonPressed?: () => void;
  onVolumeChange: (volume: number) => void;
  playerMode?: PlayerMode;
  playerRef?: RefObject<HTMLElement>;
  playerState: PlayerClientState;
  screenMode: ScreenMode;
  selectedAudioTrack?: PlayerAudioTrack | null;
  selectedResolution: number | null;
  selectedTextTrack?: PlayerSubtitles | null;
  setHiddenControlsDisabled: (value: boolean) => void;
  showVideoTimeShiftStartButton?: boolean;
  streamUrl: string | null;
  textTracks: PlayerSubtitles[] | null;
  thumbnailsUrl?: string;
  timeDisplaySwitch?: TimeDisplaySwitch;
  timeShift: number | null;
  videoRef?: RefObject<HTMLVideoElement>;
  visible?: boolean;
  volume: number;
}

const PlayerControls = ({
  adaptiveResolution,
  audioTracks,
  availableResolutions,
  buffered,
  currentTime,
  timeShift,
  duration,
  isMuted,
  items: initialItems,
  thumbnailsUrl,
  onAudioTrackSelected,
  onControlsMouseEnter,
  onControlsMouseLeave: onControlsMouseLeaveCallback = () => {},
  onFullscreenButtonPressed: onFullscreenButtonPressedCallback,
  onPauseButtonPressed: onPauseButtonPressedCallback,
  onPlayButtonPressed: onPlayButtonPressedCallback,
  onProgressBarClick,
  onHbbtvDeviceSelect,
  onReplayButtonPressed: onReplayButtonPressedCallback,
  onSeekBackButtonPressed: onSeekBackButtonPressedCallback,
  onSeekForwardButtonPressed: onSeekForwardButtonPressedCallback,
  onSoundOffButtonPressed: onSoundOffButtonPressedCallback,
  onSoundOnButtonPressed: onSoundOnButtonPressedCallback,
  onResolutionSelected,
  onTextTrackSelected,
  onTimeDisplayClick,
  onTimeShiftStartButtonPressed,
  onVolumeChange,
  playerState,
  screenMode,
  selectedAudioTrack,
  selectedResolution,
  selectedTextTrack,
  textTracks,
  volume,
  visible = true,
  showVideoTimeShiftStartButton = false,
  playerRef,
  streamUrl,
  videoRef,
  playerMode,
  onInteraction = () => {},
  children,
  setHiddenControlsDisabled,
  getFullLiveStreamLength,
  animationDisabled,
  isExternalAudioDescription,
  isOnTimeshift,
  timeDisplaySwitch,
  onForceAudioOnlySwitch,
  isVideoOnly,
}: PlayerControlsProps) => {
  const settingsMenuRef = useRef<HTMLDivElement>(null);
  const castMenuRef = useRef<HTMLDivElement>(null);
  const timerIdRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const { isMinMobile, isMaxTouchMediumMobile, isMaxLargeMobile, isTouchScreen } =
    useMediaBreakpoints();
  // V Safari neni videt focus, pokud neni inset
  const focusVisibleClassName = useFocusVisibleClassName({ inset: true });
  /**
   * State
   */
  const [items, setItems] = useState<PlayerControlsItem[]>(initialItems);
  const [isIconHovered, setIsIconHovered] = useState(false);
  const ignoreDeactivateRef = useRef(false);
  const settingsButtonRef = useRef<HTMLDivElement>(null);
  const castButtonRef = useRef<HTMLDivElement>(null);
  const controlsRef = useRef<HTMLDivElement>(null);
  // pomaha tomu, aby se na kazdy mount spustila animace (css transition se triggeruje na zmenu)
  const [startAnimation, setStartAnimation] = useState(false);
  const [chromecastTime, setChromecastTime] = useState<number | undefined>();

  const analytics = useContext(AnalyticsContext);

  const {
    liveActivated,
    setPreventHideControls,
    hbbtvStreamingActiveDevice,
    activeChromecastDevice,
    menuPopupVisible,
    setMenuPopupVisible,
    indexId,
    idec,
    hbbtv,
    setTimeUpdateCallback,
    isChromecastSession,
  } = usePlayerContext();
  const { playerVariant } = usePlayerSetup();

  useEffect(() => {
    const onChromecastTimeChange = (value: number) => {
      setChromecastTime(value);
    };
    setTimeUpdateCallback(onChromecastTimeChange);
  }, [setTimeUpdateCallback]);

  useEffect(() => {
    return () => {
      if (timerIdRef.current !== null) {
        clearTimeout(timerIdRef.current);
      }
    };
  }, []);

  // controls v parent komponente unmountneme, kdyz se controls schovaji - skonsi transition
  useEffect(() => {
    const ref = controlsRef.current;
    const onTransitionEnd = (ev: TransitionEvent) => {
      if (ev.propertyName === 'opacity') {
        const opacity = window.getComputedStyle(ref as Element).getPropertyValue('opacity');
        if (opacity === '0') {
          setHiddenControlsDisabled(true);
        }
      }
    };
    ref?.addEventListener('transitionend', onTransitionEnd);
    return () => {
      ref?.removeEventListener('transitionend', onTransitionEnd);
    };
  }, [setHiddenControlsDisabled]);

  useEffect(() => {
    setStartAnimation(true);
  }, []);

  useEffect(() => {
    if (hbbtvStreamingActiveDevice) {
      setItems(StreamingPlayerControlsItems);
    } else {
      setItems(initialItems);
    }
  }, [hbbtvStreamingActiveDevice, initialItems]);

  // TODO: zde bude prepinat existujici kontrolni prvky
  useEffect(() => {
    if (!playerMode) {
      return;
    }
    if (playerMode === PlayerMode.ads) {
      setItems(AdsPlayerControlsItems);
    }
    if (playerMode === PlayerMode.content) {
      setItems(initialItems);
    }
  }, [initialItems, playerMode]);

  // Ochytává všechny eventy na stránce a pokud jsou mimo settings okno, zavře ho
  useEffect(() => {
    function eventclick(this: HTMLElement, ev: MouseEvent) {
      // nedělat nic pokud je settings zavřené
      if (menuPopupVisible === null || !settingsMenuRef.current || !castMenuRef.current) {
        return;
      }

      const { target } = ev;

      // Pokud uživatel klikne na položku v settings, okno nezavírat
      if (target && settingsMenuRef.current.contains(target as Node)) {
        return;
      }

      // Pokud uživatel klikne na položku v hbbtv menu, okno nezavírat
      if (target && castMenuRef.current.contains(target as Node)) {
        return;
      }

      // schovat menu na next tick, aby Overlay player registroval klik s otevřeným menu
      // a nepřehrával video
      timerIdRef.current = setTimeout(() => {
        setMenuPopupVisible(null);
        setPreventHideControls(false);
      }, 0);
    }

    document.body.addEventListener('click', eventclick);

    return () => {
      document.body.removeEventListener('click', eventclick);
    };
  }, [setMenuPopupVisible, setPreventHideControls, menuPopupVisible]);

  /**
   * Callbacks
   */
  const onPlayButtonPressed = useCallback(() => {
    setItems([
      PlayerControlsItem.PAUSE,
      ...items.filter(
        (item: PlayerControlsItem) =>
          item !== PlayerControlsItem.PLAY && item !== PlayerControlsItem.PAUSE
      ),
    ]);
    onPlayButtonPressedCallback();
  }, [items, setItems, onPlayButtonPressedCallback]);

  const onPauseButtonPressed = useCallback(() => {
    setItems([
      PlayerControlsItem.PLAY,
      ...items.filter(
        (item: PlayerControlsItem) =>
          item !== PlayerControlsItem.PAUSE && item !== PlayerControlsItem.PLAY
      ),
    ]);
    onPauseButtonPressedCallback();
  }, [items, setItems, onPauseButtonPressedCallback]);

  const onReplayButtonPressed = useCallback(() => {
    onReplayButtonPressedCallback();
  }, [onReplayButtonPressedCallback]);

  const onSoundOffButtonPressed = useCallback(() => {
    if (volume === 0) {
      return;
    }
    onSoundOffButtonPressedCallback();
  }, [onSoundOffButtonPressedCallback, volume]);

  const onSoundOnButtonPressed = useCallback(() => {
    onSoundOnButtonPressedCallback();
  }, [onSoundOnButtonPressedCallback]);

  const onFullscreenButtonPressed = useCallback(() => {
    onFullscreenButtonPressedCallback();
  }, [onFullscreenButtonPressedCallback]);

  const onSeekBackButtonPressed = useCallback(() => {
    onSeekBackButtonPressedCallback();
  }, [onSeekBackButtonPressedCallback]);

  const onSeekForwardButtonPressed = useCallback(() => {
    onSeekForwardButtonPressedCallback();
  }, [onSeekForwardButtonPressedCallback]);

  const onFocusTrapDeactivate = useCallback(() => {
    // nezavirame, pokud preklikavame mezi castovanim a settings
    if (ignoreDeactivateRef.current) {
      ignoreDeactivateRef.current = false;
      return;
    }
    setMenuPopupVisible(null);
    setPreventHideControls(false);
  }, [setMenuPopupVisible, setPreventHideControls]);

  const onSettingsIconClick = useCallback(
    (e: SyntheticEvent<any>) => {
      e.preventDefault();
      if (menuPopupVisible === MenuPopupType.CAST) {
        ignoreDeactivateRef.current = true;
      }
      if (menuPopupVisible === MenuPopupType.SETTINGS) {
        setMenuPopupVisible(null);
        setPreventHideControls(false);
      } else {
        setMenuPopupVisible(MenuPopupType.SETTINGS);
        setPreventHideControls(true);
        analytics?.trigger({ type: 'PlayerSettingsOpen' });
      }
    },
    [menuPopupVisible, setMenuPopupVisible, setPreventHideControls, analytics]
  );

  const onStreamIconClick = useCallback(
    (e: SyntheticEvent<any>) => {
      e.preventDefault();
      if (menuPopupVisible === MenuPopupType.SETTINGS) {
        ignoreDeactivateRef.current = true;
      }
      if (menuPopupVisible === MenuPopupType.CAST) {
        setMenuPopupVisible(null);
        setPreventHideControls(false);
      } else {
        setMenuPopupVisible(MenuPopupType.CAST);
        setPreventHideControls(true);
      }
    },
    [setPreventHideControls, setMenuPopupVisible, menuPopupVisible]
  );

  const isTouch = useRef(false);

  const onTouchEnd = useCallback(() => {
    isTouch.current = true;
  }, []);

  // pro touch nechceme trigrovat mouse enter handler který zabraňuje skrytí controls.
  const onControlsMouseEnterHandler = useCallback(() => {
    if (!isTouch.current) {
      onControlsMouseEnter();
    }
  }, [onControlsMouseEnter]);

  const onControlsMouseOver = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      const iconElement = (event.target as HTMLDivElement).closest(`.${controlsClassnames.icon}`);
      const volumeControlElement = (event.target as HTMLDivElement).closest(
        `.${controlsClassnames.volumeControl}`
      );

      if (iconElement || volumeControlElement) {
        setIsIconHovered(true);
      } else {
        setIsIconHovered(false);
      }
    },
    [controlsClassnames.icon, controlsClassnames.volumeControl]
  );

  const onControlsMouseLeave = useCallback(() => {
    setIsIconHovered(false);
    if (!isTouch.current) {
      onControlsMouseLeaveCallback();
    }
  }, [onControlsMouseLeaveCallback]);

  const onDragChange = useCallback(
    (isDragging: boolean) => {
      // pokud je otevřené menu, nechci aby konec dragu zavíral controls
      if (menuPopupVisible !== null) {
        setPreventHideControls(isDragging);
      }
    },
    [setPreventHideControls, menuPopupVisible]
  );

  useEffect(() => {
    if (!visible) {
      setMenuPopupVisible(null);
      setPreventHideControls(false);
    }
  }, [visible, setMenuPopupVisible, menuPopupVisible, setPreventHideControls]);

  const progress = useMemo(() => {
    const time = activeChromecastDevice && chromecastTime ? chromecastTime : currentTime;
    return time > 0 ? (time / duration) * 100 : 0;
  }, [activeChromecastDevice, chromecastTime, currentTime, duration]);

  // spolecne styly pro vsechny ikony
  const iconClassName = classNames(controlsClassnames.icon, focusVisibleClassName, {
    [controlsClassnames.suppresedIcon]: isIconHovered,
  });

  const onControlsContainerClick = (e: React.MouseEvent<HTMLDivElement>) => {
    // Nechceme, aby overlay dostaval eventy controls
    // (kliknuti na ovladaci prvky by pak prepinalo play/pause videa)
    e.stopPropagation();
    // odešleme eventu, že uživatel něco dělal s obsahem a prodloužíme timeout
    // schování ovládání
    onInteraction();
  };

  // styly pro ikony, ktere neotviraji dialog (dialog otevira settings)
  const iconNonOpenableClassName = classNames(iconClassName, controlsClassnames.iconNonOpenable);
  const hideSeekForwardButton = liveActivated && playerVariant === PlayerVariantEnum.LIVE;
  const hideSeekBackButton =
    !liveActivated &&
    (timeShift || 0) + TIMESHIFT_CONTROLS_END_OFFSET >= getFullLiveStreamLength() &&
    playerVariant === PlayerVariantEnum.LIVE;
  const hideHbbtvButton = !!indexId && !idec;
  const isFinished = playerState === PlayerClientState.FINISHED;
  const isTimeshift = playerVariant === PlayerVariantEnum.LIVE && isOnTimeshift;
  const hideProgressBar = isChromecastSession && playerVariant === PlayerVariantEnum.LIVE;

  return (
    <div
      className={classNames(controlsClassnames.sectionContainer, {
        fullHeight: menuPopupVisible !== null,
        visible: visible && startAnimation,
        animation: animationDisabled,
      })}
      data-testid="playerControls"
      ref={controlsRef}
    >
      {children}
      {menuPopupVisible === MenuPopupType.SETTINGS && (
        <SettingsMenu
          adaptiveResolution={adaptiveResolution}
          audioTracks={audioTracks}
          availableResolutions={availableResolutions}
          isVideoOnly={!!isVideoOnly}
          playerRef={playerRef}
          selectedAudioTrack={selectedAudioTrack}
          selectedResolution={selectedResolution}
          selectedTextTrack={selectedTextTrack}
          settingsMenuRef={settingsMenuRef}
          textTracks={textTracks}
          onAudioTrackSelected={onAudioTrackSelected}
          onClose={onFocusTrapDeactivate}
          onForceAudioOnlySwitch={onForceAudioOnlySwitch}
          onPause={onPauseButtonPressedCallback}
          onResolutionSelected={onResolutionSelected}
          onTextTrackSelected={onTextTrackSelected}
        />
      )}
      {menuPopupVisible === MenuPopupType.CAST && (
        <Cast
          castMenuRef={castMenuRef}
          hbbtv={hbbtv}
          isExternalAudioDescription={isExternalAudioDescription}
          isTimeshift={isTimeshift}
          playerRef={playerRef}
          streamUrl={streamUrl}
          videoRef={videoRef}
          onClose={onFocusTrapDeactivate}
          onHbbtvDeviceSelect={onHbbtvDeviceSelect}
        />
      )}

      {!hbbtvStreamingActiveDevice && (
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
        <div
          className={classNames(controlsClassnames.progressBarWrapper, {
            visible,
            animation: animationDisabled,
          })}
          role="group"
          onBlur={onControlsMouseLeave}
          onClick={onControlsContainerClick}
          onMouseEnter={onControlsMouseEnterHandler}
          onMouseLeave={onControlsMouseLeave}
          onTouchEnd={onTouchEnd}
        >
          {playerVariant === PlayerVariantEnum.VOD ? (
            <VODProgressBar
              buffered={buffered}
              playerMode={playerMode}
              playerState={playerState}
              progress={progress}
              thumbnailsUrl={thumbnailsUrl}
              timeDisplaySwitch={timeDisplaySwitch}
              videoDuration={duration}
              onClick={(progress) => {
                if (playerMode === PlayerMode.content) {
                  onProgressBarClick(progress);
                }
              }}
              onTimeDisplayClick={onTimeDisplayClick}
            />
          ) : (
            <>
              {showVideoTimeShiftStartButton && isMaxTouchMediumMobile ? (
                <TimeshiftStartButton
                  key={PlayerControlsItem.TIMESHIFT_VIDEO_START}
                  onClick={onTimeShiftStartButtonPressed}
                />
              ) : null}
              <LiveProgressBar
                buffered={buffered}
                getFullLiveStreamLength={getFullLiveStreamLength}
                hideProgressBar={hideProgressBar}
                thumbnailsUrl={thumbnailsUrl}
                onClick={onProgressBarClick}
              />
            </>
          )}
        </div>
      )}
      {}
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
      <div
        className={classNames(controlsClassnames.controlsContainer, {
          visible,
          animation: animationDisabled,
        })}
        role="group"
        onBlur={onControlsMouseLeave}
        onClick={onControlsContainerClick}
        onMouseEnter={onControlsMouseEnterHandler}
        onMouseLeave={onControlsMouseLeave}
        // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
        onMouseOver={onControlsMouseOver}
        onTouchEnd={onTouchEnd}
      >
        {/* Main part */}
        {!isMaxTouchMediumMobile ? (
          <div className={controlsClassnames.vodControlsMain}>
            {items.map((item: PlayerControlsItem) => {
              switch (item) {
                case PlayerControlsItem.PLAY:
                case PlayerControlsItem.PAUSE:
                case PlayerControlsItem.REPLAY:
                  return (
                    <PlayPauseReplay
                      className={iconNonOpenableClassName}
                      key={PlayerControlsItem.REPLAY}
                      playerState={playerState}
                      videoRef={videoRef}
                      onPauseButtonPressed={onPauseButtonPressed}
                      onPlayButtonPressed={onPlayButtonPressed}
                      onReplayButtonPressed={onReplayButtonPressed}
                    />
                  );

                case PlayerControlsItem.TIMESHIFT_VIDEO_START:
                  if (playerVariant === PlayerVariantEnum.LIVE && showVideoTimeShiftStartButton) {
                    return (
                      <TimeshiftStartButton
                        key={PlayerControlsItem.TIMESHIFT_VIDEO_START}
                        onClick={onTimeShiftStartButtonPressed}
                      />
                    );
                  }
                  return null;

                case PlayerControlsItem.SOUND:
                  if (isTouchScreen || isVideoOnly) {
                    return null;
                  }
                  return (
                    <VolumeControl
                      className={controlsClassnames.volumeControl}
                      iconClassName={iconNonOpenableClassName}
                      key={PlayerControlsItem.SOUND}
                      vertical={isMaxLargeMobile}
                      volume={isMuted && !isTouchScreen ? 0 : transformVolume(volume)}
                      onDragChange={onDragChange}
                      onSoundOffButtonPressed={onSoundOffButtonPressed}
                      onSoundOnButtonPressed={onSoundOnButtonPressed}
                      onVolumeChange={onVolumeChange}
                    />
                  );

                case PlayerControlsItem.SEEK_BACK:
                  if (isMinMobile && !hideSeekBackButton && !isFinished && !hideProgressBar) {
                    return (
                      <SeekBackButton
                        className={iconNonOpenableClassName}
                        key={PlayerControlsItem.SEEK_BACK}
                        onClick={onSeekBackButtonPressed}
                      />
                    );
                  }
                  return null;

                case PlayerControlsItem.SEEK_FORWARD:
                  if (isMinMobile && !hideSeekForwardButton && !isFinished) {
                    return (
                      <SeekForwardButton
                        className={iconNonOpenableClassName}
                        key={PlayerControlsItem.SEEK_FORWARD}
                        onClick={onSeekForwardButtonPressed}
                      />
                    );
                  }
                  return null;

                default:
                  return null;
              }
            })}
          </div>
        ) : null}

        {/* Settings part */}
        <div
          className={classNames(controlsClassnames.vodControlsSettings, {
            isFullScreen: screenMode === ScreenMode.FULLSCREEN,
          })}
        >
          {items.map((item: PlayerControlsItem) => {
            switch (item) {
              case PlayerControlsItem.NO_VIDEO:
                if (isMaxTouchMediumMobile) {
                  return null;
                }
                return (
                  <NoVideoButton
                    className={iconNonOpenableClassName}
                    key={PlayerControlsItem.NO_VIDEO}
                  />
                );

              case PlayerControlsItem.INFO:
                if (isMaxTouchMediumMobile) {
                  return null;
                }
                return (
                  <InfoButton className={iconNonOpenableClassName} key={PlayerControlsItem.INFO} />
                );

              case PlayerControlsItem.CAST:
                if (hbbtv.castError || !!hideHbbtvButton || isFinished) {
                  return null;
                }
                return (
                  <MenuPopupButton
                    ariaHasPopup="dialog"
                    ariaLabel={VODPlayerControlsItemsDefinition[PlayerControlsItem.CAST]}
                    buttonTestId="playerTvShareIcon"
                    className={classNames(iconClassName, {
                      active: menuPopupVisible === MenuPopupType.CAST,
                      activeStreaming: hbbtvStreamingActiveDevice,
                    })}
                    key={PlayerControlsItem.CAST}
                    ref={castButtonRef}
                    title={VODPlayerControlsItemsDefinition[PlayerControlsItem.CAST]}
                    onClick={onStreamIconClick}
                  >
                    {hbbtvStreamingActiveDevice || activeChromecastDevice ? (
                      <IconChromecastActive tabIndex={-1} />
                    ) : (
                      <IconChromecast tabIndex={-1} />
                    )}
                  </MenuPopupButton>
                );

              case PlayerControlsItem.SETTINGS:
                if (isFinished) {
                  return null;
                }
                return (
                  <MenuPopupButton
                    ariaExpanded={menuPopupVisible === MenuPopupType.SETTINGS}
                    ariaHasPopup="dialog"
                    ariaLabel={VODPlayerControlsItemsDefinition[PlayerControlsItem.SETTINGS]}
                    buttonTestId="playerSettingsIcon"
                    className={classNames(iconClassName, {
                      active: menuPopupVisible === MenuPopupType.SETTINGS,
                    })}
                    key={PlayerControlsItem.SETTINGS}
                    ref={settingsButtonRef}
                    title={VODPlayerControlsItemsDefinition[PlayerControlsItem.SETTINGS]}
                    onClick={onSettingsIconClick}
                  >
                    <IconSettings tabIndex={-1} />
                  </MenuPopupButton>
                );

              case PlayerControlsItem.FULLSCREEN:
                if (screenMode === ScreenMode.FULLSCREEN) {
                  return (
                    <FullscreenExitButton
                      className={iconNonOpenableClassName}
                      key={PlayerControlsItem.EXIT_FULLSCREEN}
                      onClick={onFullscreenButtonPressed}
                    />
                  );
                }
                return (
                  <FullscreenEnterButton
                    className={iconNonOpenableClassName}
                    key={PlayerControlsItem.FULLSCREEN}
                    onClick={onFullscreenButtonPressed}
                  />
                );

              default:
                return null;
            }
          })}
        </div>
      </div>
    </div>
  );
};

export default memo(PlayerControls);
