import React from 'react';
import classNames from 'classnames';
import { IconSubtitles } from '@czechtv/icons';
import {
  SettingsListItemProps,
  SettingsListItemsEnum,
} from '../../SettingsMenu/SettingsList/SettingsListItem/SettingsListItem';
import { captionsStyleButtonClassnames } from './CaptionsStyleButton.css';

interface CaptionsStyleButtonProps {
  listContent: SettingsListItemProps;
  setActiveItem: (label: string) => void;
  setListContent: (listContent: SettingsListItemProps) => void;
}

export const CaptionsStyleButton = ({
  listContent,
  setListContent,
  setActiveItem,
}: CaptionsStyleButtonProps) => {
  const handleOnClick = () => {
    setActiveItem(SettingsListItemsEnum.SUBTITLES_STYLE);
    setListContent(listContent);
  };

  return (
    <div
      className={classNames(captionsStyleButtonClassnames.captions)}
      data-testid={listContent.testId}
    >
      <button
        aria-haspopup="true"
        className={classNames(captionsStyleButtonClassnames.button)}
        role="menuitem"
        type="button"
        onClick={handleOnClick}
      >
        <IconSubtitles className={captionsStyleButtonClassnames.iconCaptions}></IconSubtitles>
        <span>{SettingsListItemsEnum.SUBTITLES_STYLE}</span>
      </button>
    </div>
  );
};
