import classNames from 'classnames';
import React, { forwardRef, memo, ReactNode, SyntheticEvent, LegacyRef } from 'react';
import { PlayerNativeButton } from '../../../components/PlayerNativeButton/PlayerNativeButton';
import { menuPopupButtonClassnames } from './MenuPopupButton.css';

interface MenuPopupProps {
  ariaExpanded?: boolean;
  ariaHasPopup:
    | boolean
    | 'dialog'
    | 'menu'
    | 'true'
    | 'false'
    | 'grid'
    | 'listbox'
    | 'tree'
    | undefined;
  ariaLabel: string;
  buttonTestId?: string;
  children: ReactNode;
  className?: string;
  onClick?: (e: SyntheticEvent<any>) => void;
  title: string;
}

const MenuPopupButton = forwardRef((props: MenuPopupProps, ref) => {
  const {
    className,
    ariaExpanded,
    ariaLabel,
    title,
    buttonTestId,
    children,
    ariaHasPopup,
    onClick,
  } = props;

  return (
    <div
      className={classNames(className, menuPopupButtonClassnames.menuPopupButtonWrapper)}
      ref={ref as LegacyRef<HTMLDivElement>}
    >
      <PlayerNativeButton
        aria-controls="settings-menu"
        aria-expanded={ariaExpanded}
        aria-haspopup={ariaHasPopup}
        aria-label={ariaLabel}
        className={menuPopupButtonClassnames.menuPopupIcon}
        data-testid={buttonTestId}
        title={title}
        onClick={onClick}
      >
        <div className={menuPopupButtonClassnames.menuPopupIconInnerWrapper} tabIndex={-1}>
          {children}
        </div>
      </PlayerNativeButton>
    </div>
  );
});

export default memo(MenuPopupButton);
