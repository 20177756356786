import React, { memo, useMemo, useContext } from 'react';
import classNames from 'classnames';
import { formatTime } from '@czechtv/utils';
import { formatMessage } from '../../../utils/formatMessage';
import {
  getTimeshiftFromTimeline,
  LIVE_STREAM_DURATION,
  PlayerVariantEnum,
  TIMESHIFT_GAP_DURATION,
} from '../../../constants';
import { useMediaBreakpoints } from '../../../utils/playerResponsive';
import { usePlayerSetup } from '../../../Providers/Setup/usePlayerSetup';
import { PlayerContext } from '../../../Player/PlayerContext';
import { INDICATOR_SIZE } from '../constants';
import ProgressBarImage from './ThumbnailImage';
import { progressBarPopupClassnames } from './ProgressBarPopup.css';

interface Props {
  getFullLiveStreamLength: () => number;
  // pozice na časové ose v %, počítá se z ní pozice thumbnailu
  positionInPercentage: number;
  // offset začátku pro thumbnails ve vícenásobných playlistech
  startOffset?: number;
  // vlastní url thumbnailu přepíše výchozí
  thumbnailUrl?: string;
  // url pro celou sadu náhledových obrázků
  thumbnailsUrl?: string;
  // z timestampu se počítá náhledový obrázek
  timestamp: number;
  // název thumbnailu
  title?: string;
  // šírka nadřazeného elementu pro výpočet koncových pozic
  wrapperWidth: number;
}

export const calcOffset = (
  position: number,
  wrapperWidth: number,
  elementWidth: number
): number => {
  const offset = (wrapperWidth / 100) * (100 - position);

  if (offset < elementWidth / 2 + INDICATOR_SIZE) {
    return -(elementWidth - offset + INDICATOR_SIZE);
  }
  if (wrapperWidth - offset === 0) {
    return 0;
  }
  if (wrapperWidth - offset < elementWidth / 2) {
    return -(wrapperWidth - offset);
  }
  return -(elementWidth / 2);
};

export const ProgressBarPopup = memo(
  ({
    thumbnailsUrl,
    positionInPercentage,
    title,
    thumbnailUrl,
    wrapperWidth,
    timestamp,
    getFullLiveStreamLength,
  }: Props) => {
    const { isMaxMediumMobile } = useMediaBreakpoints();
    const { playerVariant } = usePlayerSetup();
    // nepouzivat usePlayerContext naprimo kvuli testum
    const playerContext = useContext(PlayerContext);
    const liveStreamDuration = playerContext?.liveStreamDuration || LIVE_STREAM_DURATION;
    const timeshiftGapDuration = playerContext?.timeshiftGapDuration || TIMESHIFT_GAP_DURATION;

    const isLivePlayer = playerVariant === PlayerVariantEnum.LIVE;

    const popupWidth = isMaxMediumMobile ? 132 : 160;

    // spočítáme pozici zobrazení na časové ose
    const popupOffset = useMemo(() => {
      return calcOffset(positionInPercentage, wrapperWidth, popupWidth);
    }, [positionInPercentage, wrapperWidth, popupWidth]);

    // zjistíme číselný offset, abychom věděli, co zobrazit v popusku
    const timeshiftOffset = useMemo(() => {
      if (!isLivePlayer) {
        return 0;
      }
      return getTimeshiftFromTimeline(positionInPercentage, getFullLiveStreamLength());
    }, [isLivePlayer, positionInPercentage, getFullLiveStreamLength]);

    // potřebujeme zjistit, jestli má být náhled dostupný
    const isUnavailable = useMemo(() => {
      if (!isLivePlayer && !playerContext?.liveMode) {
        return false;
      }
      return (
        (timeshiftOffset >= liveStreamDuration && timeshiftOffset <= timeshiftGapDuration) ||
        playerContext?.liveMode
      );
    }, [
      isLivePlayer,
      playerContext?.liveMode,
      timeshiftOffset,
      liveStreamDuration,
      timeshiftGapDuration,
    ]);

    const previewTrackStartOffset = playerContext?.previewTrackStartOffset ?? 0;

    // pro zobrazení času u náhledu
    const time = `${isLivePlayer ? '-' : ''}${formatTime(
      Math.abs(isLivePlayer ? timeshiftOffset : timestamp),
      'G:i:s',
      2
    )}`;

    // náhled (pro live přehrávač) není dostupný
    if (isUnavailable) {
      return (
        <div className={progressBarPopupClassnames.popup}>
          <div className={progressBarPopupClassnames.textContainer}>
            <div className={progressBarPopupClassnames.text}>
              {formatMessage({
                id: 'ProgressBarPopup.isUnavailable',
                defaultMessage: 'Náhled není dostupný',
                description:
                  'Popisek u nedostupného náhledu na časové ose pro živé vysílaní a liveMode',
              })}
            </div>
            <div
              className={classNames(
                progressBarPopupClassnames.timestamp,
                progressBarPopupClassnames.isUnavailable
              )}
            >
              {time}
            </div>
          </div>
        </div>
      );
    }

    return (
      <div
        className={progressBarPopupClassnames.popup}
        data-testid="TimestampPopUp"
        style={{
          left: `${positionInPercentage}%`,
          width: popupWidth,
          transform: `translate(${popupOffset}px)`,
        }}
      >
        {title && (
          <div className={progressBarPopupClassnames.title} data-testid="TimestampPopUpTitle">
            <div className={progressBarPopupClassnames.titleCropper}>{title}</div>
          </div>
        )}
        <div className={progressBarPopupClassnames.image} data-testid="TimestampPopUpThumbnail">
          <ProgressBarImage
            thumbnailUrl={thumbnailUrl}
            thumbnailsUrl={thumbnailsUrl}
            timestamp={
              timestamp +
              (playerContext?.startOffset || 0) +
              (playerContext?.cropStart || 0) +
              previewTrackStartOffset
            }
          />
        </div>
        <div className={progressBarPopupClassnames.timestamp}>{time}</div>
      </div>
    );
  }
);
