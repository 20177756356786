import React, { memo } from 'react';
import { IconRewind } from '@czechtv/icons';
import { PlayerNativeButton } from '../../../../components/PlayerNativeButton/PlayerNativeButton';
import { formatMessage } from '../../../../utils/formatMessage';
import { timeshiftStartButtonClassnames } from './TimeshiftStartButton.css';

interface TimeshiftStartButtonProps {
  onClick?: () => void;
}

export const TimeshiftStartButton = memo(({ onClick }: TimeshiftStartButtonProps) => {
  return (
    <PlayerNativeButton className={timeshiftStartButtonClassnames.wrapper} onClick={onClick}>
      <div className={timeshiftStartButtonClassnames.innerWrapper} tabIndex={-1}>
        <IconRewind />
        {formatMessage({
          id: 'Controls.timeshiftStartButton',
          defaultMessage: 'Na začátek',
          description: 'Tlačítko Na začátek',
        })}
      </div>
    </PlayerNativeButton>
  );
});
