import React, { memo } from 'react';
import { formatTime } from '@czechtv/utils';
import { remainingTimeClassnames } from './RemainingTime.css';

interface Props {
  onClick?: () => void;
  remainingTime: number;
  role?: string;
  title?: string;
}

const RemainingTime = ({ remainingTime, role, title, onClick }: Props) => {
  return (
    <div
      className={remainingTimeClassnames.remainingTime}
      data-testid="playerTime"
      role={role}
      title={title}
      onClick={onClick}
      onKeyDown={onClick}
    >
      {`${remainingTime < 0 ? '-' : ''}${formatTime(Math.abs(remainingTime), 'G:i:s', 2)}`}
    </div>
  );
};

export default memo(RemainingTime);
