import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { getResizedImage, resizedImagesWidth, resizedImagesHeight } from '@czechtv/utils';
import { AnalyticsContextProvider } from '@czechtv/analytics-react';
import { useVideoInProgress } from '../../Player/videosInProgress';
import {
  createUniqueVideoId,
  DEFAULT_STARTUP_REFETCH_PLAYLIST_TIMEOUT,
  LiveMode,
  LiveStreamEndReason,
  UserVideoProgressMeta,
} from '../../constants';
import { PlayerLoadingOverlay } from '../PlayerLoadingOverlay';
import { PlayerPreview } from '../PlayerPreview/PlayerPreview';
import { usePlaylist } from '../../utils/playlists/usePlaylist';
import { getDurationFromStreamUrlOffsets } from '../../utils/playlists/utils';
import { PlaylistData, PlaylistIds, PlaylistOptions } from '../../utils/playlists/constants';
import { useDefaultAnalyticsSubscribers } from '../Analytics/useDefaultAnalyticsSubscribers';
import { getAudioOnlyInfoFromStreamUrl } from '../../utils/audioOnly';
import { DRM } from '../../utils/drm';
import { PlaylistError } from '../Error/playlistError/playlistError';
import { getTimestamp, TimerEvent, TIMER_EVENT } from '../../utils/timer';
import { InfoOverlayStandalone } from '../InfoOverlay/InfoOverlay';
import { IdProvider } from '../../utils/useId';
import {
  ControlledPlayerLoaderProps,
  ControlledPlayerLoader,
  ExternalPlayerConfig,
} from './PlayerLoader';
import { PlayerProvider } from './Provider/PlayerProvider';
import { PlaylistPlayerError } from './PlaylistPlayerError';

export type PlaylistLoaderProps = ExternalPlayerConfig & PlaylistIds;

export type StandalonePlayerProps = PlaylistLoaderProps & {
  mainContentId: string;
  onAutoplayChange: (value: boolean) => void;
  onLiveModeChange: (liveMode: LiveMode) => void;
  onShouldPlayChange: (shouldPlay: boolean) => void;
  playlistData: PlaylistData | null;
  playlistOptions: PlaylistOptions;
  streamError: PlaylistError | null;
  supportedDRMs?: DRM[];
};

export const StandalonePlayer = memo(
  ({
    mainContentId,
    autoplay,
    borderRadius,
    bypassUserSettings,
    cropEnd,
    cropStart,
    showId,
    showTitle,
    startTimeInSeconds,
    ageRestriction,
    previewImageUrl,
    parentUrl,
    videoTitle,
    duration,
    fluidAspect,
    playlistData,
    streamError,
    shareVideoDomain,
    shareVideoProtocol,
    dynamicImportProvider,
    disableAirplay,
    disableChromecast,
    router,
    product,
    tags,
    suppressAnalytics: initialSuppressAnalytics,
    allControlsHidden,
    dev,
    nielsenAppId,
    gemiusPlayerId,
    gemiusId,
    nielsenDebug,
    maxStreamQuality,
    analyticsSubscribers = [],
    czechTVBaseUri = 'https://www.ceskatelevize.cz',
    widevineAccessToken,
    fairplayAccessToken,
    disableAds,
    onShouldPlayChange,
    forceAudioDescription,
    playlistOptions,
    debugStreamPausedTimeout,
    debugStreamUrlExpiredTimeout,
    playerVersion,
    supportedDRMs,
    startupRefetchPlaylistTimeout = DEFAULT_STARTUP_REFETCH_PLAYLIST_TIMEOUT,
    displayHeaderInfo,
    adminMode,
    useNewAds,
    onEvent,
    onError,
    playerRef,
    onPlaylistData,
    onLiveModeChange,
    onAutoplayChange,
    userId,
    userIdTokenCookieName,
    userVideoProgressReportingUrl,
    usePlayability,
    hideDurationPreview,
    ...rest
  }: StandalonePlayerProps) => {
    const now = getTimestamp();
    const { liveMode, startTimestamp, endTimestamp } = playlistOptions;
    const [startTime, setStartTime] = useState(startTimeInSeconds);

    const initalStreamEndedState =
      liveMode === LiveMode.liveWithStartAndEndDefined && !!endTimestamp && now >= endTimestamp;
    const [liveStreamEnded, setLiveStreamEnded] = useState<LiveStreamEndReason | undefined>(
      initalStreamEndedState ? LiveStreamEndReason.streamEndedBeforeLoad : undefined
    );

    const isLiveModeWithStartDefined =
      !!liveMode &&
      [LiveMode.liveWithStartAndEndDefined, LiveMode.liveWithStartDefined].includes(liveMode);

    const waitForLiveStreamStart =
      liveMode &&
      isLiveModeWithStartDefined &&
      !liveStreamEnded &&
      !!startTimestamp &&
      startTimestamp >= now;

    const [shouldPlay, setShouldPlay] = useState(
      isLiveModeWithStartDefined && waitForLiveStreamStart ? false : autoplay
    );
    const [shouldRefetchPlaylist, setShouldRefetchPlaylist] = useState(false);
    const refetchTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
    const suppressAnalytics = adminMode ? true : initialSuppressAnalytics;

    useEffect(() => {
      if (onPlaylistData && playlistData) {
        onPlaylistData(playlistData);
      }
      // zajima nas pouze prvotni nacteni
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [playlistData]);

    useEffect(() => {
      refetchTimeoutRef.current = setTimeout(() => {
        setShouldRefetchPlaylist(true);
      }, startupRefetchPlaylistTimeout);
      return () => {
        if (refetchTimeoutRef.current) {
          clearTimeout(refetchTimeoutRef.current);
        }
      };
    }, [startupRefetchPlaylistTimeout]);

    const setShouldPlayChange = useCallback(
      (_shouldPlay: boolean) => {
        setShouldPlay(_shouldPlay);
        onShouldPlayChange(_shouldPlay);
      },
      [onShouldPlayChange]
    );

    const onPlay = useCallback(
      (indexStartTime?: number) => {
        if (indexStartTime) {
          setStartTime(indexStartTime);
        }
        setShouldPlayChange(true);
      },
      [setShouldPlayChange]
    );

    const handleLiveStreamEnded = (reason?: LiveStreamEndReason) => {
      setLiveStreamEnded(reason);
    };

    const changeLiveModeToLiveAsVOD = useCallback(() => {
      onAutoplayChange(true);
      onLiveModeChange(LiveMode.liveAsVod);
    }, [onAutoplayChange, onLiveModeChange]);

    useEffect(() => {
      if (
        !liveMode ||
        ![LiveMode.liveWithStartAndEndDefined, LiveMode.liveWithStartDefined].includes(liveMode)
      ) {
        return () => {};
      }
      const onTimer = (ev: CustomEvent<TimerEvent>) => {
        if (!startTimestamp) {
          return;
        }
        const now = ev.detail.timestamp;
        if (now > startTimestamp && !shouldPlay) {
          setShouldRefetchPlaylist(true);
          onPlay();
        }
      };

      window.addEventListener(TIMER_EVENT, onTimer as EventListener);
      return () => window.removeEventListener(TIMER_EVENT, onTimer as EventListener);
    }, [liveMode, onPlay, shouldPlay, startTimestamp, waitForLiveStreamStart]);

    // potrebujeme pripadne i umet spustit preview pri ovladani pres ref
    useEffect(() => {
      if (playerRef) {
        playerRef.current = {
          play: async (indexStartTime?: number) => onPlay(indexStartTime),
          destroy: async () => {},
          seek: () => {},
          seekTo: () => {},
          toggleMute: async () => {},
          pause: () => {},
          stop: () => {},
          toggleFullscreen: async () => {},
          setVolume: () => {},
          getVolume: () => undefined,
          getIsMuted: () => undefined,
          ...playerRef.current,
        };
      }
    }, [onPlay, playerRef, playlistData, shouldPlay]);

    const defaultAnalyticsSubscribers = useDefaultAnalyticsSubscribers({
      product,
      dev,
      gemiusId,
      gemiusPlayerId,
      nielsenAppId,
      nielsenDebug,
      suppressAnalytics: suppressAnalytics || !playlistData,
    });
    // Player komponenta za nas doplnuje defaultAnalyticsSubscribers sama,
    // pro preview je ale musime doplnit rucne
    const previewAnalyticsSubscribers = useMemo(
      () => [...defaultAnalyticsSubscribers, ...analyticsSubscribers],
      [defaultAnalyticsSubscribers, analyticsSubscribers]
    );

    const isLoading = !playlistData;
    const idec = ('externalId' in rest && rest.externalId) || null;
    const bonusId = ('bonusId' in rest && rest.bonusId) || undefined;
    const indexId = ('indexId' in rest && rest.indexId) || undefined;
    const hasAds = !!playlistData?.meta.hasAds;

    const handleError = useCallback(
      (error: Error) => {
        onError?.(error);
      },
      [onError]
    );

    const durationFromPlaylist =
      'duration' in rest ? duration : playlistData?.meta.duration || undefined;
    const indexDuration =
      indexId && playlistData?.streamUrls[0]
        ? getDurationFromStreamUrlOffsets(playlistData.streamUrls[0].main)
        : undefined;

    // preferujeme nastaveni zvenci, pak pripadne data z playlistu
    const metaTitle = videoTitle || playlistData?.meta.title || undefined;
    const metaShowTitle = showTitle || playlistData?.meta.showTitle || undefined;
    const metaDuration = indexDuration || durationFromPlaylist;
    const metaPreviewImageUrl = previewImageUrl || playlistData?.meta.previewImageUrl;

    const playerConfig = useMemo<ControlledPlayerLoaderProps['config']>(
      () => ({
        userIdTokenCookieName,
        mainContentId,
        assetId: playlistData?.assetId,
        playlistId: playlistData?.id,
        autoPlay: autoplay,
        cropEnd,
        cropStart,
        disableAds,
        parentUrl,
        duration: metaDuration,
        dynamicImportProvider,
        forceAudioDescription,
        disableAirplay,
        disableChromecast,
        router,
        product,
        playlistQueryUri: '',
        shareVideoDomain,
        shareVideoProtocol,
        showId,
        showTitle: metaShowTitle,
        startTime,
        indexes: playlistData?.indexes,
        subtitles: playlistData?.subtitles,
        previewTrackBaseUrl: playlistData?.previewTrackBaseUrl,
        labeling: ageRestriction,
        // title, který přišel do playeru zvenčí
        videoTitle,
        // metaTitle: název zvenčí nebo z playlistů (kvůli analytice)
        metaTitle,
        suppressAnalytics,
        tags,
        allControlsHidden,
        dev,
        nielsenAppId,
        gemiusPlayerId,
        gemiusId,
        nielsenDebug,
        maxStreamQuality,
        idec,
        bonusId,
        indexId,
        analyticsSubscribers,
        hasAds,
        previewImageUrl: metaPreviewImageUrl,
        playlistOptions,
        debugStreamPausedTimeout,
        debugStreamUrlExpiredTimeout,
        playerVersion,
        nielsen: playlistData?.analytics.nielsen,
        gemius: playlistData?.analytics.gemius,
        // gtm: playlistData?.analytics.gtm,
        supportedDRMs,
        displayHeaderInfo,
        useNewAds,
        bypassUserSettings,
        borderRadius,
        forceMuted: !!rest.forceMuted,
      }),
      [
        mainContentId,
        playlistData?.assetId,
        playlistData?.id,
        playlistData?.indexes,
        playlistData?.subtitles,
        playlistData?.previewTrackBaseUrl,
        playlistData?.analytics.nielsen,
        playlistData?.analytics.gemius,
        // playlistData?.analytics.gtm,
        autoplay,
        cropEnd,
        cropStart,
        disableAds,
        parentUrl,
        metaDuration,
        dynamicImportProvider,
        forceAudioDescription,
        router,
        product,
        shareVideoDomain,
        shareVideoProtocol,
        showId,
        metaShowTitle,
        startTime,
        ageRestriction,
        videoTitle,
        metaTitle,
        suppressAnalytics,
        tags,
        allControlsHidden,
        dev,
        nielsenAppId,
        gemiusPlayerId,
        gemiusId,
        nielsenDebug,
        maxStreamQuality,
        disableAirplay,
        disableChromecast,
        idec,
        bonusId,
        indexId,
        analyticsSubscribers,
        hasAds,
        metaPreviewImageUrl,
        playlistOptions,
        debugStreamPausedTimeout,
        debugStreamUrlExpiredTimeout,
        playerVersion,
        supportedDRMs,
        displayHeaderInfo,
        useNewAds,
        userIdTokenCookieName,
        bypassUserSettings,
        borderRadius,
        rest.forceMuted,
      ]
    );

    const externalId = bonusId || indexId || idec || undefined;
    const hasError = streamError;
    const hasData = !isLoading && !hasError;
    // nastavi startTime videa z localStorage pokud je video rozkoukane
    const userVideoProgress = useVideoInProgress({
      bypassUserSettings: !!bypassUserSettings,
      product,
      videoId: hasData
        ? createUniqueVideoId({
            indexId,
            externalId,
          })
        : '',
      duration: metaDuration || 0,
    });

    useEffect(() => {
      if (userVideoProgress !== undefined && startTimeInSeconds === undefined) {
        setStartTime(userVideoProgress);
      }
    }, [userVideoProgress, startTimeInSeconds]);

    const previewMeta = useMemo(
      () => ({
        indexes: playlistData?.indexes || [],
        // používáme pouze title, který přijde zvenčí, ve starých playlistech chodí ošklivé názvy
        title: videoTitle,
        showTitle: metaShowTitle,
        duration: duration || metaDuration,
        ageRestriction: ageRestriction || undefined,
      }),
      [playlistData?.indexes, videoTitle, metaShowTitle, metaDuration, ageRestriction, duration]
    );

    const userVideoProgressMeta: UserVideoProgressMeta | undefined = useMemo(() => {
      if (!idec || !showId || !userVideoProgressReportingUrl || bypassUserSettings) {
        return undefined;
      }
      return { idec, sidp: showId, userVideoProgressReportingUrl };
    }, [bypassUserSettings, idec, showId, userVideoProgressReportingUrl]);

    if (liveStreamEnded === LiveStreamEndReason.streamEndedDuringPlayback) {
      return (
        <InfoOverlayStandalone
          buttonText="Spustit video od začátku"
          subtitle="Video si pustíte od začátku, pokud ho znovu načtete"
          title="Přenos skončil"
          onClick={changeLiveModeToLiveAsVOD}
        />
      );
    }

    const didLiveStreamEndedBeforeLoad =
      liveStreamEnded === LiveStreamEndReason.streamEndedBeforeLoad;
    if ((!shouldPlay && !hasError) || (didLiveStreamEndedBeforeLoad && !shouldPlay)) {
      const isAudioOnly = getAudioOnlyInfoFromStreamUrl(playlistData?.streamUrls);
      return (
        <PlayerProvider borderRadius={borderRadius} fluidAspect={fluidAspect}>
          <AnalyticsContextProvider subscribers={previewAnalyticsSubscribers}>
            <PlayerPreview
              hideDurationPreview={!!hideDurationPreview}
              isAudioOnly={isAudioOnly}
              meta={previewMeta}
              play={
                isLiveModeWithStartDefined &&
                startTimestamp &&
                endTimestamp &&
                endTimestamp < now &&
                liveMode !== LiveMode.liveAsVod
                  ? changeLiveModeToLiveAsVOD
                  : onPlay
              }
              previewImage={
                metaPreviewImageUrl &&
                !metaPreviewImageUrl.includes('width=') &&
                !metaPreviewImageUrl.includes('height=')
                  ? getResizedImage(
                      metaPreviewImageUrl,
                      resizedImagesWidth.PLAYER_PREVIEW,
                      resizedImagesHeight.PLAYER_PREVIEW
                    )
                  : metaPreviewImageUrl
              }
              showSimpleVideoHeader={!displayHeaderInfo}
              startsAt={waitForLiveStreamStart && startTimestamp ? startTimestamp : undefined}
              videoStartTime={startTime}
            />
          </AnalyticsContextProvider>
        </PlayerProvider>
      );
    }

    if (isLoading && !hasError) {
      return <PlayerLoadingOverlay borderRadius={borderRadius} />;
    }

    // cleanup
    if (refetchTimeoutRef.current) {
      clearTimeout(refetchTimeoutRef.current);
    }

    if (streamError || !playlistData) {
      const startupProps = {
        errorDetail: streamError,
        product,
        dev,
        gemiusId,
        gemiusPlayerId,
        nielsenAppId,
        nielsenDebug,
        suppressAnalytics,
        czechTVBaseUri,
        usingNewLivePlaylist: playlistOptions.encoder
          ? !!playlistOptions.useNewPlaylist
          : undefined,
      };
      onError?.(streamError);
      return (
        <PlaylistPlayerError
          mainContentId={mainContentId}
          playlistOptions={playlistOptions}
          startupProps={startupProps}
        />
      );
    }

    return (
      <ControlledPlayerLoader
        config={playerConfig}
        externalUserId={userId}
        initialPlaylistData={playlistData}
        playerRef={playerRef}
        refetchPlaylistController={{ shouldRefetchPlaylist, setShouldRefetchPlaylist }}
        userVideoProgressMeta={userVideoProgressMeta}
        onError={handleError}
        onEvent={onEvent}
        onLiveStreamEnded={handleLiveStreamEnded}
      />
    );
  }
);

export const PlaylistLoader = memo((props: PlaylistLoaderProps) => {
  const {
    borderRadius,
    cropEnd,
    cropStart,
    delayLoadingPlaylist = false,
    autoplay: initialAutoplay,
    useNewPlaylist,
    playlistUri = 'https://www.ceskatelevize.cz/ivysilani/ajax/get-client-playlist/',
    playlistVodUri,
    playlistLiveUri,
    widevineLicenseServerUrl = 'https://ivys-wvproxy.o2tv.cz/license',
    widevineAccessToken,
    fairplayLicenseServerUrl = 'https://ivys-fpproxy.o2tv.cz/license',
    fairplayAccessToken,
    fairplayLicenseCertificateUrl = 'https://fs.ceskatelevize.cz/cache/file/mobile/drm/cert/fairplay.cer',
    origin,
    bypassGeoIp,
    accessToken,
    adminMode,
    startTimestamp,
    endTimestamp,
    useNewAds,
    liveMode: initialLiveMode,
    adOceanCustomTargeting,
    adOceanSections,
    product,
    showId,
    promoHash,
    promoTimeout,
    externalId,
    indexId,
    bonusId,
    versionId,
    mediaId,
    encoder,
    useLegacyPlaylistFallback,
    disableLabeling,
    externalLiveStreams,
    noGapTimeshift = true,
    forceAudioOnly,
    maxAutoQuality,
    maxQuality,
    vastConfig,
    videoTitle: externalEpisodeTitle,
    showTitle: externalShowTitle,
    additionalAdKeywords,
    useNewCdnForLiveIdec,
    faqReportApiUrl,
    faqUrl,
    usePlayability,
    bypassLicenseToken,
  } = props;
  const [shouldPlay, setShouldPlay] = useState(initialAutoplay);
  const [liveMode, setLiveMode] = useState(initialLiveMode);
  const [autoplay, setAutoplay] = useState(initialAutoplay);

  const handleShouldPlayChange = useCallback(
    (shouldPlay: boolean) => {
      if (shouldPlay && !autoplay) {
        setAutoplay(true);
      }
      setShouldPlay(shouldPlay);
    },
    [autoplay]
  );

  const handleLiveModeChange = useCallback((liveMode: LiveMode) => {
    setLiveMode(liveMode);
  }, []);

  const handleAutoplayChange = useCallback((value: boolean) => {
    setAutoplay(value);
  }, []);

  useEffect(() => {
    const onTimer = () => {
      const timeEvent = new CustomEvent(TIMER_EVENT, {
        detail: {
          timestamp: getTimestamp(),
        },
      });
      window.dispatchEvent(timeEvent);
    };
    const timer = setInterval(onTimer, 1000);
    return () => clearInterval(timer);
  }, []);

  const externalLiveStreamContentId = externalLiveStreams ? 'external' : undefined;
  const mainContentId =
    externalId ||
    indexId ||
    bonusId ||
    versionId ||
    mediaId ||
    encoder ||
    externalLiveStreamContentId;

  if (!mainContentId) {
    throw new Error('Invalid identificator');
  }

  const playlistOptions: PlaylistOptions = useMemo(
    () => ({
      playlistUri,
      widevineLicenseServerUrl,
      fairplayLicenseCertificateUrl,
      fairplayLicenseServerUrl,
      cropEnd,
      cropStart,
      fairplayAccessToken,
      widevineAccessToken,
      skip: !shouldPlay && delayLoadingPlaylist,
      useNewPlaylist,
      bypassGeoIp,
      forceAudioOnly,
      origin,
      playlistVodUri,
      playlistLiveUri,
      accessToken,
      adminMode,
      useNewAds,
      startTimestamp,
      endTimestamp,
      liveMode,
      adOceanCustomTargeting,
      adOceanSections,
      product,
      showId,
      promoHash,
      promoTimeout,
      externalId,
      indexId,
      bonusId,
      mediaId,
      versionId,
      encoder,
      useLegacyPlaylistFallback,
      disableLabeling,
      noGapTimeshift,
      externalLiveStreams,
      maxAutoQuality,
      maxQuality,
      vastConfig,
      additionalAdKeywords,
      useNewCdnForLiveIdec,
      externalEpisodeTitle,
      externalShowTitle,
      faqReportApiUrl,
      faqUrl,
      usePlayability,
      bypassLicenseToken,
    }),
    [
      playlistUri,
      widevineLicenseServerUrl,
      fairplayLicenseCertificateUrl,
      fairplayLicenseServerUrl,
      cropEnd,
      cropStart,
      fairplayAccessToken,
      widevineAccessToken,
      shouldPlay,
      delayLoadingPlaylist,
      useNewPlaylist,
      bypassGeoIp,
      forceAudioOnly,
      origin,
      playlistVodUri,
      playlistLiveUri,
      accessToken,
      adminMode,
      useNewAds,
      startTimestamp,
      endTimestamp,
      liveMode,
      adOceanCustomTargeting,
      adOceanSections,
      product,
      showId,
      promoHash,
      promoTimeout,
      externalId,
      indexId,
      bonusId,
      mediaId,
      versionId,
      encoder,
      useLegacyPlaylistFallback,
      disableLabeling,
      noGapTimeshift,
      externalLiveStreams,
      maxAutoQuality,
      maxQuality,
      vastConfig,
      additionalAdKeywords,
      useNewCdnForLiveIdec,
      externalEpisodeTitle,
      externalShowTitle,
      faqReportApiUrl,
      faqUrl,
      usePlayability,
      bypassLicenseToken,
    ]
  );

  /*
  kvuli problemu s logovanim fallbacku behem prepinani do timeshiftu, logujeme jako error
  jen fallback pri prvnim nacteni
  */
  const {
    playlistError: streamError,
    data: playlistData,
    supportedDRMs,
  } = usePlaylist({ ...playlistOptions, logFallbackError: true });

  const updatedPlaylistOptions = useMemo(() => {
    if (
      playlistData &&
      (playlistData.replayToken ||
        useNewPlaylist !== playlistData.useNewPlaylist ||
        playlistData.isLab)
    ) {
      return {
        ...playlistOptions,
        isLab: playlistData.isLab,
        replayToken: playlistData.replayToken,
        useNewPlaylist: playlistData.useNewPlaylist,
      };
    }
    return playlistOptions;
  }, [playlistData, playlistOptions, useNewPlaylist]);

  if (!playlistData && !streamError && !delayLoadingPlaylist) {
    return <PlayerLoadingOverlay borderRadius={borderRadius} />;
  }

  return (
    <IdProvider>
      <StandalonePlayer
        {...props}
        autoplay={autoplay}
        key={playlistData?.type}
        mainContentId={mainContentId}
        playlistData={playlistData}
        playlistOptions={updatedPlaylistOptions}
        streamError={streamError}
        supportedDRMs={supportedDRMs}
        onAutoplayChange={handleAutoplayChange}
        onLiveModeChange={handleLiveModeChange}
        onShouldPlayChange={handleShouldPlayChange}
      />
    </IdProvider>
  );
});
